import styled from 'styled-components';
import Brinde from '../../assets/home.webp';

export const LoginPageContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;

    position: relative;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;

    background-color: #292828;
    height: 100%;
    width: 45%;
    max-width: 800px;
    min-width: 500px;
    border-radius: 0 74px 74px 0;
    padding: 40px 0;

    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 0;
        border-radius: 0;
    }
`;

export const ImageContainer = styled.div`
    height: 100%;
    width: 80%;
    margin-left: 20%;
    
    background-image: url(${Brinde});
    background-size: cover;
    background-position-y: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 2500px) and (orientation: landscape) {
        width: 75%;
        margin-left: 25%;
    }

    @media screen and (max-width: 2100px) and (orientation: landscape) {
        width: 75%;
        margin-left: 25%;
    }

    @media screen and (max-height: 1200px) and (orientation: portrait) {
        width: 85%;
        margin-left: 15%;
    }

    @media screen and (max-height: 2000px) and (orientation: portrait) {
        width: 90%;
        margin-left: 10%;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
