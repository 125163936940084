import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalExcluirVinculoVolumeCategoriaContainer } from './styles';

const ModalExcluirVinculoVolumeCategoria = ({ setModalOpened, handleExcluirVinculoVolumeCategoria }) => {

    return (
        <ModalExcluirVinculoVolumeCategoriaContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja <span>excluir</span> o vínculo?</p>
                    <ButtonsContainer>
                        <button onClick={handleExcluirVinculoVolumeCategoria}>Sim</button>
                        <button onClick={() => setModalOpened(false)}>Não</button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalExcluirVinculoVolumeCategoriaContainer>
    );
};

export default ModalExcluirVinculoVolumeCategoria;