import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import bebidaSchema from '../../../schemas/Bebidas/bebidaSchema';
import Loading from '../../Loading';
import { ReactSVG } from 'react-svg';
import ImportarSvg from '../../../assets/importar.svg';
import ModalImportarBebidas from '../../Modals/ModalImportarBebidas';

const BebidaForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(bebidaSchema)
    });

    const [geos, setGeos] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');
    const [geoError, setGeoError] = useState(false);

    const { bebida_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [modalImportarBebidas, setModalImportarBebidas] = useState(false);

    const [loading, setLoading] = useState(true);
    const [bebidaLoaded, setBebidaLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);

    useEffect(() => {
        if (bebidaLoaded && geosLoaded) {
            setLoading(false);
        };
    }, [bebidaLoaded, geosLoaded]);

    useEffect(() => {
        if (bebida_id) {
            api.get(`/bebidas/${bebida_id}`)
                .then(response => {
                    const bebida = response.data.data.bebida;
                    reset(bebida);
                    setGeoSelecionada(bebida.geo_id);
                    setBebidaLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                    if (error.response.status === 404) {
                        toast.error('Bebida não encontrada!');
                        return;
                    };
                    toast.error('Erro ao buscar bebida!');
                });
        } else {
            setBebidaLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bebida_id]);

    const buscarGeos = () => {
        api.get(`/geos`)
            .then(response => {
                setGeos(response.data.data.geos);
                setGeosLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                }
                if (error.response.status === 404) {
                    toast.error('Geo não encontrada!');
                    return;
                };
                toast.error('Erro ao buscar Geo!');
            });
    };

    useEffect(() => {
        if (user?.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
    }, [user]);
    
    const onSubmitFunction = (data) => {
        if (user.permissao === 'ac' && !geoSelecionada) {
            return
        };
        const dados = {
            ...data,
            geo_id: geoSelecionada
        };

        if (bebida_id) {
            dados.id = bebida_id;
            api.patch(`/bebidas`, {bebidas: [dados]})
                .then(response => {
                    toast.success('Bebida atualizada com sucesso!');
                    navigate('/administrativo/bebida');
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                    if (error.response.status === 404) {
                        toast.error('Bebida não encontrada!');
                        return;
                    };
                    toast.error('Erro ao atualizar bebida!');
                });
        } else {
            api.post('/bebidas', {bebidas: [dados]})
                .then(response => {
                    toast.success('Bebida cadastrada com sucesso!');
                    navigate('/administrativo/bebida');
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                    if (error.response.status === 404) {
                        toast.error('Bebida não encontrada!');
                        return;
                    };
                    if (error.response.status === 409) {
                        toast.error('Bebida já cadastrada!');
                        return;
                    };
                    toast.error('Erro ao cadastrar bebida!');
                });
        }
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                {!bebida_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarBebidas(true)} type='button'>
                            <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div>
                )}
                <InputContainer className='nome'>
                    <label htmlFor='nome'>Nome</label>
                    <input
                        type='text'
                        id='nome'
                        placeholder='Nome da bebida'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                </InputContainer>
                <InputContainer className='segmento'>
                    <label htmlFor='segmento'>Segmento</label>
                    <input
                        type='text'
                        id='segmento'
                        placeholder='Água, cerveja, premium, etc.'
                        {...register('segmento')}
                    />
                    {errors.segmento && <span className='input-error'>{errors.segmento.message}</span>}
                </InputContainer>
                <InputContainer className='pack'>
                    <label htmlFor='Pack'>Pack</label>
                    <input
                        type='text'
                        id='Pack'
                        placeholder='Pack'
                        {...register('pack')}
                    />
                    {errors.pack && <span className='input-error'>{errors.pack.message}</span>}
                </InputContainer>
                <InputContainer className='marca-consolidada'>
                    <label htmlFor='marca-consolidada'>Marca Consolidada</label>
                    <input
                        type='text'
                        id='marca-consolidada'
                        placeholder='Marca consolidada'
                        {...register('marca_consolid')}
                    />
                    {errors.marca_consolid && <span className='input-error'>{errors.marca_consolid.message}</span>}
                </InputContainer>
                <InputContainer className='marca'>
                    <label htmlFor='marca'>Marca</label>
                    <input
                        type='text'
                        id='marca'
                        placeholder='Marca da bebida'
                        {...register('marca')}
                    />
                    {errors.marca && <span className='input-error'>{errors.marca.message}</span>}
                </InputContainer>
                {user?.permissao === 'ac' && (
                    <SelectContainer className='geo'>
                        <label htmlFor='geo_id'>Geo</label>
                        <select
                            id='geo_id'
                            value={geoSelecionada}
                            onChange={e => setGeoSelecionada(e.target.value)}
                        >
                            <option value=''>Selecione uma geo</option>
                            {geos.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nome}</option>
                                );
                            })}
                        </select>
                        {geoError && <span className='input-error' style={{top: '60px'}}>Campo obrigatório</span>}
                    </SelectContainer>
                )}
                <div className='buttons-container'>
                    <Link to='/administrativo/bebida'>{'< Voltar'}</Link>
                    <SalvarButton
                        type='submit'
                        onClick={() => {
                            if (user.permissao === 'ac' && !geoSelecionada) {
                                setGeoError(true);
                            } else {
                                setGeoError(false);
                            };
                        }}
                    >
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
            </Form>
        )}
        {modalImportarBebidas && (
            <ModalImportarBebidas setModalOpened={setModalImportarBebidas}/>
        )}
        </>
    );
};

export default BebidaForm;