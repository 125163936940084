import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import AdminBebidaEditarPage from '../pages/AdminBebidaEditarPage';
import AdminBebidaNovoPage from '../pages/AdminBebidaNovoPage';
import AdminBebidaPage from '../pages/AdminBebidaPage';
import AdminCategoriaEditarPage from '../pages/AdminCategoriaEditarPage';
import AdminCategoriaNovoPage from '../pages/AdminCategoriaNovoPage';
import AdminCategoriaPage from '../pages/AdminCategoriaPage';
import AdminGeoEditarPage from '../pages/AdminGeoEditarPage';
import AdminGeoNovoPage from '../pages/AdminGeoNovoPage';
import AdminGeoPage from '../pages/AdminGeoPage';
import AdminOperacaoEditarPage from '../pages/AdminOperacaoEditarPage';
import AdminOperacaoNovoPage from '../pages/AdminOperacaoNovoPage';
import AdminOperacaoPage from '../pages/AdminOperacaoPage';
import AdminPdvEditarPage from '../pages/AdminPdvEditarPage';
import AdminPdvNovoPage from '../pages/AdminPdvNovoPage';
import AdminPdvPage from '../pages/AdminPdvPage';
import AdminUsuarioEditarPage from '../pages/AdminUsuarioEditarPage';
import AdminUsuarioNovoPage from '../pages/AdminUsuarioNovoPage';
import AdminUsuarioPage from '../pages/AdminUsuarioPage';
import AdminVinculoVolumeCategoriaNovoPage from '../pages/AdminVinculoVolumeCategoriaNovoPage';
import AdminVinculoVolumeCategoriaPage from '../pages/AdminVinculoVolumeCategoriaPage';
import AdminVolumeEditarPage from '../pages/AdminVolumeEditarPage';
import AdminVolumeNovoPage from '../pages/AdminVolumeNovoPage';
import AdminVolumePage from '../pages/AdminVolumePage';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFoundPage';
import PedidosEditarPage from '../pages/PedidosEditarPage';
import PedidosNovoPage from '../pages/PedidosNovoPage';
import PedidosPage from '../pages/PedidosPage';
import PedidosVisualizarPage from '../pages/PedidosVisualizarPage';
import { useAuth } from '../providers/Auth';
import CustomRoute from './CustomRoute';
import DashboardPage from '../pages/DashboardPage';
import AdminComercialPage from '../pages/AdminComercialPage';
import AdminComercialNovoPage from '../pages/AdminComercialNovoPage';
import AdminComercialEditarPage from '../pages/AdminComercialEditarPage';
import PdfPage from '../pages/PdfPage';
import AdminEscadaPage from '../pages/AdminEscadaPage';

const Router = () => {

    const { loadingUser, user } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route 
                    path='/' 
                    element={
                        loadingUser && (
                            user ? (
                                <Navigate to='/pedidos' />
                            ) : (
                                <LoginPage />
                            )
                        )
                    } 
                />
                <Route 
                    path='/dashboard'
                    element={<CustomRoute element={DashboardPage} permissions={['ac','admin']}/>}
                />
                <Route
                    path='/pedidos'
                    element={<CustomRoute element={PedidosPage} permissions={['ac','admin','geo','gc','gv','rn','mari']} />}
                />
                <Route
                    path='/pedidos/novo'
                    element={<CustomRoute element={PedidosNovoPage} permissions={['ac','admin','geo','gc','gv','rn']} />}
                />
                <Route
                    path='/pedidos/editar/:pedido_id'
                    element={<CustomRoute element={PedidosEditarPage} permissions={['ac','admin','geo','gc','gv','rn']} />}
                />
                <Route
                    path='/pedidos/:pedido_id'
                    element={<CustomRoute element={PedidosVisualizarPage} permissions={['mari','ac','admin','geo','gc','gv', 'rn']} />}
                />
                <Route 
                    path='/administrativo/geo'
                    element={<CustomRoute element={AdminGeoPage} permissions={['ac']} />}
                />
                <Route
                    path='/administrativo/geo/novo'
                    element={<CustomRoute element={AdminGeoNovoPage} permissions={['ac']} />}
                />
                <Route
                    path='/administrativo/geo/editar/:geo_id'
                    element={<CustomRoute element={AdminGeoEditarPage} permissions={['ac']} />}
                />
                <Route 
                    path='/administrativo/comercial'
                    element={<CustomRoute element={AdminComercialPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/comercial/novo'
                    element={<CustomRoute element={AdminComercialNovoPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/comercial/editar/:comercial_id'
                    element={<CustomRoute element={AdminComercialEditarPage} permissions={['ac', 'admin']} />}
                />
                <Route 
                    path='/administrativo/operacao'
                    element={<CustomRoute element={AdminOperacaoPage} permissions={['ac', 'admin','geo','gc']} />}
                />
                <Route
                    path='/administrativo/operacao/novo'
                    element={<CustomRoute element={AdminOperacaoNovoPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/operacao/editar/:operacao_id'
                    element={<CustomRoute element={AdminOperacaoEditarPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/usuario'
                    element={<CustomRoute element={AdminUsuarioPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/usuario/novo'
                    element={<CustomRoute element={AdminUsuarioNovoPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/usuario/editar/:usuario_id'
                    element={<CustomRoute element={AdminUsuarioEditarPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/ponto-de-venda'
                    element={<CustomRoute element={AdminPdvPage} permissions={['ac', 'admin','geo','gc', ...(user?.geo_id === 3 ? ['gv'] : [])]} />}
                />
                <Route
                    path='/administrativo/ponto-de-venda/novo'
                    element={<CustomRoute element={AdminPdvNovoPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/ponto-de-venda/editar/:pdv_id'
                    element={<CustomRoute element={AdminPdvEditarPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/administrativo/categoria'
                    element={<CustomRoute element={AdminCategoriaPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/categoria/novo'
                    element={<CustomRoute element={AdminCategoriaNovoPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/categoria/editar/:categoria_id'
                    element={<CustomRoute element={AdminCategoriaEditarPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
               <Route
                    path="/administrativo/bebida"
                    element={<CustomRoute element={AdminBebidaPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route 
                    path='/administrativo/bebida/novo'
                    element={<CustomRoute element={AdminBebidaNovoPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/bebida/editar/:bebida_id'
                    element={<CustomRoute element={AdminBebidaEditarPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route 
                    path='/administrativo/volume'
                    element={<CustomRoute element={AdminVolumePage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/volume/novo'
                    element={<CustomRoute element={AdminVolumeNovoPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/volume/editar/:volume_id'
                    element={<CustomRoute element={AdminVolumeEditarPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route 
                    path='/administrativo/vinculo'
                    element={<CustomRoute element={AdminVinculoVolumeCategoriaPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                <Route
                    path='/administrativo/vinculo/novo'
                    element={<CustomRoute element={AdminVinculoVolumeCategoriaNovoPage} permissions={['ac', 'admin', ...(user?.geo_id === 3 ? ['gv', 'gc'] : [])]} />}
                />
                 <Route
                    path='/administrativo/escada'
                    element={<CustomRoute element={AdminEscadaPage} permissions={['ac', 'admin']} />}
                />
                <Route
                    path='/pdf/:link'
                    element={<PdfPage />}
                />
                
                <Route
                    path='*'
                    element={<NotFoundPage />}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;