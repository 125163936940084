import { Doughnut } from 'react-chartjs-2';
import { 
    Chart as ChartJS, 
    ArcElement, 
    Title,
    Tooltip,
    Legend 
} from 'chart.js';
import { TotalContainer, TotalPedidosContainer } from './styles';

ChartJS.register(
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const TotalPedidos = ({ pedidos, anoSelecionado }) => {

    return (
        <TotalPedidosContainer>
            <h3>Total de pedidos {anoSelecionado ? `${anoSelecionado}` : ''}</h3>
            <TotalContainer>
                <h4>{pedidos.length}</h4>
                <p>total</p>
            </TotalContainer>
             <Doughnut 
                data={{
                    labels: ['Concluídos', 'Baixados', 'Aprovados', 'Revisar', 'Reprovados','Aguardando'],
                    datasets: [
                        {
                            label: 'Pedidos',
                            data: [
                                pedidos.filter((pedido) => pedido.status === 'CONCLUIDO').length,
                                pedidos.filter((pedido) => pedido.status === 'BAIXADO').length,
                                pedidos.filter((pedido) => pedido.status === 'APROVADO').length,
                                pedidos.filter((pedido) => pedido.status === 'REVISAR').length,
                                pedidos.filter((pedido) => pedido.status === 'REPROVADO').length,
                                pedidos.filter((pedido) => pedido.status === 'AGUARDANDO').length,
                            ],
                            backgroundColor: [
                                '#292828',
                                '#707070',
                                '#40AD48',
                                '#FDBC11',
                                '#ED1818',
                                '#F16521',
                            ],
                            borderColor: [
                                '#292828',
                                '#707070',
                                '#40AD48',
                                '#FDBC11',
                                '#ED1818',
                                '#F16521',
                            ],
                            borderWidth: 1,
                        },
                    ],  
                }}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: "right",
                            fullSize: true,
                            align: 'center',
                            labels: {
                                borderRadius: 10,
                                useBorderRadius: true,
                            }
                        },
                    },
                }}
            />
        </TotalPedidosContainer>
    )
};

export default TotalPedidos;