import AdminGeo from '../../components/AdminGeo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminGeoPageContainer } from './styles';

const AdminGeoPage = () => {

    return (
        <AdminGeoPageContainer>
            <Header />
            <AdminGeo />
            <Footer />
        </AdminGeoPageContainer>  
    );
};

export default AdminGeoPage;