import styled from 'styled-components';

export const Tr = styled.tr`
    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        text-align: center;

        > label {
            display: none;
        }
    }

    > td:first-child,
    > td:nth-child(2) {
        text-align: left;
    }

    @media screen and (max-width: 985px) {
        > td {
            height: 40px;
            font-size: 0.8em;
        }
    }

    @media screen and (max-width: 840px) {
        display: flex;
        flex-direction: column;
        border: 1px solid #E9E9E9;
        border-radius: 15px;
        margin: 1rem 0;
        padding: 1rem;
        
        > td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;

            > label {
                display: flex;
                column-gap: 4px;
                color: #292828;
                font-weight: 600;
                font-size: 0.9em;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
            
            > .aguardando {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #F16623;
                    }
                }
            }

            > .revisando {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #FDBC11;
                    }
                }
            }

            > .aprovado {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #40AD48;
                    }
                }
            }
        }

        > :nth-child(2),
        > :nth-child(3),
        > :nth-child(4) {
            width: 100%;
        }

        > td:last-child {
            border-bottom: none;
        }
    }
`;