import styled from 'styled-components';

export const PedidosContainer = styled.div`
    margin: 1rem 2rem;
 
    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        
        > .voltar {
            border: none;
            border-radius: 100%;
            background: #FDBC11;
            width: 25px;
            height: 25px;
            
            color: #292828;
            font-weight: 600;
            font-size: 1.4rem;
        }

        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #f9f9f9;
                stroke: #f9f9f9;
            }
        }

        > h1 {
            font-weight: 700;
        }
    };
`;

export const ListaLevasContainer = styled.div`

    > .filtros-container {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 1rem;
        margin-left: 1rem;

        > button {
            background-color: #FDBC11;
            color: #292828;
            font-weight: 700;
            border: none;
            padding: 10px;
            border-radius: 20px;
            width: 200px;
            min-width: 140px;
            height: 40px;
            margin-top: auto;
            margin-left: auto;
        }
    }

    @media screen and (max-width: 525px) {
        > .filtros-container {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 10px;
        }
    }
`;

export const ListaLevasVaziaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    height: 200px;
    margin: 1rem 2rem;

    > h1 {
        font-weight: 600;
        color: #292828;
    }
`;

export const LevasContainer = styled.div`
    display: flex;
    align-items: center;
    height: 120px;

    margin: 2rem 0;
    padding: 1.5rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > button {
        background-color: #FDBC11;
        color: #292828;
        font-weight: 700;
        border: none;
        padding: 10px;
        border-radius: 20px;
        min-width: 160px;
        width: 160px;
        height: 40px;
    }

    > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 90px;
        padding: 0 10px;
        border-right: 1px solid #E9E9E9;

        > h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            color: #292828;
            height: 50px;

            svg {
                width: 20px;
                height: 20px;
            }

            > .editar-svg {
                cursor: pointer;
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        > p {
            text-align: center;
            font-weight: 700;
            font-size: 1.3rem;
        }
    }
    
    > .item:last-child {
        border-right: none;
    }

    > .concluidos {
        svg {
            width: 18px;
            height: 18px;
            path {
                fill: #292828;
                stroke: #292828;
            }
        }
    }

    > .baixados {
        svg {
            width: 20px;
            height: 20px;
            path {
                fill: #292828;
            }
        }
    }

    @media (max-width: 1015px) {
        > button {
            height: 35px;
            font-size: 0.8rem;
            padding: 5px;
            width: 140px;
            min-width: 140px;
        }

        > .item {

            > h1 {
                font-size: 0.8rem;
            }

            > p {
                font-size: 1.1rem;
            }
        }
    }

    @media (max-width: 840px) {
        flex-direction: column;
        height: auto;
        padding: 1rem;

        > button {
            margin-top: 1rem;
        }

        > .item {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            height: 50px;
            border-right: none;
            border-bottom: 1px solid #E9E9E9;

            > h1 {
                height: 30px;
            }

            > p {
                font-size: 1rem;
            }
        }

        > .item:last-child {
            border-bottom: none;
        }
    }
`;

export const NenhumaLevaCriadaContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;
    padding-top: 20px;

    > h2 {
        color: #FDBC11; 
        font-weight: 600;
        font-size: 1.1rem;
    }

    > p {
        color: #292828;
    }

    > button {
        background-color: #FDBC11;
        color: #292828;
        font-weight: 700;
        border: none;
        padding: 10px;
        border-radius: 20px;
        width: 200px;
        min-width: 140px;
        height: 40px;
        margin: 20px 0;
    }
`;

export const ListaPedidosContainer = styled.div`
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 1rem;
    
    > .header {
        display: flex;
        column-gap: 10px;

        > .gerar-qrcode {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            width: 150px;
            height: 40px;
            padding: 10px;
            border: none;
            border-radius: 20px;
            background-color: #FDBC11;
            text-align: center;
            color: #292828;
            font-weight: 700;
        }

        > .novo-pedido {
            width: 150px;
            height: 40px;
            padding: 10px;
            border: none;
            border-radius: 20px;
            background-color: #FDBC11;

            text-align: center;
            text-decoration: none;
            color: #292828;
            font-weight: 700;
        }

        > .concluir-pedidos {
            display: flex;
            align-items: center;
            column-gap: 5px;
            border: none;
            border-radius: 20px;
            background: #292828;
            color: #FDBC11;
            padding: 0 15px;

            svg {
                margin-top: 4px;
                path, polygon, rect, circle, ellipse {
                    fill: #FDBC11;
                    stroke: #FDBC11;
                }
            }
        }
    }

    @media screen and (max-width: 840px) {
        > .header {
            > .novo-pedido {
                font-size: 0.8em;
                height: 35px;
                padding: 8px;
                min-width: 100px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        > .header {
            > .novo-pedido {
                width: 150px;
                height: 32px;
                padding: 8px;
                border: none;
                border-radius: 20px;
                background-color: #FDBC11;
    
                text-align: center;
                text-decoration: none;
                color: #292828;
                font-weight: 700;
                font-size: 0.7em;
            }
        }
    }
`;

export const PesquisarInputContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
    background: #F0F0F0;
    border-radius: 22px;
    width: 400px;
    margin-right: auto;

    > input {
        color: #292828;
        border: none;
        background: transparent;
        padding: 10px;
        padding-left: 20px;
        width: 100%;
        height: 40px;

        :focus {
            outline: none;
        }
    }

    > label {
        cursor: text;
    }

    @media screen and (max-width: 840px) {
        > input {
            height: 35px;
        }
    }

    @media screen and (max-width: 500px) {
        > input {
            font-size: 0.7em;
            height: 32px;
        }
        
        > label {
            svg {
                width: 15px;
                height: 15px;
            }
        }
    }
`;

export const FiltrosContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: ${({permissao}) => permissao === 'rn' ? 'flex-start' : 'space-between'};
    column-gap: 10px;
    margin: 1.5rem 0;

    > .download {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        width: 15%;
        min-width: 140px;
        height: 42px;
        background-color: transparent;
        border: none;
        border: 1px solid #292828;
        border-radius: 20px;
        color: #292828;

        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #292828;
            }
        }
    }

    @media screen and (max-width: 970px) {
        flex-wrap: wrap;
        row-gap: 10px;
        justify-content: center;
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 15%;
    min-width: 140px;

    > label {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #292828;
    }

    > select {
        width: 100%;
        height: 35px;
        background: #FDBC11;
        border: none;
        text-align: center;
        color: #292828;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    .filtro-select-container {
        height: 34px;
        border: none;

        .filtro-select__control {
            min-height: 34px;
            background: #FDBC11;
        }

        .filtro-select__value-container {
            color: #292828;

            .filtro-select__placeholder {
                color: #292828;
                text-align: center;
            }

            .filtro-select__single-value {
                font-weight: 600;
            }
        }

        .filtro-select__multi-value {
            .filtro-select__multi-value__label {
                min-width: 50px;
            }
            
            .filtro-select__multi-value__remove {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .filtro-select__clear-indicator {
            width: 25px;
            padding: 2px;
            svg {
                width: 15px;
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            } 
        }

        .filtro-select__dropdown-indicator {
            width: 25px;
            padding: 4px;
            svg {

                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            }
        }

        .filtro-select__option {
            color: #292828;
        }
    }
`;

export const TotalPedidosContainer = styled.div`
    display: flex;
    color: #292828;
    column-gap: 4px;
    
    svg {
        width: 20px;
        height: 20px;
        path, polygon, rect, circle, ellipse {
            fill: #FDBC11;
        }
    }
`;

export const PedidosTableContainer = styled.div`
    > table {
        > thead > tr > :nth-child(6),
        > thead > tr > :nth-child(7),
        > thead > tr > :nth-child(8),
        > thead > tr > :nth-child(9),
        > thead > tr > :nth-child(10),
        > thead > tr > :nth-child(11) {
            text-align: center;
        };

        > tbody > tr > :nth-child(6),
        > tbody > tr > :nth-child(7),
        > tbody > tr > :nth-child(8),
        > tbody > tr > :nth-child(9),
        > tbody > tr > :nth-child(10),
        > tbody > tr > :nth-child(11) {
            text-align: center;
        }
    }

    @media screen and (max-width: 970px) {
        > table {
            > thead > tr > :nth-child(9) {
                display: none;
            }

            > tbody > tr > :nth-child(9) {
                display: none;
            }
        }
    }

    @media screen and (max-width: 840px) {
        > table {
            > thead {
                display: none;
            }
        }
    }
`;

export const AlertaContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 0 2rem;
    margin: 1.5rem;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #fa5252;
    background: #FCDDDD;
    color: #fa5252;
`;