import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useAuth } from '../../providers/Auth';
import { paginador } from '../../utils/pager';
import { AdminVinculoVolumeCategoriaContainer, ContentContainer, PesquisarInputContainer, SelectContainer, TableContainer, TotalVinculosContainer } from './styles';
import CadastroVinculoSvg from '../../assets/cadastro-vinculo.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import VisualizarSvg from '../../assets/visualizar.svg'; 
import Pager from '../Pager';
import CustomTable from '../CustomTable';
import api from '../../services/api';
import VinculoVolumeCategoriaTr from '../Trs/VinculoVolumeCategoriaTr';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';

const AdminVinculoVolumeCategoria = () => {
    const [vinculos, setVinculos] = useState([]);

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');
    const [vinculosFiltrados, setVinculosFiltrados] = useState([]);
    const [vinculosPaginados, setVinculosPaginados] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [vinculosLoaded, setVinculosLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (vinculosLoaded && geosLoaded){
            setLoading(false);
        };
    }, [vinculosLoaded, geosLoaded]);

    const buscarVinculos = () => {
        setVinculosLoaded(false);
        api.get('/bebida-volume-categorias')
            .then((response) => {
                setVinculos(response.data.data.volumeCategorias);
                setVinculosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };
    
    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarVinculos();
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const vinculosFiltrados = vinculos.filter((vinculo) => {
            const filtroId = pesquisa ? String(vinculo.id) === String(pesquisa) : true;
            const filtroBebida = vinculo.bebida.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroVolume = vinculo.volume.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroCategoria = vinculo.categoria.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroGeo = geoSelecionada ? String(vinculo.geo_id) === String(geoSelecionada.value) : true;

            return (filtroId || filtroBebida || filtroVolume || filtroCategoria) && filtroGeo;
        });
        setVinculosFiltrados(vinculosFiltrados);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[vinculos, pesquisa, geoSelecionada]);

    useEffect(() => {
        const paginado = paginador(vinculosFiltrados, paginaAtual)
        setVinculosPaginados(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vinculosFiltrados, paginaAtual]);

    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
        let name = `vínculos_${formatedDate}.xlsx`;
        const fields = ['id','bebida','volume','categoria','geo','data_de_criacao','data_de_atualizacao'];
        const dadosFiltrados = vinculos.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'vínculos');
        XLSX.writeFile(wb,name);
    };

    return (
        <AdminVinculoVolumeCategoriaContainer>
            <div className='header'>
                <ReactSVG src={CadastroVinculoSvg}/> <h1>Cadastro de Vínculos</h1> 
            </div>  
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='header'>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-vínculo'
                                placeholder='Pesquisar vínculo'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-vínculo'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                        <Link to='/administrativo/vinculo/novo' className='novo'>
                            Novo
                        </Link>
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalVinculosContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${vinculosFiltrados.length} ${vinculosFiltrados.length === 1 ? 'vínculo' : 'vínculos'}`}
                    </TotalVinculosContainer>
                    <TableContainer>
                        <CustomTable 
                            header={[
                                'Id', 
                                'Bebida', 
                                'Volume', 
                                'Categoria', 
                                `${user.permissao === 'ac' ? 'Geo' : ''}`,
                                ' '
                            ]} 
                            message='Nenhum volume encontrado.'
                        >
                            {vinculosPaginados.map((vinculo) => {
                                return <VinculoVolumeCategoriaTr key={vinculo.id} vinculo={vinculo} buscarVinculos={buscarVinculos}/>
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminVinculoVolumeCategoriaContainer>
    );
};

export default AdminVinculoVolumeCategoria;
