import { FiltrosContainer, PedidosPorPdvContainer, PesquisarInputContainer, TableContainer, TrNotFound } from './styles';
import { ReactSVG } from 'react-svg';
import ArrowDownSvg from '../../assets/arrow-down.svg';
import HomeAguardandoSvg from '../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import { useState } from 'react';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { useEffect } from 'react';
import { paginador } from '../../utils/pager';
import Loading from '../Loading';
import Pager from '../Pager';
import PedidosPorPdvTr from '../Trs/PedidosPorPdvTr';
import * as XLSX from 'xlsx/xlsx.mjs';

const PedidosPorPdv = ({ pedidos, levaAtual, levasAtivas, anoSelecionado, geoSelecionada, operacaoSelecionada }) => {
    const [pdvs, setPdvs] = useState([]);
    const [pdvsFiltrados, setPdvsFiltrados] = useState([]);
    const [pdvsPaginados, setPdvsPaginados] = useState([]);
    
    const [pesquisa, setPesquisa] = useState('');

    const [tableOpened, setTableOpened] = useState(true);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [loading, setLoading] = useState(true);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const buscarPdvs = () => {
        setLoading(true);
        api.get('/pdvs')
            .then((response) => {
                setPdvs(response.data.data.pdvs);
                setLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarPdvs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        const pdvsFiltrados = pdvs.filter(pdv => {
            const filtroGeo = geoSelecionada ? String(pdv.geo_id) === String(geoSelecionada.value) : true;
            const filtroOperacao = operacaoSelecionada ? String(pdv.operacao_id) === String(operacaoSelecionada.value) : true;
            const filtroId = pesquisa ? String(pdv.id) === String(pesquisa) : true;
            const filtroNome = pesquisa ? pdv.nome.toLowerCase().includes(pesquisa.toLowerCase()) : true;
            const filtroStatus = pdv.status === 1;
            
            return (
                filtroStatus
                &&
                filtroGeo
                &&
                filtroOperacao
                && 
                (filtroId || filtroNome)
            );
        }).sort((pdvA, pdvB) => {
            //rankear pdvs pelo número de pedidos aprovados, caso seja 0, rankear pelo número de pedidos total
            const pedidosPdvA = pedidos.filter(pedido => String(pedido.ponto_de_venda_id) === String(pdvA.id));
            const pedidosPdvB = pedidos.filter(pedido => String(pedido.ponto_de_venda_id) === String(pdvB.id));
            const pedidosAprovadosPdvA = pedidosPdvA.filter(pedido => pedido.status === 'APROVADO');
            const pedidosAprovadosPdvB = pedidosPdvB.filter(pedido => pedido.status === 'APROVADO');
            const pedidosAprovadosPdvASize = pedidosAprovadosPdvA.length;
            const pedidosAprovadosPdvBSize = pedidosAprovadosPdvB.length;
            const pedidosPdvASize = pedidosPdvA.length;
            const pedidosPdvBSize = pedidosPdvB.length;
            if (pedidosAprovadosPdvASize > pedidosAprovadosPdvBSize) {
                return -1;
            } else if (pedidosAprovadosPdvASize < pedidosAprovadosPdvBSize) {
                return 1;
            } else if (pedidosPdvASize > pedidosPdvBSize) {
                return -1;
            } else if (pedidosPdvASize < pedidosPdvBSize) {
                return 1;
            } else {
                return 0;
            };
        });
        setPdvsFiltrados(pdvsFiltrados);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdvs, geoSelecionada, operacaoSelecionada, pedidos, pesquisa]);
    
    useEffect(() => {
        const paginado = paginador(pdvsFiltrados, paginaAtual);
        setPdvsPaginados(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdvsFiltrados, paginaAtual]);

    const criaRelatorio = () => {
        const name = `pedido_por_pdv${anoSelecionado ? `_${anoSelecionado}` : ''}.xlsx`;
        const dados = pdvsFiltrados.map(pdv => {
            const levasAtivasCond = ['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada;
            const pedidosPdv = pedidos.filter(pedido => String(pedido.ponto_de_venda_id) === String(pdv.id));
            const pedidosAguardando = pedidosPdv.filter(pedido => pedido.status === 'AGUARDANDO');
            const pedidosRevisar = pedidosPdv.filter(pedido => pedido.status === 'REVISAR');
            const pedidosAprovados = pedidosPdv.filter(pedido => pedido.status === 'APROVADO');
            let totalLeva = [];
            if (levasAtivasCond) {
                totalLeva = levasAtivas.reduce((acc, leva) => {
                    if (!leva.data_encerramento) {
                        return acc.concat(pedidosPdv.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                    };
                }, []);
            } else if (levaAtual) {
                totalLeva = pedidosPdv.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
            };
            return {
                id: pdv.id,
                pdv: pdv.nome,
                [`total pedidos ${anoSelecionado ? anoSelecionado : ''}`]: pedidosPdv.length,
                [`total ${levasAtivasCond ? 'levas ativas' : 'leva atual'}`]: totalLeva.length,
                aguardando: pedidosAguardando.length,
                revisar: pedidosRevisar.length,
                aprovados: pedidosAprovados.length,
            };
        });
        const ws = XLSX.utils.json_to_sheet(dados);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'pedidos_por_pdv');
        XLSX.writeFile(wb, name);
    };

    return (
        <PedidosPorPdvContainer>
            <div className='header'>
                <h1>Pedidos por PDV</h1>
                <button onClick={() => setTableOpened(!tableOpened)}>
                    {tableOpened ? (
                        <>
                        ver menos <ReactSVG src={ArrowDownSvg} />
                        </>
                    ) : (
                        <>
                        ver mais <ReactSVG style={{transform: 'rotate(180deg)', marginTop: '3px'}} src={ArrowDownSvg} />
                        </>
                    )}
                </button>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <TableContainer tableOpened={tableOpened}>
                    <FiltrosContainer>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-pdv'
                                placeholder='Pesquisar pdv'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-pdv'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                    </FiltrosContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        Id
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        PDV
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''} 
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                                    </div>
                                </th>
                                <th>
                                    <div className='aguardando'>
                                        <ReactSVG src={HomeAguardandoSvg}/> Aguardando
                                    </div>
                                </th>
                                <th>
                                    <div className='revisando'>
                                        <ReactSVG src={HomeRevisarSvg}/> Revisando
                                    </div>
                                </th>
                                <th>
                                    <div className='aprovado'>
                                        <ReactSVG src={HomeAprovadosSvg}/> Aprovado
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pdvsPaginados.length ? (
                                pdvsPaginados.map((pdv) => (
                                    <PedidosPorPdvTr key={pdv.id} pdv={pdv} pedidos={pedidos} levaAtual={levaAtual} levasAtivas={levasAtivas} geoSelecionada={geoSelecionada} anoSelecionado={anoSelecionado}/>   
                                ))
                            ) : (
                                <TrNotFound>
                                    <td>Nenhum pdv encontrado.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </TrNotFound>
                            )}
                        </tbody>
                    </table>
                    <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                </TableContainer>
            )}
        </PedidosPorPdvContainer>
    );
};

export default PedidosPorPdv; 