import styled from 'styled-components';

export const AdminEscadaContainer = styled.div`
    margin: 1rem 2rem;

    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px 20px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        margin: 1rem 0;
        
        > div {
            height: 18px;
            > div {
                height: 18px;
                svg {
                    width: 18px;
                    height: 18px;
                    path, polygon, rect, circle, ellipse {
                        fill: #f9f9f9;
                        stroke: #f9f9f9;
                    }
                }
            }
        }

        > h1 {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 840px) {
        margin: 1rem;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > .caption {
        display: flex;
        align-items: center;
        column-gap: 6px;
        border: 1px dashed #FDBC11;
        width: 278px;
        height: 48px;
        padding: 0 18px;
        margin-left: auto;
        
        > p {
            color: #000;

            > b {
                color: #000;
                font-weight: 600;
            }
        }
    }

    @media screen and (max-width: 840px) {
        padding: 1rem;
    }
`;

export const DegrausContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    > .captions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const BotaoAdicionarDegrau = styled.button`
  width: 324px;
  height: 42px;
  border: 1px solid #292828;
  border-radius: 20px;
  color: #000;
  margin: 20px 0 20px 0;
  background: #fff;
`;

export const BotaoRemoverDegrau = styled.button`
  width: 324px;
  height: 42px;
  border: 1px solid red;
  border-radius: 20px;
  color: red;
  margin-top: 20px;
  background: #fff;
`;

export const AddDegrau = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;