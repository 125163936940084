import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font: normal normal normal 14px/18px Poppins;
        color: #f0f0f0;
    }

    body {
        min-height: 100vh;
    }

    button {
        cursor: pointer;
    }

    .input-error {
        color: red;
        font-size: 14px;
        text-shadow: 1px 1px 1px #00000015;
        margin-top: 3px;
        padding-left: 5px;

        position: absolute;
        max-width: 100%;
        left: 0;
        
        z-index: 1;
        background-color: #FFFFFF;
        padding: 0.5em 0.7em;
        border-radius: 6px;
        box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
    };

    .input-error::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        
        bottom: 100%;
        left: 1em;
        border: .5rem solid transparent;
        border-top: none;

        border-bottom-color: #fff;
        filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    };
`

export default GlobalStyle;