import AdminCategoria from '../../components/AdminCategoria';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminCategoriaPageContainer } from './styles';

const AdminCategoriaPage = () => {

    return (
        <AdminCategoriaPageContainer>
            <Header />
            <AdminCategoria />
            <Footer />
        </AdminCategoriaPageContainer>
    )
};

export default AdminCategoriaPage;