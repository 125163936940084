import { ReactSVG } from 'react-svg';
import { AdminPdvNovoContainer, ContentContainer } from './styles';
import CadastroPontoDeVenda from '../../assets/cadastro-ponto-venda.svg';
import PdvForm from '../Forms/PdvForm';

const AdminPdvNovo = () => {

    return (
        <AdminPdvNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroPontoDeVenda}/> <h1>Cadastro de Ponto de Venda</h1> 
            </div>
            <ContentContainer>
                <PdvForm />
            </ContentContainer>
        </AdminPdvNovoContainer>
    );
};

export default AdminPdvNovo;