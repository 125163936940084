import * as yup from 'yup';
import volumeSchema from './volumeSchema';

const volumesSchema = yup.object().shape({
    volumes: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(volumeSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default volumesSchema;