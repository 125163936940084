import { FooterContainer } from './styles';
import { ReactSVG } from 'react-svg';
import LogoAmbevSvg from '../../assets/logo-ambev-preto.svg';
import { ReactComponent as IfollowLogoSvg } from '../../assets/ifollow-logo.svg';

const Footer = () => {

    return (
        <FooterContainer>
            <ReactSVG src={LogoAmbevSvg} alt='logo ambev' />
            <IfollowLogoSvg />
        </FooterContainer>
    );
};

export default Footer;