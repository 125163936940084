import VolumeForm from '../Forms/VolumeForm';
import { AdminVolumeEditarContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroVolumeSvg from '../../assets/cadastro-volume.svg';

const AdminVolumeEditar = () => {

    return (
        <AdminVolumeEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroVolumeSvg}/> <h1>Cadastro de Volumes</h1> 
            </div> 
            <ContentContainer>
                <VolumeForm />
            </ContentContainer>
        </AdminVolumeEditarContainer>   
    );
};

export default AdminVolumeEditar;