import { ArquivoContainer, ButtonsContainer, ContentContainer, ExemploContainer, ModalImportarPdvsContainer } from './styles';
import CustomModal from '../CustomModal';
import * as XLSX from 'xlsx';
import { FcDownload } from 'react-icons/fc';
import { useEffect, useRef, useState } from 'react';
import api from '../../../services/api';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import pdvsSchema from '../../../schemas/Pdv/pdvsSchema';
import Loading from '../../Loading';

const ModalImportarPdvs = ({setModalOpened}) => {
    const [operacoes, setOperacoes] = useState([]);
    const [arquivo, setArquivo] = useState('Selecionar arquivo...');
    const [dados, setDados] = useState('');
    const inputFileRef = useRef(null);

    const { logout } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [operacoesLoaded, setOperacoesLoaded] = useState(false);

    useEffect(() => {
        if (operacoesLoaded) {
            setLoading(false);
        };
    },[operacoesLoaded]);

    const buscarOperacoes = () => {
        api.get('/operacoes')
            .then((response) => {
                setOperacoes(response.data.data.operacoes);
                setOperacoesLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    }

    useEffect(() => {
        buscarOperacoes();
    },[]);

    const arquivoExemplo = () => {
        const dadosExemplo = [
            {
                nome: 'nome do pdv',
                cnpj: '00.000.000/0000-00',
                codigo: '123456',
                operacao_id: 1,
            }
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosExemplo);
        XLSX.utils.book_append_sheet(wb, ws, 'criar_pdvs');
        XLSX.writeFile(wb, 'criar_pdvs.xlsx');
    };

    const criarRelatorioOperacao = () => {
        const fields = ['id','nome', 'codigo'];
        const dadosFiltrados = operacoes.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'operacoes');
        XLSX.writeFile(wb, 'operacoes.xlsx');
    };

    const readUploadFile = (e) => {
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                setDados(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        };
    };

    const atualizar = async () => {
        setLoading(true);
        try {
            await pdvsSchema.validate({pdvs: dados})
        } catch (error) {
            if (error.message && error.path) {
                const errorPath = error.path;
                const linha = errorPath.slice(
                    errorPath.indexOf("[") + 1, 
                    errorPath.lastIndexOf("]")
                );
                toast.error(<div>{error.message}<br /> Linha: {Number(linha)+2}</div>)
            };
            setArquivo('Selecionar arquivo...');
            setDados('');
            inputFileRef.current.value = null;
            return;
        };
        
        api.post('/pdvs', {pdvs: dados})
            .then((response) => {
                toast.success('Pdvs cadastrados com sucesso!');
                navigate('/administrativo/ponto-de-venda');
            }).catch(error => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                if (error.response.status === 404) {
                    if (error.response.data.data?.pdv) {
                        toast.error(`Operação: ${error.response.data.data.pdv.operacao_id} não encontrada!`, {autoClose: false});
                    } else {   
                        toast.error('Operação não encontrada!');
                    };
                    return setLoading(false);
                };
                if (error.response?.status === 409) {
                    if (error.response.data.data?.pdv) {
                        toast.error(`Código: ${error.response.data.data.pdv.codigo} já cadastrado na operação ${error.response.data.data.pdv.operacao_id}!`, {autoClose: false});
                    } else {
                        toast.error('Já existe um ponto de venda cadastrado com esse Código nessa Operação!');
                    }
                    return setLoading(false);
                };
                setArquivo('Selecionar arquivo...');
                setDados('');
                inputFileRef.current.value = null;
                toast.error('Erro ao cadastrar ponto de venda');
                setLoading(false);
            });
    };

    return (
        <ModalImportarPdvsContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar pdvs</h1>
                        <p>
                            <button type='button' onClick={() => arquivoExemplo()}>Baixe aqui</button> o modelo de planilha
                        </p>
                        <ExemploContainer>
                            <div className='item'>
                                Coluna <strong>"operacao_id":</strong>
                                <p>
                                    preencher com <strong>id</strong> da <strong>operação</strong>.
                                </p>
                                <FcDownload onClick={criarRelatorioOperacao} size={18}/>
                            </div>
                        </ExemploContainer>
                        <ArquivoContainer>
                            <label>{'Arquivo (.xlsx)'}</label>
                            <label htmlFor='arquivo-xlsx' className='arquivo-input'>
                                <p>{arquivo}</p>
                                <label type='button' htmlFor='arquivo-xlsx'>
                                    selecionar
                                </label>
                            </label>
                            <input
                                id="arquivo-xlsx"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputFileRef}
                                onChange={(e) => {
                                    const event = e;
                                    readUploadFile(event);
                                    setArquivo(event.target.files[0].name);
                                    e.target.value = null;
                                }}
                            />
                        </ArquivoContainer>
                        <ButtonsContainer>
                            <button type='button' onClick={async () => await atualizar()} className='importar'>criar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarPdvsContainer>
    );
};

export default ModalImportarPdvs;