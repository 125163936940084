import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import { useAuth } from '../../../providers/Auth';

const PdvTr = ({ pdv }) => {
    const { user } = useAuth();

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{pdv.id}
            </td>
            <td>
                <label>Nome</label>
                {pdv.nome}
            </td>
            <td>
                <label>Operação</label>
                {pdv.operacao}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {pdv.geo}
                    </>
                )}
            </td>
            <td>
                <label>CNPJ</label>
                {pdv.cnpj}
            </td>
            <td>
                <label>Código</label>
                {pdv.codigo}
            </td>
            <td>
                <label></label>
                {['ac', 'admin'].includes(user.permissao) && (
                    <Link to={`/administrativo/ponto-de-venda/editar/${pdv.id}`}>
                        editar <ReactSVG src={EditarSvg} />
                    </Link>
                )}
            </td>
        </Tr>
    )
};

export default PdvTr;