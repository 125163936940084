import styled from 'styled-components';

export const Tr = styled.tr`

    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        > .aguardando,
        > .aprovado,
        > .reprovado,
        > .cancelado,
        > .revisar,
        > .baixado,
        > .concluido {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 4px;
            border-radius: 14px;
            padding: 5px;
            font-size: 0.8rem;
        }

        > .cardapio {
            color: #292828;
        }

        > .qrcode {
            svg {
                path, rect, circle, ellipse, polygon, polyline {
                    fill: ${({pedido}) => {
                        return (Number(pedido.qrcode) && ['CONCLUIDO', 'BAIXADO', 'APROVADO'].includes(pedido.status)) ? (
                            '#FDBC11'
                        ) : (
                            'rgba(112, 112, 112, 0.3)'
                        );
                    }};
                }
            }
        }

        > .aguardando {  
            background: #F16521;
        }

        > .aprovado {
            background: #40AD48;
        }

        > .reprovado {
            background: #ED1818;
        }

        > .revisar {
            background: #FDBC11;
        }

        > .baixado {
            background: #F16521;
        }

        > .concluido  {
            background: #292828;
        }      

        > label {
            display: none;
        }
    }

    @media (max-width: 840px) {
        display: flex;
        flex-direction: column;
        border: 1px solid #E9E9E9;
        border-radius: 15px;
        margin: 1rem 0;
        padding: 1rem;
        
        > td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            > label {
                display: flex;
                color: #292828;
                font-weight: 600;
            }
        }

        > td:last-child {
            border-bottom: none;
        }
    }
`;

export const VisualizarEditarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;

    > .visualizar,
    > .editar {
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: none;
        width: 26px;
        height: 26px;
        display: flex;
    };

    > .visualizar {
        background: #FDBC11;
    }

    > .editar {
        background: #292828;

        :disabled {
            background: #a0a0a0;
            cursor: not-allowed;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
    };
`;