import styled from 'styled-components';

export const ModalCancelarPedidoContainer = styled.div`

`;

export const ContentContainer = styled.div`
    padding: 3rem;
    min-width: 350px;
    width: 40vw;
    max-width: 500px;

    text-align: center;
    > p {
        color: #292828;
        font-size: 1.2em;
        margin-bottom: 5px;

        > span {
            color: #292828;
            font-weight: 600;
            font-size: 1em;
        };
    }

    > span {
        color: #ed1818;
        font-weight: 600;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin-top: 1.5rem;

    > button {
        border-radius: 20px;
        padding: 10px 40px;
        border: none;
    }

    > :first-child {
        background-color: #40ad48;
    }

    > :last-child {
        background-color: #ed1818;
    }
`;
