import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import ExcluirSvg from '../../../assets/excluir.svg';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { GrFormCheckmark , GrFormClose } from 'react-icons/gr';
import { moneyMask } from '../../../utils/masks';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';

const PedidoBebidaTr = ({ index, bebida, pedido, buscarPedidoBebidas, pedidoBebidas, setPedidoBebidasLoading }) => {
    const [editar, setEditar] = useState(false);
    const [preco, setPreco] = useState(bebida.preco);

    const [width, setWidth] = useState(0);
    const span = useRef();
    
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (span.current) setWidth(span.current.offsetWidth + 8);
    }, [preco]);

    const handleEditar = () => {
        api.patch(`pedido-bebidas/${pedido.id}`, {id: bebida.id, nome: bebida.nome, volume: bebida.volume, preco})
            .then((response) => {
                setPedidoBebidasLoading(true);
                setEditar(false);
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };

    const handleExcluir = () => {
        api.delete(`/pedido-bebidas/${pedido.id}`, {data: {pedido_bebida_id: bebida.id}})
            .then((response) => {
                setPedidoBebidasLoading(true);
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível excluir a bebida do pedido. Tente novamente mais tarde.')
            });
    };

    const handlePosicaoCima = () => {
        let bebidasUpdate = [];
        pedidoBebidas.forEach((pedidoBebida) => {
            if (Number(pedidoBebida.ordem) === Number(bebida.ordem)) {
                bebidasUpdate.push({
                    id: pedidoBebida.id,
                    ordem: Number(pedidoBebida.ordem) - 1
                });
            };
            if (Number(pedidoBebida.ordem) === Number(bebida.ordem) - 1) {
                bebidasUpdate.push({
                    id: pedidoBebida.id,
                    ordem: Number(pedidoBebida.ordem) + 1
                });
            };
        });
        api.patch(`pedido-bebidas/${pedido.id}/ordem`, {bebidas: bebidasUpdate})
            .then((response) => {
                setPedidoBebidasLoading(true);
                setEditar(false);
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };

    const handlePosicaoBaixo = () => {
        let bebidasUpdate = [];
        pedidoBebidas.forEach((pedidoBebida) => {
            if (Number(pedidoBebida.ordem) === Number(bebida.ordem)) {
                bebidasUpdate.push({
                    id: pedidoBebida.id,
                    ordem: Number(pedidoBebida.ordem) + 1
                });
            };
            if (Number(pedidoBebida.ordem) === Number(bebida.ordem) + 1) {
                bebidasUpdate.push({
                    id: pedidoBebida.id,
                    ordem: Number(pedidoBebida.ordem) - 1
                });
            };
        });
        api.patch(`pedido-bebidas/${pedido.id}/ordem`, {bebidas: bebidasUpdate})
        .then((response) => {
                setEditar(false);
                setPedidoBebidasLoading(true);
                buscarPedidoBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };

    return (
        <Tr>
            <td>
                <div className='ordenar'>
                    <button
                        disabled={Number(index) === 0}
                        onClick={handlePosicaoCima}
                    >
                        <MdOutlineArrowDropUp title='Mover produto pra cima' size={25} fill='#292828'/>
                    </button>
                    <button
                        disabled={Number(index+1) === pedidoBebidas.length}
                        onClick={handlePosicaoBaixo}
                    >
                        <MdOutlineArrowDropDown title='Mover produto pra baixo' size={25} fill='#292828'/>
                    </button>
                </div>
            </td>
            <td>
                <label>Produto</label>
                {bebida.nome}
            </td>
            <td>
                <label>Volume</label>
                {bebida.volume}
            </td>
            <td>
                <label>Preço</label>
                <div className='preco'>
                    {(bebida.preco !== '3 por 10,00' && bebida.preco !== '4 por 12,00') && 'R$'}
                    {editar ? (
                        <div>
                            <span style={{position: 'absolute', visibility: 'hidden'}} ref={span}>{preco}</span>
                            <input 
                                type='text' 
                                value={preco} 
                                style={{width}}
                                maxLength={6}
                                onChange={(e) => {                                
                                    setPreco(moneyMask(e.target.value))
                                }} 
                            />
                        </div>
                    ) : (
                        bebida.preco
                    )}
                </div>
            </td>
            <td>
                <label>Editar/Excluir</label>
                {editar ? (
                    <div>
                        <button className='salvar'>
                            <GrFormCheckmark size={20} onClick={handleEditar}/>
                        </button>
                        <button className='cancelar' onClick={() => setEditar(false)}>
                            <GrFormClose size={20}/>
                        </button>
                    </div>
                ) : (
                    <div>               
                        <button className='editar' onClick={() => setEditar(true)}>
                            <ReactSVG src={EditarSvg} />
                        </button>
                        <button className='excluir' onClick={handleExcluir}>
                            <ReactSVG src={ExcluirSvg} />
                        </button>
                    </div>
                )}
            </td>
        </Tr>
    );
};

export default PedidoBebidaTr;