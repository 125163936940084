import AdminOperacao from '../../components/AdminOperacao';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminOperacaoPageContainer } from './styles';

const AdminOperacaoPage = () => {

    return (
        <AdminOperacaoPageContainer>
            <Header />
            <AdminOperacao />
            <Footer />
        </AdminOperacaoPageContainer>
    );
};

export default AdminOperacaoPage;