import CustomModal from '../CustomModal';
import { ArquivoContainer, ButtonsContainer, ContentContainer, ExemploContainer, ModalImportarOperacoesContainer } from './styles';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../providers/Auth';
import { useEffect, useRef, useState } from 'react';
import { FcDownload } from 'react-icons/fc';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { UFs } from '../../../utils/states';
import operacoesSchema from '../../../schemas/Operacoes/operacoesSchema';
import { toast } from 'react-toastify';
import Loading from '../../Loading';

const ModalImportarOperacoes = ({ setModalOpened }) => {
    const [arquivo, setArquivo] = useState('Selecionar arquivo...');
    const [dados, setDados] = useState('');
    const inputFileRef = useRef(null);

    const [comerciais, setComerciais] = useState([]);
    
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [comerciaisLoaded, setComerciaisLoaded] = useState(false);

    useEffect(() => {
        if (comerciaisLoaded) {
            setLoading(false);
        }
    },[comerciaisLoaded]);

    const buscarComerciais = () => {
        api.get(`/comerciais`)
            .then(response => {
                setComerciais(response.data.data.comerciais);
                setComerciaisLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                }
                toast.error('Erro ao buscar Comercial!');
            });
    };

    useEffect(() => {
        buscarComerciais();
    },[]);

    const criarRelarioEstado = () => {
        const ws = XLSX.utils.json_to_sheet(UFs);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'estados');
        XLSX.writeFile(wb, 'estados.xlsx');
    };

    const criarRelatorioComercial = () => {
        const fields = ['id','nome'];
        if (user.permissao === 'ac') fields.push('geo')
        const dadosFiltrados = comerciais.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'comerciais');
        XLSX.writeFile(wb, 'comerciais.xlsx');
    };

    const arquivoExemplo = () => {
        const dadosExemplo = [
            {
                nome: 'nome da operação',
                tipo: 'CDD',
                codigo: '123456',
                estado: 'RJ',
                comercial_id: '1',
            }
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosExemplo);
        XLSX.utils.book_append_sheet(wb, ws, 'criar_operações');
        XLSX.writeFile(wb, 'criar_operações.xlsx');
    };

    const readUploadFile = (e) => {
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                setDados(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        };
    };

    const handleCriarOperacoes = async () => {
        try {
            const operacoesDados = {
                operacoes: dados.map(item => {
                    const estado = item.estado ? { value: item.estado, label: item.estado } : null;
                    const comercial = item.comercial_id ? { value: item.comercial_id, label: item.comercial_id } : null;

                    return {
                        ...item,
                        estado,
                        comercial,
                    }
                })
            }
            await operacoesSchema.validate(operacoesDados);
        } catch (error) {
            if (error.message && error.path) {
                const errorPath = error.path;
                const linha = errorPath.slice(
                    errorPath.indexOf("[") + 1, 
                    errorPath.lastIndexOf("]")
                );
                toast.error(<div>{error.message}<br /> Linha: {Number(linha)+2}</div>)
            };
            setArquivo('Selecionar arquivo...');
            setDados('');
            inputFileRef.current.value = null;
            return;
        };

        api.post('/operacoes', {operacoes: dados})
            .then(response => {
                toast.success('Operações cadastradas com sucesso!');
                navigate('/administrativo/operacao');
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    return;
                };
                if (error.response.status === 404) {
                    toast.error('Comercial não encontrado!');
                    return;
                };
                if (error.response.status === 409) {
                    if (error.response.data.data?.operacao) {
                        const message = error.response.data.message;
                        const operacao = error.response.data.data.operacao;
                        const info = operacao.nome ? 'Nome' : 'Código';
                        toast.error(`${message} ${info}: ${operacao.nome ? operacao.nome : operacao.codigo}`, {autoClose: false});
                        return;
                    }
                    toast.error('Operação já cadastrada!');
                    return;
                };
                setArquivo('Selecionar arquivo...');
                setDados('');
                inputFileRef.current.value = null;
                toast.error('Erro ao cadastrar Operação!');
            });
    };

    return (
        <ModalImportarOperacoesContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar Operações</h1>
                        <p>
                            <button type='button' onClick={() => arquivoExemplo()}>Baixe aqui</button> o modelo de planilha
                        </p>
                        <ExemploContainer>
                            <div className='item'>
                                Coluna <strong>"estado":</strong>
                                <p>
                                    preencher com <strong>sigla</strong> do <strong>estado</strong>.
                                </p>
                                <FcDownload onClick={criarRelarioEstado} size={18}/>
                            </div>
                            <div className='item'>
                                Coluna <strong>"comercial_id":</strong>
                                <p>
                                    preencher com <strong>id</strong> do <strong>comercial</strong>.
                                </p>
                                <FcDownload onClick={criarRelatorioComercial} size={18}/>
                            </div>
                        </ExemploContainer>
                        <ArquivoContainer>
                            <label>{'Arquivo (.xlsx)'}</label>
                            <label htmlFor='arquivo-xlsx' className='arquivo-input'>
                                <p>{arquivo}</p>
                                <button type='button'>
                                    selecionar
                                </button>
                            </label>
                            <input
                                id="arquivo-xlsx"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputFileRef}
                                onChange={(e) => {
                                    const event = e;
                                    readUploadFile(event);
                                    setArquivo(event.target.files[0].name);
                                    e.target.value = null;
                                }}
                                />
                        </ArquivoContainer>
                        <ButtonsContainer>
                            <button type='button' onClick={async () => await handleCriarOperacoes()} className='importar'>criar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarOperacoesContainer>
    );
};

export default ModalImportarOperacoes;