import styled from 'styled-components';

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    > h1 {
        text-align: center;
        color: #f9f9f9;
        font-size: 4em;
        width: 500px;
        line-height: 1em;
        font-weight: 400;

        > span {
            color: #FDBC11;
            font-size: 1em;
            font-weight: bold;
        }
    }

    > p {
        margin: 20px 0;
    }

    @media screen and (max-width: 900px) {
        > h1 {
            font-size: 2.5em;
            width: 300px;
        }

        > p {
            font-size: 0.9em;
        };
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 20px;
    width: 60%;
`;

export const CodigoInputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 35px;
    padding: 0 10px;
    border-bottom: 1px solid #F0F0F0;

    position: relative;

    > label {
        width: 20px;
    };

    > input {    
        width: 100%;
        border: none;
        background: transparent;
        padding: 0 5px;

        ::placeholder {
            color: #F0F0F0;
        }

        :focus {
            outline: none;
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: #F0F0F0;
        -webkit-box-shadow: 0 0 0px 1000px #292828 inset;
        transition: background-color 5000s ease-in-out 0s;
    }
`;

export const PasswordInputContainer = styled(CodigoInputContainer)`
    > svg {
        cursor: pointer;

        path {
            ${({showPassword}) => showPassword ? 'fill: #fff;' : ''}
        }
    }
`;

export const LoginButton = styled.button`
    min-width: 200px;
    width: 80%;
    height: 42px;
    margin-top: 20%;
    background: #F16521;

    font-weight: 600;
    color: #FFFFFF;

    border: none;
    border-radius: 20px;

    @keyframes grow {
        from {transform: scale(1,1)}
        to {transform: scale(1.3,1.3)}
    }

    > .loading-container{
        margin: auto;
        width: min-content;
        display: flex;
        position: relative;
        .circle{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #fff;
            margin-right: 10px;
                
            &:first-child{
                animation: grow 700ms linear 100ms infinite;
            }

            &:nth-child(2) {
                animation: grow 700ms linear 200ms infinite;
            }

            &:nth-child(3) {
                animation: grow 700ms linear 300ms infinite;
            }
            
            &:last-child{
                animation: grow 700ms linear 400ms infinite;
            }
        }
    }
`;	