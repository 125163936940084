import { ReactSVG } from 'react-svg';
import UsuarioForm from '../Forms/UsuarioForm';
import { AdminUsuarioEditarContainer, ContentContainer } from './styles';
import CadastroUsuarioSvg from '../../assets/cadastro-usuario.svg';

const AdminUsuarioEditar = () => {
    return (
        <AdminUsuarioEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroUsuarioSvg}/> <h1>Editar Usuario</h1> 
            </div>
            <ContentContainer>
                <UsuarioForm />
            </ContentContainer>
        </AdminUsuarioEditarContainer>
    );
};

export default AdminUsuarioEditar;