import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AdicionarButton, FormContainer, InputContainer, PedidoCategoriaProdutosContainer, TableContainer, TitleContainer } from './styles';
import { moneyMask } from '../../utils/masks';
import CustomTable from '../CustomTable';
import PedidoProdutoTr from '../Trs/PedidoProdutoTr';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import pedidoCategoriaProdutoSchema from '../../schemas/PedidoCategoriaProdutos/pedidoCategoriaProdutoSchema';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { FaSortAmountDownAlt, FaSortAmountUp } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { useState, useEffect } from 'react';

const PedidoCategoriaProdutos = ({ index, categoria, pedido, outrasCategoriasValue, buscarPedidoProdutoCategorias, setOutrasCategoriasLoading, pedidoProdutos, buscarPedidoProdutos, pedidoProdutosLoaded }) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(pedidoCategoriaProdutoSchema(pedido))
    });

    const [pedidoProdutosOrdenados, setPedidoProdutosOrdenados] = useState([]);
    const [pedidoProdutosLoading, setPedidoProdutosLoading] = useState(true);

    const { logout } = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (pedidoProdutosLoaded) {
            const produtosOrdenados = pedidoProdutos.sort((a, b) => {
                const precoA = Number(a.preco.replace(',', '.'));
                const precoB = Number(b.preco.replace(',', '.'));
                if (categoria.ordem === 'crescente') {
                    if (precoA > precoB) {
                        return 1
                    } else if (precoA < precoB) {
                        return -1
                    }
                };
                if (categoria.ordem === 'decrescente') {
                    if (precoA < precoB) {
                        return 1
                    } else if (precoA > precoB) {
                        return -1
                    }
                };
                if (categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente') {
                    return Number(a.ordem) - Number(b.ordem)
                };
            });
            setPedidoProdutosOrdenados(produtosOrdenados);
            setPedidoProdutosLoading(false);
        };
    }, [pedidoProdutosLoaded]);

    const onSubmitFunction = (data) => {
        const ultimoProduto = pedidoProdutos.at(-1);
        const dados = {
            ...data,
            pedido_produto_categoria_id: categoria.pedido_produto_categoria_id,
            ordem: ultimoProduto ? ultimoProduto.ordem+1 : 1,
        };
        api.post(`/pedido-produtos/${pedido.id}`, dados)
            .then((response) => {
                buscarPedidoProdutos();
                reset();
            })
            .catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível adicionar o produto ao pedido. Tente novamente mais tarde.')
            });
    };

    const handlePosicaoCima = () => {
        setOutrasCategoriasLoading(true);
        let categoriasUpdate = [];
        outrasCategoriasValue.forEach((categoriaValue) => {
            if (Number(categoriaValue.posicao) === Number(categoria.posicao)){
                categoriasUpdate.push({
                    id: categoriaValue.pedido_produto_categoria_id,
                    posicao: Number(categoriaValue.posicao) - 1
                });
            };
            if (Number(categoriaValue.posicao) === Number(categoria.posicao) - 1){
                categoriasUpdate.push({
                    id: categoriaValue.pedido_produto_categoria_id,
                    posicao: Number(categoriaValue.posicao) + 1
                });
            };
        });
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {categorias: categoriasUpdate})
            .then((response) => {
                buscarPedidoProdutoCategorias();
            })
            .catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível alterar a posição da categoria. Tente novamente mais tarde.')
                setOutrasCategoriasLoading(false);
            });
    };

    const handlePosicaoBaixo = () => {
        setOutrasCategoriasLoading(true);
        let categoriasUpdate = [];
        outrasCategoriasValue.forEach((categoriaValue) => {
            if (Number(categoriaValue.posicao) === Number(categoria.posicao)){
                categoriasUpdate.push({
                    id: categoriaValue.pedido_produto_categoria_id,
                    posicao: Number(categoriaValue.posicao) + 1
                });
            };
            if (Number(categoriaValue.posicao) === Number(categoria.posicao) + 1){
                categoriasUpdate.push({
                    id: categoriaValue.pedido_produto_categoria_id,
                    posicao: Number(categoriaValue.posicao) - 1
                });
            };
        });
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {categorias: categoriasUpdate})
            .then((response) => {
                buscarPedidoProdutoCategorias();
            })
            .catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível alterar a posição da categoria. Tente novamente mais tarde.')
                setOutrasCategoriasLoading(false);
            });
    };

    const handleRemover = () => {
        setOutrasCategoriasLoading(true);
        const dados = {
            id: categoria.pedido_produto_categoria_id,
            ordem: 'null',
        };
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoProdutoCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setOutrasCategoriasLoading(false);
        });
    };

    const handleOrdenarCrescente = () => {
        setOutrasCategoriasLoading(true);
        const dados = {
            id: categoria.pedido_produto_categoria_id,
            ordem: 'crescente',
        };
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoProdutoCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setOutrasCategoriasLoading(false);
        });
    };

    const handleOrdenarDecrescente = () => {
        setOutrasCategoriasLoading(true);
        const dados = {
            id: categoria.pedido_produto_categoria_id,
            ordem: 'decrescente',
        };
        api.patch(`/pedido-produto-categorias/${pedido.id}`, {categorias: [dados]})
        .then((response) => {
            buscarPedidoProdutoCategorias();
        }).catch((error) => {
            if (error.response?.status === 401){
                logout();
                navigate('/');
                return;
            };
            toast.error(error.response.data.message);
            setOutrasCategoriasLoading(false);
        });
    };

    return (
        <PedidoCategoriaProdutosContainer>
            <TitleContainer>
                <button
                    disabled={Number(index) === 0}
                    onClick={handlePosicaoCima}
                >
                    <FiArrowUp title='Mover categoria pra cima' stroke='#292828'/>
                </button>
                <button
                    disabled={Number(index+1) === outrasCategoriasValue.length}
                    onClick={handlePosicaoBaixo}
                >
                    <FiArrowDown title='Mover categoria pra baixo' stroke='#292828'/>
                </button>
                <p>
                    {categoria.label}
                </p>
                <div className='sort-itens-container'>
                    <button
                        onClick={handleRemover}
                        disabled={categoria.ordem !== 'crescente' && categoria.ordem !== 'decrescente'}
                    >
                        <GrFormClose title='Remover ordenação' size={16} />
                    </button>
                    <button
                        onClick={handleOrdenarCrescente}
                        disabled={categoria.ordem === 'crescente'}
                    >
                        <FaSortAmountDownAlt title='Ordenar preço crescente' fill='#292828' size={18} />
                    </button>
                    <button
                        onClick={handleOrdenarDecrescente}
                        disabled={categoria.ordem === 'decrescente'}
                    >
                        <FaSortAmountUp title='Ordenar preço decrescente' fill='#292828' size={18} />
                    </button>
                </div>
            </TitleContainer>
            <FormContainer onSubmit={handleSubmit(onSubmitFunction)}>
                <InputContainer className='nome'>
                    <input 
                        type='text'
                        placeholder='Digite o nome'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                </InputContainer>
                <InputContainer className='volume'>
                    <input
                        type='text'
                        placeholder='Digite o volume'
                        {...register('volume')}
                    />
                    {errors.volume && <span className='input-error'>{errors.volume.message}</span>}
                </InputContainer>
                <InputContainer className='preco'>
                    <label htmlFor={`preco_${categoria.value}`}>R$</label>
                    <input
                        type='text'
                        id={`preco_${categoria.value}`}
                        placeholder='0,00'
                        maxLength='6'
                        {...register('preco')}
                        onChange={(e)=>{
                            const valDigitado = moneyMask(e.target.value);
                            setValue('preco', `${valDigitado}`);
                        }}
                    />
                    {errors.preco && <span className='input-error'>{errors.preco.message}</span>}
                </InputContainer>
                <AdicionarButton>
                    adicionar
                </AdicionarButton>
            </FormContainer>
            <TableContainer>
                <CustomTable header={['#','Produto', 'Volume', 'Preço', 'Editar/Excluir']} message='Você ainda não adicionou um produto.' isLoading={pedidoProdutosLoading}>
                    {pedidoProdutosOrdenados.map((pedidoProduto, index) => (
                        <PedidoProdutoTr 
                            key={pedidoProduto.id}
                            index={index}
                            produto={pedidoProduto}
                            pedido={pedido}
                            buscarPedidoProdutos={buscarPedidoProdutos}
                            pedidoProdutos={pedidoProdutosOrdenados}
                            setPedidoProdutosLoading={setPedidoProdutosLoading}
                        />  
                    ))}
                </CustomTable>
            </TableContainer>
        </PedidoCategoriaProdutosContainer>
    );
};

export default PedidoCategoriaProdutos;