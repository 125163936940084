import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    
    > thead > tr > th {
        color: #292828;
        padding-left: 10px;
        height: 40px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.7);
        font-weight: 500;
    }

    > thead > tr {
        .center {
            text-align: center;
        }
    }

    > tbody > .message > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
    }
`;

export const LoadingTr = styled.tr`
    @keyframes animation-loading {
        0% {
            opacity: 0;
        }

        40% {
            opacity: 1;
        }

        80% {
            opacity: 0;
        }

        100% {
            opacity: 0;
        }
    }

    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        
        > div {
            display: flex;
            padding: 15px 20px;
            column-gap: 8px;

            > span {
                background-color: #292828;
                border-radius: 1em;
                display: inline-block;
                height: 1em;
                vertical-align: top;
                width: 1em;
            }

            > span:first-child {
                animation: animation-loading 1s ease-in-out 0ms infinite;
            }

            > span:nth-child(2) {
                animation: animation-loading 1s ease-in-out 160ms infinite;
            }

            > span:last-child {
                animation: animation-loading 1s ease-in-out 320ms infinite;
            }
        }
    }
`;