import { PaginatorContainer, PdfContainer, PdfViewerContainer } from './styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import api from '../../services/api';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import QrBrahmaPng from '../../assets/qr-brahma.png';
import QrBrahmaDmPng from '../../assets/qr-brahma-dm.png';
import QrBudPng from '../../assets/qr-bud.png';
import QrSpatenPng from '../../assets/qr-spaten.png';
import QrOriginalPng from '../../assets/qr-original.png';
import QrStellaPng from '../../assets/qr-stella.jpg';
import Loading from '../Loading';
import { toast } from 'react-toastify';

const PdfViewer = ({ fileUrl, pedido }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [file, setFile] = useState(null);

    const [errorPdf, setErrorPdf] = useState(false);

    const [loading, setLoading] = useState(true);
    
    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
    });
      
    const onDocumentLoadSuccess = ({ numPages }) => {
        let pages = Number(numPages);
        if (Number(pedido.qrcode)) pages += 1;
        setNumPages(pages);
        setPageNumber(1);
    }
    
    const changePage = (offset) => {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
    
    const previousPage = () => {
      changePage(-1);
    }
    
    const nextPage = () => {
      changePage(1);
    }

    useEffect(() => {
        api.get(`${fileUrl}`, {responseType: 'blob'})
            .then((response) => {
                const file = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                setFile(file);
                setLoading(false);
            }).catch((error) => {
                setErrorPdf(true);
                setLoading(false);
            });
    }, [fileUrl]);
    
    return (
        <PdfViewerContainer>
            {loading ? (
                <Loading />
            ) : (
                <>
                {file && (
                    <>
                    <PaginatorContainer>
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                            <BsArrowLeft size={20} /> anterior
                        </button>
                            Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            próxima <BsArrowRight size={20} />
                        </button>
                    </PaginatorContainer>
                    <PdfContainer pedido={pedido} pageNumber={pageNumber} numPages={numPages}>
                        <div className='qr-code'>
                            <figure>
                                {pedido.template === 'brahma' && (
                                    <img src={QrBrahmaPng} alt='QR Code Brahma' />
                                )}
                                {pedido.template === 'brahma litrinho' && (
                                    <img src={QrBrahmaPng} alt='QR Code Brahma' />
                                )}
                                {pedido.template === 'brahma duplo malte' && (
                                    <img src={QrBrahmaDmPng} alt='QR Code Brahma DM' />
                                )}
                                {pedido.template === 'budweiser' && (
                                    <img src={QrBudPng} alt='QR Code Bud' />
                                )}
                                {pedido.template === 'spaten' && (
                                    <img src={QrSpatenPng} alt='QR Code Spaten' />
                                )}
                                {pedido.template === 'original' && (
                                    <img src={QrOriginalPng} alt='QR Code Original' />
                                )}
                                {pedido.template === 'stella' && (
                                    <img src={QrStellaPng} alt='QR Code Stella' />
                                )}
                            </figure>
                        </div>
                        <Document
                            className='pdf'
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </PdfContainer>
                    <PaginatorContainer>
                        <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                        >
                        <BsArrowLeft size={20} /> anterior
                        </button>
                            Página {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
                        <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                        >
                            próxima <BsArrowRight size={20} />
                        </button>
                    </PaginatorContainer>
                    </>
                )}
                {errorPdf && (
                    <span className='notfound'>Pdf não encontrado.</span>
                )}
                </>
            )}
        </PdfViewerContainer>
    );
};

export default PdfViewer;