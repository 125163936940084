import { AdminGeoContainer, ContentContainer, PesquisarInputContainer, TableContainer, TotalGeosContainer } from './styles';
import { ReactComponent as CadastroGeoSvg } from '../../assets/cadastro-geo.svg';
import { ReactComponent as PesquisarSvg } from '../../assets/pesquisar.svg';
import { ReactComponent as DownloadSvg } from '../../assets/download.svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import { useEffect, useState } from 'react';
import CustomTable from '../CustomTable';
import api from '../../services/api';
import { useAuth } from '../../providers/Auth';
import GeoTr from '../Trs/GeoTr';
import { Link, useNavigate } from 'react-router-dom';
import { paginador } from '../../utils/pager';
import Pager from '../Pager';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import { ReactSVG } from 'react-svg';

const AdminGeo = () => {
    const [geos, setGeos] = useState([]);
    
    const [pesquisa, setPesquisa] = useState('');
    const [geosFiltradas, setGeosFiltradas] = useState([]);
    const [geosPaginadas, setGeosPaginadas] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [loading, setLoading] = useState(true);

    const { logout } = useAuth();
    const navigate = useNavigate();

    const buscarGeos = () => {
        setLoading(true);
        api.get('/geos')
            .then((response) => {
                setGeos(response.data.data.geos);
                setLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarGeos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        const geosFiltrados = geos.filter((geo) => {
            const filtroId = pesquisa ? String(geo.id) === String(pesquisa) : true;
            const filtroNome = geo.nome.toLowerCase().includes(pesquisa.toLowerCase());
            return filtroNome || filtroId;
        });
        setGeosFiltradas(geosFiltrados);
       
    },[geos, pesquisa]);

    useEffect(() => {
        const paginado = paginador(geosFiltradas, paginaAtual);
        setGeosPaginadas(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    }, [geosFiltradas, paginaAtual]);

    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
        let name = `geos_${formatedDate}.xlsx`;
        const fields = ['id','nome','data_de_criacao','data_de_atualizacao'];
        const dadosFiltrados = geos.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'geos');
        XLSX.writeFile(wb,name);
    };

    return (
        <AdminGeoContainer>
            <div className='header'>
                <CadastroGeoSvg /> <h1>Cadastro de Geo</h1> 
            </div>  
            {loading ? (
                <Loading />
            ) : (                
                <ContentContainer>
                    <div className='header'> 
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-região'
                                placeholder='Pesquisar região'
                                value={pesquisa}
                                onChange={(e)=>setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-região'>
                                <PesquisarSvg />
                            </label>
                        </PesquisarInputContainer>
                        <button onClick={criaRelatorio} className='extrair'>
                            <DownloadSvg /> extrair lista
                        </button>
                        <Link to='/administrativo/geo/novo' className='novo'>
                            Novo
                        </Link>
                    </div>
                    <TotalGeosContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${geosFiltradas.length} ${geosFiltradas.length === 1 ? 'geo' : 'geos'}`}
                    </TotalGeosContainer>
                    <TableContainer>
                        <CustomTable header={['Id', 'Nome da região', 'Criação', ' ']} message='Nenhuma geo encontrada.'>
                            {geosPaginadas.map((geo) => {
                                return <GeoTr key={geo.id} geo={geo}/>
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminGeoContainer>
    );
};

export default AdminGeo;