import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PedidosNovo from '../../components/PedidosNovo';
import { PedidosNovoPageContainer } from './styles';

const PedidosNovoPage = () => {

    return (
        <PedidosNovoPageContainer>
            <Header />
            <PedidosNovo />
            <Footer />
        </PedidosNovoPageContainer>
    );
};

export default PedidosNovoPage;