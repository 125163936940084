import { useState } from 'react';
import { Tr } from './styles';
import { useEffect } from 'react';
import { useAuth } from '../../../providers/Auth';
import { ReactSVG } from 'react-svg';
import HomeAguardandoSvg from '../../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../../assets/home-aprovados.svg';

const PedidosPorOperacaoTr = ({ operacao, pedidos, levaAtual, levasAtivas, geoSelecionada, anoSelecionado }) => {
    const [pedidosOperacao, setPedidosOperacao] = useState([]);
    const [pedidosOperacaoLevaAtual, setPedidosOperacaoLevaAtual] = useState([]);
    const [pedidosOperacaoAguardando, setPedidosOperacaoAguardando] = useState([]);
    const [pedidosOperacaoRevisar, setPedidosOperacaoRevisar] = useState([]);
    const [pedidosOperacaoAprovado, setPedidosOperacaoAprovado] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        const pedidosOperacao = pedidos.filter(pedido => String(pedido.operacao_id) === String(operacao.id));
        const pedidosAguardando = pedidosOperacao.filter(pedido => pedido.status === 'AGUARDANDO');
        const pedidosRevisar = pedidosOperacao.filter(pedido => pedido.status === 'REVISAR');
        const pedidosAprovado = pedidosOperacao.filter(pedido => pedido.status === 'APROVADO');

        setPedidosOperacao(pedidosOperacao);
        setPedidosOperacaoAguardando(pedidosAguardando);
        setPedidosOperacaoRevisar(pedidosRevisar);
        setPedidosOperacaoAprovado(pedidosAprovado);

        if (['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada) {
            const pedidosLevasAtivas = levasAtivas.reduce((acc, leva) => {
                if (!leva.data_encerramento) {
                    return acc.concat(pedidosOperacao.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                };
            },  []);
            setPedidosOperacaoLevaAtual(pedidosLevasAtivas);
        } else if (levaAtual) {
            const pedidosLevaAtual = pedidosOperacao.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
            setPedidosOperacaoLevaAtual(pedidosLevaAtual);
        } else {
            setPedidosOperacaoLevaAtual([]);
        }
    },[pedidos]);

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{operacao.id}
            </td>
            <td>
                <label>Operação / Tipo</label>
                <div>
                    <p>{operacao.nome}</p>
                    <span>{operacao.tipo}</span>
                </div>
            </td>
            <td>
                <label>
                    Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''}
                </label>
                {pedidosOperacao.length}
            </td>
            <td>
                <label>
                    {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                </label>
                {pedidosOperacaoLevaAtual.length}
            </td>
            <td>
                <label className='aguardando'>
                    Aguardando <ReactSVG src={HomeAguardandoSvg}/>
                </label>
                {pedidosOperacaoAguardando.length}
            </td>
            <td>
                <label className='revisando'>
                    Revisando <ReactSVG src={HomeRevisarSvg}/>
                </label>
                {pedidosOperacaoRevisar.length}
            </td>
            <td>
                <label className='aprovado'>
                    Aprovado <ReactSVG src={HomeAprovadosSvg}/>
                </label>
                {pedidosOperacaoAprovado.length}
            </td>
        </Tr>
    )
};

export default PedidosPorOperacaoTr;