import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactComponent as EditarSvg } from '../../../assets/editar.svg';
import { useAuth } from '../../../providers/Auth';

const ComercialTr = ({ comercial }) => {
    const { user } = useAuth();

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{comercial.id}
            </td>
            <td>
                <label>Comercial</label>
                {comercial.nome}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {comercial.geo}
                    </>
                )}
            </td>
            <td>
                <label>Criação</label>
                {comercial.data_de_criacao}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/comercial/editar/${comercial.id}`} className='editar'>
                    editar <EditarSvg />
                </Link>
            </td>
        </Tr>
    );
};

export default ComercialTr;