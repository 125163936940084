import { ReactSVG } from 'react-svg';
import { AdminUsuarioNovoContainer, ContentContainer } from './styles';
import CadastroUsuarioSvg from '../../assets/cadastro-usuario.svg';
import UsuarioForm from '../Forms/UsuarioForm';

const AdminUsuarioNovo = () => {

    return (
        <AdminUsuarioNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroUsuarioSvg}/> <h1>Cadastrar Novo Usuário</h1> 
            </div>
            <ContentContainer>
                <UsuarioForm />
            </ContentContainer>
        </AdminUsuarioNovoContainer>
    );
};

export default AdminUsuarioNovo;