import { ContentContainer, CustomModalContainer } from './styles';
import { ReactComponent as FecharSvg } from '../../../assets/fechar.svg';

const CustomModal = ({ children, setModalOpened }) => {

    return (
        <CustomModalContainer onClick={() => setModalOpened(false)}>
            <ContentContainer onClick={(e) => e.stopPropagation()}>
                <button className="fechar" onClick={() => setModalOpened(false)}>
                    <FecharSvg />
                </button>
                {children}
            </ContentContainer>
        </CustomModalContainer>
    );
};

export default CustomModal;