import { ButtonsContainer, PedidoLevaContainer } from './styles';
import { ReactSVG } from 'react-svg';
import HomeMinimoPedidosSvg from '../../assets/home-minimo-pedidos.svg'; 
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import HomeDataCorteSvg from '../../assets/home-data-corte.svg';
import HomeDiasRestantesSvg from '../../assets/home-dias-restantes.svg';
import { useEffect, useState } from 'react';
import DownloadPedidosButton from '../DownloadPedidosButton';
import { useAuth } from '../../providers/Auth';
import { FaRegCalendarTimes } from 'react-icons/fa';

const PedidoLeva = ({ leva, setLeva, aprovados, buscarPedidos }) => {
    const [diasRestantes, setDiasRestantes] = useState('');

    const { user } = useAuth();

    useEffect(()=>{
        const [day, month, year] = leva.data_final.split('/')
        const data = new Date();
        const dia = String(data.getDate());
        const mes = String(data.getMonth() + 1);
        const ano = data.getFullYear();

        const date1 = new Date(year, month - 1, day);
        const date2 = new Date(ano, mes - 1, dia);
        const diffTime = date1 - date2;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        setDiasRestantes(diffDays);
    },[leva])

    return (
        <PedidoLevaContainer>
            <h2>
                {user.permissao === 'mari' && (
                    <p><span>Cliente: </span>{leva.cliente}</p>
                )}
                {leva.id} - Leva {leva.geo_nome}
            </h2>
            <div className='item'>
                <h1>
                    {leva && <ReactSVG src={HomeMinimoPedidosSvg} />} Mínimo de pedidos
                </h1>
                <p>{leva.quantidade_min ? leva.quantidade_min : '-'}</p>
            </div>
            <div className='item'>
                <h1>
                    {leva && <ReactSVG src={HomeAprovadosSvg} />} Total aprovados
                </h1>
                <p style={{color: '#40AD48'}}>{leva ? aprovados.length : '-'}</p>
            </div>
            <div className='item'>
                <h1>
                    {leva && <ReactSVG src={HomeDataCorteSvg} />} Data de corte
                </h1>
                <p>{leva.data_final}</p>
            </div>
            {leva.data_encerramento ? (
                <div className='item'>
                    <h1>
                        <FaRegCalendarTimes size={20} fill='#292828'/> Data encerramento
                    </h1>
                    <p>
                        {leva.data_encerramento.split(' ')[0]}
                    </p>
                </div>
            ) : (
                <div className='item'>
                    <h1>
                        {leva && <ReactSVG src={HomeDiasRestantesSvg} />} Dias restantes
                    </h1>
                    <p>{diasRestantes}</p>
                </div>
            )}
            {user.permissao === 'mari' ? (
                <ButtonsContainer>
                    <DownloadPedidosButton
                        buscaPedido={buscarPedidos}
                        arquivos={aprovados.map((pedido) => {
                            return {
                                fileName: `${pedido.titulo}_${pedido.template}_${pedido.id}.pdf`,
                                url:pedido.link,
                                pedido: pedido,
                                leva_id: leva.id
                            }
                        })} 
                    />
                    <button onClick={() => setLeva(leva)}>
                        {'ver todos os pedidos >'}
                    </button>
                </ButtonsContainer>
            ) : (
                <button 
                    className='ver-pedidos'
                    onClick={() => setLeva(leva)}
                >
                    {'Ver pedidos >'}
                </button>
            )}
        </PedidoLevaContainer>
    );
};

export default PedidoLeva;