import styled from 'styled-components';

export const ModalImportarPedidoProdutosContainer = styled.div`

`;

export const ContentContainer = styled.div`
    padding: 3rem;

    > h1 {
        font-weight: 600;
        color: #292828;
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
    }
`;

export const SelectContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;
    width: 300px;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    > span {
        top: 55px;
    }

    .produto-select-container {
        height: 44px;
        .produto-select__control {
            height: 100%;
        }

        .produto-select__multi-value {
            .produto-select__multi-value__label {
                min-width: 50px;
            }
            
            .produto-select__multi-value__remove {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .produto-select__clear-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            } 
        }

        .produto-select__dropdown-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            }
        }

        .produto-select__option {
            color: #292828;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-top: 1.5rem;

    > .importar {
        width: 159px;
        height: 34px;
        background: #FDBC11;
        border: none;
        border-radius: 20px;
        color: #292828;
        font-weight: 600;
    }

    > .voltar {
        font-size: 0.9em;
        color: #808384;
        border: none;
        background: none;
        text-decoration: underline;
    }
`;