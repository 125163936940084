import AdminOperacaoEditar from '../../components/AdminOperacaoEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminOperacaoEditarPageContainer } from './styles';

const AdminOperacaoEditarPage = () => {

    return (
        <AdminOperacaoEditarPageContainer>
            <Header />
            <AdminOperacaoEditar />
            <Footer /> 
        </AdminOperacaoEditarPageContainer>
    );
};

export default AdminOperacaoEditarPage;