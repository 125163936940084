import CustomModal from '../CustomModal';
import { ArquivoContainer, ButtonsContainer, ContentContainer, ExemploContainer, ModalImportarUsuariosContainer } from './styles';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { FcDownload } from 'react-icons/fc';
import api from '../../../services/api';
import Loading from '../../Loading';
import usuariosSchema from '../../../schemas/Usuarios/usuariosSchema';
import { toast } from 'react-toastify';

const ModalImportarUsuarios = ({ setModalOpened }) => {
    const [arquivo, setArquivo] = useState('Selecionar arquivo...');
    const [dados, setDados] = useState('');
    const inputFileRef = useRef(null);

    const [geos, setGeos] = useState([]);

    const [loading, setLoading] = useState(true);
    const [geosLoaded, setGeosLoaded] = useState(false);
    
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (geosLoaded) {
            setLoading(false);
        }
    },[geosLoaded]);

    const buscarGeos = () => {
        api.get('/geos')
            .then((response) => {
                setGeos(response.data.data.geos);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };
    
    useEffect(() => {
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);
    
    const arquivoExemplo = () => {
        const dadosExemplo = [
            {
                nome: 'nome do usuário',
                usuario_id: '123456',
                email: 'codigo@ambev.com.br',
                codigo: '12345678',
                senha: '5678',
                permissao_id: 1
            }
        ];
        if (user.permissao === 'ac') dadosExemplo[0].geo_id = 1;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosExemplo);
        XLSX.utils.book_append_sheet(wb, ws, 'criar_usuarios');
        XLSX.writeFile(wb, 'criar_usuarios.xlsx');
    };

    const criarRelatorioPermissao = () => {
        const dadosPermissao = [
            { id: 1, nome: 'administrador' },
            { id: 2, nome: 'regional' },
            { id: 3, nome: 'gerente comercial' },
            { id: 4, nome: 'gerente de vendas' },
            { id: 5, nome: 'representante nacional' },
        ];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosPermissao)
        XLSX.utils.book_append_sheet(wb,ws,'permissoes');
        XLSX.writeFile(wb, 'permissoes.xlsx');
    };

    const criaRelatorioGeo = () => {
        const fields = ['id','nome'];
        const dadosFiltrados = geos.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'geos');
        XLSX.writeFile(wb, 'geos.xlsx');
    };

    const readUploadFile = (e) => {
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                setDados(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        };
    };

    const handleCriarUsuarios = async () => {
        try {
            await usuariosSchema.validate({usuarios: dados});
        } catch (error) {
            if (error.message && error.path) {
                const errorPath = error.path;
                const linha = errorPath.slice(
                    errorPath.indexOf("[") + 1, 
                    errorPath.lastIndexOf("]")
                );
                toast.error(<div>{error.message}<br /> Linha: {Number(linha)+2}</div>)
            };
            setArquivo('Selecionar arquivo...');
            setDados('');
            inputFileRef.current.value = null;
            return;
        };

        api.post('/usuarios/multiplos', { usuarios: dados })
            .then((response) => {
                toast.success('Usuários cadastrados com sucesso!');
                navigate('/administrativo/usuario');
            }).catch(error => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                if (error.response.status === 404) {
                    toast.error('Geo não encontrada!');
                    return;
                };
                if (error.response?.status === 409) {
                    toast.error('Já existe um usuário cadastrado com esse Código');
                    return;
                };
                setArquivo('Selecionar arquivo...');
                setDados('');
                inputFileRef.current.value = null;
                toast.error('Erro ao cadastrar usuário');
            });
    };

    return (
        <ModalImportarUsuariosContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar usuários</h1>
                        <p>
                            <button type='button' onClick={() => arquivoExemplo()}>Baixe aqui</button> o modelo de planilha
                        </p>
                        <ExemploContainer>
                            <div className='item'>
                                Coluna <strong>"permissao_id":</strong>
                                <p>
                                    preencher com <strong>id</strong> da <strong>permissão</strong>.
                                </p>
                                <FcDownload onClick={criarRelatorioPermissao} size={18}/>
                            </div>
                            {user.permissao === 'ac' && (
                                <div className='item'>
                                    Coluna <strong>"geo_id":</strong> 
                                    <p>
                                        preencher com <strong>id</strong> da <strong>geo</strong>.
                                    </p> 
                                    <FcDownload onClick={criaRelatorioGeo} size={18}/>
                                </div>
                            )}
                        </ExemploContainer>
                        <ArquivoContainer>
                            <label>{'Arquivo (.xlsx)'}</label>
                            <label htmlFor='arquivo-xlsx' className='arquivo-input'>
                                <p>{arquivo}</p>
                                <button type='button'>
                                    selecionar
                                </button>
                            </label>
                            <input
                                id="arquivo-xlsx"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputFileRef}
                                onChange={(e) => {
                                    const event = e;
                                    readUploadFile(event);
                                    setArquivo(event.target.files[0].name);
                                    e.target.value = null;
                                }}
                                />
                        </ArquivoContainer>
                        <ButtonsContainer>
                            <button type='button' onClick={async () => await handleCriarUsuarios()} className='importar'>criar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarUsuariosContainer>
    );
};

export default ModalImportarUsuarios;