import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer } from '../ModalRemoverPedidoCategoria/styles';
import { ContentContainer, ModalAprovarPedidoContainer } from './styles';
import Loading from '../../Loading';
import { useState } from 'react';

const ModalAprovarPedido = ({ setModalOpened, pedido, buscarPedido, levaAtual }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const handleAprovarPedido = () => {
        setLoading(true);
        api.patch(`/pedidos/${pedido.id}/status`, {status: 'APROVADO', leva_id: levaAtual.id})
            .then((response) => {
                toast.success('Pedido aprovado com sucesso!');
                buscarPedido();
                setModalOpened(false);
                navigate('/pedidos');
            }).catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível aprovar o pedido. Tente novamente mais tarde.')
                setModalOpened(false);
                setLoading(false);
            });
    };

    return (
        <ModalAprovarPedidoContainer>
             <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <p>Certeza que deseja <span>aprovar</span> esse pedido?</p>
                        <ButtonsContainer>
                            <button onClick={handleAprovarPedido}
                            >
                                SIM
                            </button>
                            <button onClick={() => setModalOpened(false)}>
                                NÃO
                            </button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalAprovarPedidoContainer>
    )
};

export default ModalAprovarPedido;