import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;

    max-height: 80px;
    width: 100%;
    padding: 40px;
    background-color: #292828;
    border-radius: 0 0 20px 20px;
    position: relative;

    > h1 {
        font-size: 1.5em;
        width: 150px;
        line-height: 1em;
        font-weight: 400;

        > span {
            color: #FFC700;
            font-size: 1em;
            font-weight: 700;    
        }
    }
    
    > a {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        border-radius: 5px;
        height: 40px;
        padding: 10px;
        
        text-decoration: none;

        position: relative;

        > div > div > svg {
            height: 16px;
            width: 16px;
            margin-top: 2px;
            path, polygon, rect, circle, ellipse {
                fill: #FFC700;
            }
        }

        > svg {
            z-index: 2;
            height: 18px;
            path, polygon, rect, circle, ellipse {
                fill: #FFC700;
            }
        }

        > p {
            color: #FFC700;
            z-index: 2;
            font-weight: 600;
            font-size: 0.9em;
        }

        ::after {
            content: '';
            position: absolute;
            bottom: -1px;
            width: 100%;
            height: 3px;
            background-color: #FFC700;
            border-radius: 5px;
            transition: height .3s ease, width .3s ease;
            z-index: 1;
        }

        :hover {
            ::after {
                height: 100%;
            }

            > div > div > svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }

            > svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                    stroke: #292828;
                }
            }

            > p {
                color: #292828;
            }
        }
    }

    .hovered {
         ::after {
            height: 100%;
        }

        > div > div > svg {
            path, polygon, rect, circle, ellipse {
                fill: #292828;
            }
        }

        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #292828;
                stroke: #292828;
            }
        }

        > p {
            color: #292828;
        }
    }

    @media screen and (max-width: 768px) {
        > h1 {   
            text-align: center;
            margin: 0 auto;
        }
        
        > a {
            display: none;
        }
    }
`;

export const AdministrativoButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    border-radius: 5px;
    padding: 10px;
    height: 40px;

    border: none;
    border-radius: 5px;
    background-color: transparent;

    position: relative;

    > svg {
        z-index: 2;
        path, polygon, rect, circle, ellipse {
            fill: #FFC700;
        }
    }

    > .arrow-down {
        transition: transform .3s ease;
    }
     
    > p {
        color: #FFC700;
        font-size: 0.9em;
        font-weight: 600;
        z-index: 2;
    }

    ::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 3px;
        background-color: #FFC700;
        border-radius: 5px;
        transition: height .3s ease, width .3s ease;
        z-index: 1;
    }

    :hover {
        ::after {
            height: 100%;
        }

        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #292828;
            }
        }

        > .arrow-down {
            transform: rotate(180deg);
        }

        > p {
            color: #292828;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const UserInfoContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    > span {
        font-weight: 600;
        color: #FFC700;

        ::after {
            content: '|';
            color: #f0f0f0;
            font-weight: 100;
            margin: 0 10px;
            font-size: 1.2em;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AdministrativoNav = styled.nav`
    position: absolute;
    z-index: 3;
    background-color: #fff;
    width: 100%;
    top: 41.5px;
    padding: 5px 12px;
    box-shadow: 0px 3px 6px #0000000D;
    border: 1px solid #D2D2D2;
    border-radius: 13px;
    transition: visibility 0s,opacity 0.3s linear;

    > a,
    > a:visited,
    > a:active,
    > a:link {
        display: flex;
        align-items: center;
        column-gap: 5px;
        height: 35px;
        width: 100%;
        
        color: #292828;
        font-size: 0.9em;
        font-weight: 500;
        text-decoration: none;
        border-bottom: 1px solid #f0f0f0;
        white-space: nowrap;

        > figure {
            width: 18px;

            svg {
                width: 16px;
                height: 16px;
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }
        }
    }

    > :last-child,
    > :last-child:visited,
    > :last-child:active,
    > :last-child:link {
        border-bottom: none;
    }
`;

export const LogoutButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 10px;

    background-color: transparent;
    border: none;
    border-radius: 100%;

    :hover {
        background-color: #fff;

        svg {
            fill: #292828;
            > path, polygon, rect, circle, ellipse {
                fill: #292828;
            };
        }
    }

    svg {
        width: 50%;
        height: 50%;
        fill: #fff;
        margin: 4px 0 0 4px;

        > path, polygon, rect, circle, ellipse {
            fill: #fff;
        };          
    };

    @media (max-width: 768px) {
        display: none;
    }
`;

export const MenuButton = styled.button`
    display: none;
    background: none;
    border: none;

    position: absolute;
    right: 20px;

    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        padding-bottom: 1px;
    }
`;

export const MenuMobile = styled.div`
    visibility: ${({menuOpen}) => menuOpen ? 'visible' : 'hidden'};
    transition: visibility .3s linear;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    > .menu {
        width: 300px;
        height: 100%;
        background: #292828;
        box-shadow: 0px 3px 6px #0000000D;
        border-radius: 10px 0 0 10px;
        position: absolute;
        right: ${({menuOpen}) => menuOpen ? '0' : '-260px'};
        transition: right .3s ease;

        > .userinfo {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;

            > span {
                font-weight: 600;
                color: #FFC700;

                ::after {
                    content: '|';
                    color: #fff;
                    font-weight: 100;
                    margin: 0 10px;
                    font-size: 1.2em;
                }
            }

            > p {
                font-weight: 50;
            }
        }

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 8px;
            border-radius: 5px;
            height: 45px;
            padding: 10px;
            
            text-decoration: none;
            position: relative;
            border-bottom: 1px solid #FFC700;

            > div > div > svg {
                height: 16px;
                width: 16px;
                margin-top: 2px;
                path, polygon, rect, circle, ellipse {
                    fill: #FFC700;
                }
            }

            > svg {
                z-index: 2;
            }

            > p {
                color: #FFC700;
                font-weight: 600;
            }
        }

        .hovered {
            background: #FFC700;
            > div > div > svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }

            > p {
                color: #292828;
            }
        }
        
        > .sair {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 10px;
            width: 100%;
            padding: 0 20px;

            background: none;
            border: none;
            border-radius: 100%;

            position: absolute;
            bottom: 20px;

            svg {
                width: 20px;
                height: 20px;
                fill: #fff;
                margin: 4px 0 0 4px;

                > path, polygon, rect, circle, ellipse {
                    fill: #fff;
                }       
            }
        }
    }
`;

export const CloseMenuButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #fff;
    border: none;
    border-radius: 100%;
    border: 1px solid #D2D2D2;
    padding-bottom: 1px;
    position: absolute;
    top: 25px;
    right: 285px;
`;

export const MenuAdministrativoMobile = styled.div`
    position: relative;

    > .administrativo-button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        height: 45px;

        border: none;
        border-bottom: 1px solid #FFC700;
        border-radius: ${({administrativoMobileOpen}) => administrativoMobileOpen ? '0' : '5px'};
        background-color: transparent;
        
        > p {
            color: #FFC700;
            font-weight: 600;
        }

        svg {
            path, polygon, rect, circle, ellipse {
                fill: #FFC700;
            }
        }

        > .arrow-down {
            transform: ${({administrativoMobileOpen}) => administrativoMobileOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
            transition: transform .3s ease;
        }
    }

    > nav {
        width: 100%;
        background: #FFC700;
        border-radius: 0 0 10px 10px;
        visibility: ${({administrativoMobileOpen}) => administrativoMobileOpen ? 'visible' : 'hidden'};
        transform-origin: top;
        transform: ${({administrativoMobileOpen}) => administrativoMobileOpen ? 'scaleY(1)' : 'scaleY(0)'};
        transition: visibility .3s linear, transform .3s ease;

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            text-decoration: none;
            border-bottom: 1px solid #292828;
            color: #292828;
            font-weight: 500;
            height: 40px;

            svg {
                width: 15px;
                height: 15px;
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }
        }

        > a:last-child {
            border-bottom: none;
        }
    }
`;