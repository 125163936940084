import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PedidosEditar from '../../components/PedidosEditar';
import { PedidosEditarPageContainer } from './styles';

const PedidosEditarPage = () => {

    return (
        <PedidosEditarPageContainer>
            <Header />
            <PedidosEditar />
            <Footer />
        </PedidosEditarPageContainer>
    );
};

export default PedidosEditarPage;