import AdminGeoEditar from '../../components/AdminGeoEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminGeoEditarPageContainer } from './styles';

const AdminGeoEditarPage = () => {

    return (
        <AdminGeoEditarPageContainer>
            <Header />
            <AdminGeoEditar />
            <Footer />
        </AdminGeoEditarPageContainer>
    );
};

export default AdminGeoEditarPage;