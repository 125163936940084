import { ReactSVG } from 'react-svg';
import { Tr } from './styles';
import EditarSvg from '../../../assets/editar.svg';
import { Link } from 'react-router-dom';

const UsuarioTr = ({ usuario }) => {

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{usuario.id}
            </td>
            <td>
                <label>Nome</label>
                {usuario.nome}
            </td>
            <td>
                <label>Permissão</label>
                {usuario.permissao}
            </td>
            <td>
                <label>Geo</label>
                {usuario.geo}
            </td>
            <td>
                <label>Código</label>
                {usuario.codigo}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/usuario/editar/${usuario.id}`}>
                    editar <ReactSVG src={EditarSvg} />
                </Link>
            </td>
        </Tr>
    )
};

export default UsuarioTr;