import { ExcluirButton, Tr } from './styles';
import { ReactSVG } from 'react-svg';
import ExcluirSvg from '../../../assets/excluir.svg';
import { useState } from 'react';
import ModalExcluirVinculoVolumeCategoria from '../../Modals/ModalExcluirVinculoVolumeCategoria';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';

const VinculoVolumeCategoriaTr = ({ vinculo, buscarVinculos }) => {
    const { logout, user } = useAuth();
    const navigate = useNavigate();
    
    const [modalOpened, setModalOpened] = useState(false);

    const handleExcluirVinculoVolumeCategoria = () => {
        api.delete(`/bebida-volume-categorias/${vinculo.id}`)
            .then(() => {
                buscarVinculos();
                toast.success('Vínculo excluído com sucesso!');
                setModalOpened(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                toast.error('Erro ao excluir vínculo!');
            });
    };

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{vinculo.id}
            </td>
            <td>
                <label>Bebida</label>
                {vinculo.bebida}
            </td>
            <td>
                <label>Volume</label>
                {vinculo.volume}
            </td>
            <td>
                <label>Categoria</label>
                {vinculo.categoria}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {vinculo.geo}
                    </>
                )}
            </td>
            <td>
                <label>Excluir</label>
                <ExcluirButton onClick={() => setModalOpened(true)}>
                    <ReactSVG src={ExcluirSvg}/>
                </ExcluirButton>
                {modalOpened && (
                    <ModalExcluirVinculoVolumeCategoria 
                        setModalOpened={setModalOpened} 
                        handleExcluirVinculoVolumeCategoria={handleExcluirVinculoVolumeCategoria}
                    />
                )}
            </td>
        </Tr>
    );
};

export default VinculoVolumeCategoriaTr;