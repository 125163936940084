import AdminGeoNovo from '../../components/AdminGeoNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminGeoNovoPageContainer } from './styles';

const AdminGeoNovoPage = () => {

    return (
        <AdminGeoNovoPageContainer>
            <Header />
            <AdminGeoNovo />
            <Footer />
        </AdminGeoNovoPageContainer>
    );
};

export default AdminGeoNovoPage;