import styled from 'styled-components';

export const ModalEditarDataLevaContainer = styled.div`

`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    min-width: 350px;
    width: 40vw;
    max-width: 300px;

    > p {
        color: #292828;
        font-size: 1.2em;
        margin-bottom: 10px;
        font-weight: 500;
    }

    > input {
        background-color: #F0F0F0;
        border-radius: 6px;
        width: 180px;
        padding-left: 10px;
        height: 44px;
        border: 1px solid black;
        color: #292828;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 5px;
    margin-top: 1.5rem;

    > :first-child {
        width: 125px;
        height: 40px;
        background: #FDBC11;
        border-radius: 20px;
        border: none;
        font-weight: 700;
         
        &:disabled {
            background-color: #a0a0a0;
            cursor: not-allowed;
        }
    }

    > :last-child {
        border: none;
        background: none;
        text-decoration: underline;
        color: #292828;
    }
`;