import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import { useAuth } from '../../../providers/Auth';

const CategoriaTr = ({ categoria }) => {
    const { user } = useAuth();

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{categoria.id}
            </td>
            <td>
                <label>Nome</label>
                {categoria.nome}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {categoria.geo}
                    </>
                )}
            </td>
            <td>
                <label>Criação</label>
                {categoria.data_de_criacao}
            </td>
            <td>
                <label>Atualização</label>
                {categoria.data_de_atualizacao}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/categoria/editar/${categoria.id}`}>
                    editar <ReactSVG src={EditarSvg} />
                </Link>
            </td>
        </Tr>
    )
};

export default CategoriaTr;