import { AdminVinculoVolumeCategoriaNovoContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroVinculoSvg from '../../assets/cadastro-vinculo.svg';
import VinculoVolumeCategoriaForm from '../Forms/VinculoVolumeCategoriaForm';

const AdminVinculoVolumeCategoriaNovo = () => {

    return (
        <AdminVinculoVolumeCategoriaNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroVinculoSvg}/> <h1>Cadastro de Vínculos</h1> 
            </div> 
            <ContentContainer>
                <VinculoVolumeCategoriaForm />
            </ContentContainer>
        </AdminVinculoVolumeCategoriaNovoContainer>
    );
};

export default AdminVinculoVolumeCategoriaNovo;