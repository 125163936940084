import styled from 'styled-components';

export const ModalGerarQrcodeContainer = styled.div``;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    padding: 3rem;
    min-width: 350px;
    width: 30vw;
    max-width: 500px;
`;


export const SelectContainer = styled.div`
   display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    > span {
        top: 55px;
    }

    .react-select-container {
        height: 44px;
        .react-select__control {
            height: 100%;
        }

        .react-select__multi-value {
            .react-select__multi-value__label {
                min-width: 50px;
            }
            
            .react-select__multi-value__remove {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .react-select__clear-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            } 
        }

        .react-select__dropdown-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            }
        }

        .react-select__option {
            color: #292828;
        }
    }
`;

export const GerarQrcodeButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 150px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #FDBC11;
    text-align: center;
    color: #292828;
    font-weight: 700;
    margin: 0 auto;

    &:disabled {
        background-color: rgba(41, 40, 40, 0.1);
        color: rgba(41, 40, 40, 0.2);
        cursor: not-allowed;
    }
`;