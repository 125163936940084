import { Tr, VisualizarEditarContainer } from './styles';
import { ReactComponent as ConcluidoSvg } from '../../../assets/concluido.svg';
import { ReactComponent as BaixadoSvg } from '../../../assets/baixado.svg';
import { ReactComponent as AguardandoSvg } from '../../../assets/aguardando.svg';
import { ReactComponent as AprovadoSvg } from '../../../assets/aprovado.svg';
import { ReactComponent as ReprovadoSvg } from '../../../assets/reprovado.svg';
import { ReactComponent as RevisarSvg } from '../../../assets/revisar.svg';
import { ReactComponent as EditarSvg } from '../../../assets/editar.svg';
import { ReactComponent as VisualizarSvg } from '../../../assets/visualizar.svg';
import QrCodeSvg from '../../../assets/qr-code.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../providers/Auth';
import DownloadPedidoButton from '../../DownloadPedidoButton';
import { ReactSVG } from 'react-svg';

const PedidoTr = ({ pedido, buscarPedidos }) => {
    const { user } = useAuth();
    const navigate = useNavigate();

    return (
        <Tr pedido={pedido}>
            <td>
                <label>Id</label>
                #{pedido.id}
            </td>
            <td>
                {user.permissao !== 'rn' && (
                    <>
                        <label>Operação</label>
                        {pedido.nome_operacao}
                    </>
                )}
            </td>
            <td>
                {user.permissao !== 'rn' && (
                    <>
                        <label>Solicitante</label>
                        {pedido.criado_por}
                    </>
                )}
            </td>
            <td>
                <label>PDV</label>
                {pedido.ponto_de_venda}
                </td>
            <td>
                <label>Template</label>
                {pedido.template}
            </td>
            <td>
                <label>Quant.</label>
                {pedido.quantidade}
            </td>
            <td>
                <label>Quant. qrcode</label>
                {pedido.quantidade_qrcode}
            </td>
            <td>
                <label>Cardápio</label>
                <div className='cardapio'>
                    {`${pedido.impresso ? 'Impresso' : ''} ${pedido.impresso && pedido.qrcode ? 'e' : ''} ${pedido.qrcode ? 'QR Code' : ''}`}
                    {!pedido.impresso && !pedido.qrcode && 'Digital'}
                </div>
            </td>
            <td>
                <label>Qr Code</label>
                <div className='qrcode'>
                    {(Number(pedido.qrcode) && ['CONCLUIDO', 'BAIXADO', 'APROVADO'].includes(pedido.status)) ? (
                        <a 
                            href={`https://gestorcadapios.blob.core.windows.net/qrcode/${pedido.link_pdv.split('/').pop()}.pdf`}
                            download={`QR Code - ${pedido.titulo}.pdf`}
                        >
                            <ReactSVG src={QrCodeSvg} />
                        </a>
                    ) : (
                        <ReactSVG src={QrCodeSvg} />
                    )}
                </div>
            </td>
            <td>
                <label>Status</label>
                {pedido.status === 'AGUARDANDO' && (
                    <div className='aguardando'>
                        <AguardandoSvg /> aguardando
                    </div>
                )}
                {pedido.status === 'APROVADO' && (
                    <div className='aprovado'>
                        <AprovadoSvg /> aprovado
                    </div>
                )}
                {pedido.status === 'REPROVADO' && (
                    <div className='reprovado'>
                        <ReprovadoSvg /> reprovado
                    </div>
                )}
                {pedido.status === 'REVISAR' && (
                    <div className='revisar'>
                        <RevisarSvg /> revisar
                    </div>
                )}
                {pedido.status === 'CANCELADO' && (
                    <div className='cancelado'>
                        <ReprovadoSvg /> cancelado
                    </div>
                )}
                {pedido.status === 'BAIXADO' && (
                    <div className='baixado'>
                        <BaixadoSvg /> baixado
                    </div>
                )}
                {pedido.status === 'CONCLUIDO' && (
                    <div className='concluido'>
                        <ConcluidoSvg /> concluído
                    </div>
                )}
            </td>
            <td>
                <label>Atualização</label>
                {pedido.data_de_atualizacao}
            </td>
            <td>
                <label>Visualizar/editar</label>
                <VisualizarEditarContainer>
                    <button 
                        className='visualizar'
                        onClick={() => navigate(`/pedidos/${pedido.id}`)}
                    >
                        <VisualizarSvg />
                    </button>
                    {user.permissao === 'mari' ? (
                        <DownloadPedidoButton
                            pedido={pedido}
                            buscarPedidos={buscarPedidos}
                        />
                    ) : (
                        <button
                            className='editar'
                            onClick={() => navigate(`/pedidos/editar/${pedido.id}`)}
                            disabled={pedido.status !== 'REVISAR' || (pedido.usuario_id !== user.id && user.permissao !== 'gv')}
                        >
                            <EditarSvg />
                        </button>
                    )}
                </VisualizarEditarContainer>
            </td>
        </Tr>
    );
};

export default PedidoTr;