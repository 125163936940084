import { useState } from 'react';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalEditarDataLevaContainer } from './styles';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const ModalEditarDataLeva = ({ setModalOpened, buscarLevas, leva }) => {
    const [data, setData] = useState('');
    
    const handleEditarLevaData = () => {
        const [ano, mes, dia] = data.split('-');
        const dataFormatada = `${dia}/${mes}/${ano}`;
        const dados = {
            data_final: dataFormatada
        };
        api.patch(`/levas/${leva.id}`, dados)
            .then((response) => {
                buscarLevas();
                toast.success('Data atualizada com sucesso!');
                setModalOpened(false);
            }).catch((error) => {
                toast.error('Erro ao editar leva!')
            })
    };

    return (
        <ModalEditarDataLevaContainer>
              <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Selecione a nova data de corte</p>
                    <input
                        type='date'
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                    />
                    <ButtonsContainer>
                        <button 
                            disabled={!data}
                            onClick={handleEditarLevaData}
                        >
                            Salvar
                        </button>
                        <button onClick={() => setModalOpened(false)}>{'< Voltar'}</button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalEditarDataLevaContainer>
    );
};

export default ModalEditarDataLeva;