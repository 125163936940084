import * as yup from 'yup';
import categoriaSchema from './categoriaSchema';

const categoriasSchema = yup.object().shape({
    categorias: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(categoriaSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default categoriasSchema;