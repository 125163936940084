import styled from 'styled-components';

export const PedidosEditarContainer = styled.div`
    margin: 1rem 2rem;

    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        margin: 1rem 0;
        
        svg {
            path, polygon, rect, circle, ellipse {
                fill: #f9f9f9;
                stroke: #f9f9f9;
            }
        }

        > h1 {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 840px) {
        margin: 1rem;
    }
`;

export const ContentContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
`;

export const ObservacoesContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    margin-bottom: 2rem;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #FDBC11;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    > .observacoes {
        display: flex;
        column-gap: 1rem;
        overflow: auto;
        padding: 5px 0;
        ::-webkit-scrollbar {
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        ::-webkit-scrollbar-thumb {
            background: #FDBC11;
            border-radius: 10px;
        }
        > .item {
            display: flex;
            flex-direction: column;
            column-gap: 0.5rem;
            row-gap: 4px;
            padding: 1rem;
            border: 1px solid #E9E9E9;
            border-radius: 15px;
            width: 200px;
            min-width: 200px;
            box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.3);

            > .overflow {
                overflow: auto;
                width: 100%;
                height: 60px;
                ::-webkit-scrollbar {
                    width: 5px;
                }

                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                ::-webkit-scrollbar-thumb {
                    background: #FDBC11;
                    border-radius: 10px;
                }

                > p {
                    color: #292828;
                    width: 99%;
                    word-wrap: break-word;
                }
            }

            > span {
                color: #bdbdbd;
                font-size: 0.8em;
                text-align: right;
            }
        }
    }
`;