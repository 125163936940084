import styled from 'styled-components';

export const PagerContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 5px;
    padding: 30px 0;

    > .page-button {
        background: none;
        border: none;
        text-decoration: underline;
        color: #292828;
    }

    > .page-number-button {
        background: none;
        border: none;
        width: 10px;
        height: 17px;
        margin: 0 5px;
        color: #292828;
    }
    
    > .paginador-input-container {
        display: flex;
        width: min-content;
        text-align: center;
        margin-right: 7px;
        color: #292828;

    }

    > .paginador-input-container > input {
        width: 30px;
        text-align: center;
        background: none;
        border:none;
        text-decoration: underline;
        color: #292828;
    }

    > .paginador-input-container > p {
        width: min-content;
        color: #292828;
    }
`;