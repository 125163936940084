import { useEffect, useRef, useState } from 'react';
import { FcDownload } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import bebidasSchema from '../../../schemas/Bebidas/bebidasSchema';
import api from '../../../services/api';
import Loading from '../../Loading';
import CustomModal from '../CustomModal';
import { ArquivoContainer, ButtonsContainer, ContentContainer, ExemploContainer, ModalImportarBebidasContainer } from './styles';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../providers/Auth';

const ModalImportarBebidas = ({setModalOpened}) => {
    const [arquivo, setArquivo] = useState('Selecionar arquivo...');
    const [dados, setDados] = useState('');
    const inputFileRef = useRef(null);

    const [geos, setGeos] = useState([]);
    
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [geosLoaded, setGeosLoaded] = useState(false);

    useEffect(() => {
        if (geosLoaded) {
            setLoading(false);
        }
    },[geosLoaded]);

    useEffect(() => {
        const buscarGeos = () => {
            api.get('/geos')
                .then((response) => {
                    setGeos(response.data.data.geos);
                    setGeosLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
    },[user]);

    const criaRelatorioGeo = () => {
        const fields = ['id','nome'];
        const dadosFiltrados = geos.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'geos');
        XLSX.writeFile(wb, 'geos.xlsx');
    };

    const arquivoExemplo = () => {
        const dadosExemplo = [
            {
                nome: 'nome da bebida',
                segmento: 'Água, cerveja, premium, etc.',
                pack: 'pack',
                marca_consolid: 'marca consolidada',
                marca: 'marca',
            }
        ];
        if (user.permissao === 'ac') dadosExemplo[0].geo_id = 1;

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosExemplo);
        XLSX.utils.book_append_sheet(wb, ws, 'criar_bebidas');
        XLSX.writeFile(wb, 'criar_bebidas.xlsx');
    };

    const readUploadFile = (e) => {
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);

                setDados(json);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        };
    };

    const atualizar = async () => {
        try {
            await bebidasSchema.validate({bebidas: dados});
        } catch (error) {
            if (error.message && error.path) {
                const errorPath = error.path;
                const linha = errorPath.slice(
                    errorPath.indexOf("[") + 1, 
                    errorPath.lastIndexOf("]")
                );
                toast.error(<div>{error.message}<br /> {!Number.isNaN(Number(linha)) && `Linha: ${Number(linha)+2}`}</div>)
            };
            setArquivo('Selecionar arquivo...');
            setDados('');
            inputFileRef.current.value = null;
            return;
        };

        api.post('/bebidas', {bebidas: dados})
            .then(response => {
                toast.success('Bebidas cadastradas com sucesso!');
                navigate('/administrativo/bebida');
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                }
                if (error.response.status === 404) {
                    toast.error('Geo não encontrada!');
                    return;
                };
                setArquivo('Selecionar arquivo...');
                setDados('');
                inputFileRef.current.value = null;
                toast.error('Erro ao cadastrar bebida!');
            });
    };

    return (
        <ModalImportarBebidasContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar bebidas</h1>
                        <p>
                            <button type='button' onClick={() => arquivoExemplo()}>
                                Baixe aqui
                            </button> o modelo de planilha
                        </p>
                        {user.permissao === 'ac' && (
                            <ExemploContainer>
                                    <div className='item'>
                                        Coluna <strong>"geo_id":</strong> 
                                        <p>
                                            preencher com <strong>id</strong> da <strong>geo</strong>.
                                        </p> 
                                        <FcDownload onClick={criaRelatorioGeo} size={18}/>
                                    </div>
                            </ExemploContainer>
                        )}
                        <ArquivoContainer>
                            <label>{'Arquivo (.xlsx)'}</label>
                            <label htmlFor='arquivo-xlsx' className='arquivo-input'>
                                <p>{arquivo}</p>
                                <button type='button'>
                                    selecionar
                                </button>
                            </label>
                            <input
                                id="arquivo-xlsx"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputFileRef}
                                onChange={(e) => {
                                    const event = e;
                                    readUploadFile(event);
                                    setArquivo(event.target.files[0].name);
                                    e.target.value = null;
                                }}
                                />
                        </ArquivoContainer>
                        <ButtonsContainer>
                            <button type='button' onClick={async () => await atualizar()} className='importar'>criar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarBebidasContainer>
    );
};

export default ModalImportarBebidas;