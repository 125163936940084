import { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import Loading from '../../Loading';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalConcluirPedidosContainer } from './styles';

const ModalConcluirPedidos = ({ setModalOpened, pedidosBaixados, buscarPedidos }) => {
    const [loading, setLoading] = useState(false);

    const handleConcluirPedidos = () => {
        setLoading(true);
        const dados = {
            pedidosIds: pedidosBaixados.map(pedido => pedido.id),
        };
        api.post('/concluir-pedidos', dados)
            .then(() => {
                buscarPedidos();
                setModalOpened(false);
            }).catch((err) => {
                setLoading(false);
                toast.error('Erro ao concluir pedidos');
            }); 
    };

    return (
        <ModalConcluirPedidosContainer>
             <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <p>Certeza que deseja <span>concluír</span> os pedidos baixados?</p>
                        <span>todos pedidos baixados terão seu status alterado para concluído e o pdf será deletado.</span>
                        <ButtonsContainer>
                            <button 
                                onClick={handleConcluirPedidos}
                            >
                                SIM
                            </button>
                            <button onClick={() => setModalOpened(false)}>
                                NÃO
                            </button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalConcluirPedidosContainer>
    )
};

export default ModalConcluirPedidos;