import * as yup from 'yup';

const bebidaSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório (nome).'),
    segmento: yup.string().required('Campo obrigatório (segmento).'),
    pack: yup.string().required('Campo obrigatório (pack).'),
    marca_consolid: yup.string().required('Campo obrigatório (marca consolidada).'),
    marca: yup.string().required('Campo obrigatório (marca).'),
});

export default bebidaSchema;