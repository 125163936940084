import styled from 'styled-components';

export const PedidosVisualizarContainer = styled.div`
    margin: 1rem 2rem;

    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        margin: 1rem 0;
        
        svg {
            path, polygon, rect, circle, ellipse {
                fill: #f9f9f9;
                stroke: #f9f9f9;
            }
        }

        > h1 {
            font-weight: 600;
        }
            > .voltar {
            border: none;
            border-radius: 100%;
            background: #FDBC11;
            width: 25px;
            height: 25px;
            
            color: #292828;
            font-weight: 600;
            font-size: 1.4rem;
        }
        
    }

    @media screen and (max-width: 840px) {
        margin: 1rem;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export const InformacoesContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 4px;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }
    }

    > .informacoes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 10px;
        > .item {
            width: 10%;
            > h3 {
                color: #292828;
                font-weight: 600;
            }

            > p {
                color: #292828;
                margin: 5px 0;
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .informacoes {
            flex-direction: column;
            
            > .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #E9E9E9;
                padding: 5px 0;
            }

            > .editar {
                margin-top: 10px;
                margin-left: auto;
            }
        }
    }
`;

export const PdfContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;
        width: 100%;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }
    }
`;

export const StatusContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    > button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        column-gap: 5px;
        width: 144px;
        height: 38px;
        border: none;
        font-weight: 600;
    }
    
    > .reprovar {
        background-color: #ED1818;     
        
        svg {
            margin-top: 4px;
            width: 24px;
            height: 24px;
        }
    }

    > .ajustar {
        background-color: #F7971C;
    }

    > .aprovar {
        background-color: #40AD48;
        
        svg {
            margin-top: 4px;
            width: 24px;
            height: 24px;
        }
    }

    > .aguardando {
        background: #F16521;
        width: 250px;

        svg {
            margin-top: 4px;
            width: 20px;
            height: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        > .aguardando {
            background: #F16521;
            width: 200px;
            height: 35px;
            font-size: 0.8rem;

            svg {
                margin-top: 4px;
                width: 16px;
                height: 16px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        > .reprovar,
        > .ajustar,
        > .aprovar {
            font-size: 0.8em;
            width: 120px;
            height: 30px;

            svg {
                height: 16px;
                width: 16px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        > .reprovar,
        > .ajustar,
        > .aprovar {
            font-size: 0.7em;
            width: 100px;
            height: 30px;

            svg {
                height: 14px;
                width: 14px;
            }
        }
    }
`;

export const ItensContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;
        width: 100%;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }
    }

    > .bebidas,
    > .produtos {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0.5rem;
        row-gap: 10px;
        justify-content: flex-start;

        > h3 {
            color: #292828;
            font-weight: 600;
            font-size: 1.2rem;
        }
        > div {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            > h3 {
                color: #292828;
            }
            > .item {
                border: 1px solid #E9E9E9;
                border-radius: 15px;
                padding: 1.5rem;
                min-width: 20%;

                > h3 {
                    color: #292828;
                    font-weight: 600;
                    font-size: 1.1rem;
                    margin-bottom: 10px;
                }
    
                > p {
                    color: #292828;
                    margin-bottom: 2px;
                }
            }
        }
    }

    @media screen and (max-width: 840px) {
        padding: 1rem;
    }

    @media screen and (max-width: 600px) {
        > .bebidas,
        > .produtos {
            > div {
                justify-content: center;

                > .item {
                    min-width: 100%;
                }
            }
        }
    }
`;  