import styled from 'styled-components';

export const ModalImportarBebidasContainer = styled.div`

`;

export const ContentContainer = styled.div`
    padding: 2rem;

    > h1 {
        font-weight: 600;
        color: #292828;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    > p {
        margin-top: 20px;
        color: #292828;
        
        > button {
            color: #292828;
            font-weight: 600;
            text-decoration: underline;
            border: none;
            background-color: none;
        }
    }
`;

export const ArquivoContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0 0 20px 0;

    > label {
        color: #292828;
        font-weight: 600;
    }

    > .arquivo-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        background: #fff;
        height: 44px;
        width: 100%;
        cursor: pointer;
        
        > p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 15px;
            color: #292828;
        }

        > button {
            border: 1px solid #a3a3a3;
            border-radius: 0 6px 6px 0;
            height: 100%;
            padding: 0 15px;
            color: #292828;
            font-weight: 500;
        }
    } 

    > input {
        display: none;
    }
`;

export const ExemploContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 10px 0;
    font-weight: 500;
    border: 1px solid #a3a3a3;
    border-radius: 6px;
    padding: 10px 15px;
    background-color: #fff;

    > .item {
        display: flex;
        align-items: center;
        position: relative;
        color: #292828;

        > p {
            color: #292828;

            > strong {
                color: #292828;
                font-weight: 600;
            }
        }

        > strong {
            font-weight: 600;
            color: #292828;
            margin: 0 5px;
        }

        > svg {
            cursor: pointer;
            margin-left: 4px;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    > .importar {
        width: 159px;
        height: 34px;
        background: #FDBC11;
        border: none;
        border-radius: 20px;
        color: #292828;
        font-weight: 600;
    }

    > .voltar {
        font-size: 0.9em;
        color: #808384;
        border: none;
        background: none;
        text-decoration: underline;
    }
`;