import { AdminOperacaoEditarContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroOperacaoSvg from '../../assets/cadastro-operacao.svg';
import OperacaoForm from '../Forms/OperacaoForm';

const AdminOperacaoEditar = () => {

    return (
        <AdminOperacaoEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroOperacaoSvg}/> <h1>Editar Operação</h1> 
            </div>
            <ContentContainer>
                <OperacaoForm />
            </ContentContainer>
        </AdminOperacaoEditarContainer>
    );
};

export default AdminOperacaoEditar;