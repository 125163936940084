import { AdminCategoriaNovoContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroCategoriaSvg from '../../assets/cadastro-categoria.svg';
import CategoriaForm from '../Forms/CategoriaForm';

const AdminCategoriaNovo = () => {

    return (
        <AdminCategoriaNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroCategoriaSvg}/> <h1>Cadastro de Nova Categoria</h1> 
            </div>
            <ContentContainer>
                <CategoriaForm />
            </ContentContainer>
        </AdminCategoriaNovoContainer>
    );
};

export default AdminCategoriaNovo;