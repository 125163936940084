import styled from 'styled-components';

export const Tr = styled.tr`
    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        > label {
            display: none;
        }

        > .ordenar {
            > button {
                display: flex;
                align-items: center;
                justify-content: center;

                color: #292828;
                background: none;
                border-radius: 100%;
                width: 20px;
                height: 20px;

                :disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }
        }

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            color: #292828;

            > button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 26px;
                height: 26px;

                background: none;
                border: none;
                border-radius: 50%;

                svg {
                    height: 20px;
                    margin-top: 3px;
                }
            }

            > .editar {
                background: #292828;
    
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #FDBC11;
                    }
                }
            }
    
            > .excluir {
                background: #ED1818;
                
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #fff;
                    }
                }
            }

            > .cancelar {
                background: #ED1818;

                svg {
                    margin-bottom: 3px;

                    path, polygon, rect, circle, ellipse {
                        stroke: #fff;
                    }
                }
            }

            > .salvar {
                background: #40AD48;
                svg {
                    margin-bottom: 3px;

                    polyline {
                        stroke: #fff;
                    }
                }
            }
        }

        > .preco {
            justify-content: flex-start;
            > div {
                display: flex;
                flex-direction: column;
                > input {
                    padding: 2px 0 2px 3px;
                    color: #292828;
                    min-width: 50px;
                }
            }
        }
    }

    > :nth-child(1) {
        width: 60px;
    }

    > :nth-child(3),
    > :nth-child(4) {
        width: 20%;
    }

    > :nth-child(5) {
        width: 5%;
    }

    @media screen and (max-width: 840px) {
        display: flex;
        flex-direction: column;
        border: 1px solid #E9E9E9;
        border-radius: 15px;
        margin: 1rem 0;
        padding: 1rem;
        
        > td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 0.9em;

            > label {
                display: flex;
                color: #292828;
                font-weight: 600;
                font-size: 1em;
            }
        }

        > :nth-child(3),
        > :nth-child(4),
        > :nth-child(5) {
            width: 100%;
        }

        > td:last-child {
            border-bottom: none;
        }
    }
`;