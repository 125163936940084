import AdminVinculoVolumeCategoria from '../../components/AdminVinculoVolumeCategoria';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminVinculoVolumeCategoriaPageContainer } from './styles';

const AdminVinculoVolumeCategoriaPage = () => {

    return (
        <AdminVinculoVolumeCategoriaPageContainer>
            <Header />
            <AdminVinculoVolumeCategoria />
            <Footer />
        </AdminVinculoVolumeCategoriaPageContainer>
    );
};

export default AdminVinculoVolumeCategoriaPage;