import styled from 'styled-components';

export const Button = styled.button`
    border: none;
    border-radius: 100%;
    width: 26px;
    height: 26px;
    background: #292828;

    svg {
        height: 12px;
        path {
            fill: #FDBC11;
        }
    }

    &:disabled {
        background: #a0a0a0;
        cursor: not-allowed;
        svg {
            path {
                fill: #fff;
            }
        }
    }
`;