import styled from 'styled-components';

export const EscadaDegrauContainer = styled.div`
    display: flex;
    background: #F0F0F0;
    border-radius: 6px;
    height: 120px;

    position: relative;

    > .setas-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
    
            color: #292828;
            background: none;
            border: 2px solid #292828;
            border-radius: 6px;
            width: 23px;
            height: 23px;
    
            :disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }

    > .bebidas-container {
        display: flex;
        padding: 0 20px;
        align-items: center;
        column-gap: 25px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        ::-webkit-scrollbar {
            height: 6px;
        }
        
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #FDBC11;
            border-radius: 10px;
        }

        > .adicionar-bebida {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: none;
            background: none;

            > :first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                height: 54px;
                background: #FDBC11;
                color: #000000;
                border-radius: 100%;
                border: none;
                font-weight: 700;
                font-size: 30px;
            }            

            > :last-child {
                color: #000000;
                border: none;
                text-decoration: underline;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
`;

export const NenhumaBebidaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 102px;
    height: 80px;
    background: #fff;
    border: 1px solid #70707030;
    border-radius: 9px;
    color: #D4D4D4;
`;

export const ComparadoresMaiorContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 10px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    width: 100%;
    height: 90px;
    border-radius: 10px;

    position: absolute;
    top: -90px;
    z-index: 1;

    > .comparador {
        color: #FDBC11;
        font-size: 1.5em;
        margin-right: 5px;
    }

    > .escolher-bebida {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            border: none;
            background: none;

            > :first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 34px;
                height: 34px;
                background: #FDBC11;
                color: #000000;
                border-radius: 100%;
                border: none;
                font-weight: 700;
                font-size: 1.2em;
            }            

            > :last-child {
                color: #000000;
                border: none;
                text-decoration: underline;
                font-size: 0.8em;
                font-weight: 600;
            }
    }

    > .itens {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        column-gap: 10px;
        overflow-x: auto;
        overflow-y: hidden;

        ::-webkit-scrollbar {
            height: 4px;
        }
        
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #FDBC11;
            border-radius: 10px;
        }
    }
`;

export const ComparadoresMenorContainer = styled(ComparadoresMaiorContainer)`
    top: initial;
    bottom: -90px;

    > .comparador {
        color: #292828;
    }
`;