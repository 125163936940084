import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import { useAuth } from '../../../providers/Auth';

const BebidaTr = ({ bebida }) => {
    const { user } = useAuth();

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{bebida.id}
            </td>
            <td>
                <label>Nome</label>
                {bebida.nome}
            </td>
            <td>
                <label>Segmento</label>
                {bebida.segmento}
            </td>
            <td>
                <label>Marca</label>
                {bebida.marca}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {bebida.geo}
                    </>
                )}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/bebida/editar/${bebida.id}`}>
                    editar <ReactSVG src={EditarSvg} />
                </Link>
            </td>
        </Tr>
    )
};

export default BebidaTr;