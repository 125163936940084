import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalReprovarPedidoContainer } from './styles';

const ModalReprovarPedido = ({ setModalOpened, pedido, buscarPedido, levaAtual }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleReprovarPedido = () => {
        api.patch(`/pedidos/${pedido.id}/status`, {status: 'REPROVADO', leva_id: levaAtual.id})
            .then((response) => {
                toast.success('Pedido reprovado com sucesso!');
                buscarPedido();
                setModalOpened(false);
                navigate('/pedidos');
            }).catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível reprovar o pedido. Tente novamente mais tarde.')
                setModalOpened(false);
            });
    };

    return (
        <ModalReprovarPedidoContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja <span>reprovar</span> esse pedido?</p>
                    <span>O status do pedido ficará como reprovado e o pdf será deletado.</span>
                    <ButtonsContainer>
                        <button onClick={handleReprovarPedido}
                        >
                            SIM
                        </button>
                        <button onClick={() => setModalOpened(false)}>
                            NÃO
                        </button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalReprovarPedidoContainer>
    )
};

export default ModalReprovarPedido;