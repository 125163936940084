import Dashboard from '../../components/Dashboard';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { DashboardPageContainer } from './styles';

const DashboardPage = () => {

    return (
        <DashboardPageContainer>
            <Header page='dashboard'/>
            <Dashboard />
            <Footer />
        </DashboardPageContainer>
    );
};

export default DashboardPage;