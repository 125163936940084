import { ReactSVG } from 'react-svg';
import { Button } from './styles';
import DownloadSvg from '../../assets/download.svg';
import api from '../../services/api';
import { toast } from 'react-toastify';
import JSZip from "jszip";
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import { useAuth } from '../../providers/Auth';

const DownloadPedidoButton = ({ pedido, buscarPedidos }) => {

    const {user}=useAuth()
    const downloadFile = async () => {
        try {
            const zip = new JSZip();

            let responseCardapio = '';
            let responseQrCode = '';

            if(user.permissao === 'mari'){
                if (Number(pedido.impresso)) {
                    responseCardapio = await axios.get(`https://gestorcadapios.blob.core.windows.net${pedido.link_pdv}.pdf`, {
                        responseType: 'arraybuffer',
                    });
                };
                if (Number(pedido.qrcode)) {
                    responseQrCode = await axios.get(`https://gestorcadapios.blob.core.windows.net/qrcode/${pedido.link_pdv.split('/').pop()}.pdf`, {
                        responseType: 'arraybuffer',
                    });
                };
            }
            else if(Number(pedido.impresso)) {
                responseCardapio = await api.get(`${pedido.link}`, {
                    responseType: 'arraybuffer',
                });
            };
            
            if (pedido.status === 'APROVADO') {
                await api.patch(`/pedidos/${pedido.id}/status`, { status: 'BAIXADO' });
            }
            if (responseCardapio && responseQrCode) {
                const pdfDoc1 = await PDFDocument.load(responseCardapio.data);
                const pdfDoc2 = await PDFDocument.load(responseQrCode.data);

                const mergedDoc = await PDFDocument.create();
                for (let i = 0; i < pdfDoc1.getPageCount(); i++) {
                    const [page] = await mergedDoc.copyPages(pdfDoc1, [i]);
                    mergedDoc.addPage(page);
                };
                for (let i = 0; i < pdfDoc2.getPageCount(); i++) {
                    const [page] = await mergedDoc.copyPages(pdfDoc2, [i]);
                    mergedDoc.addPage(page);
                };

                const mergedPdfBytes = await mergedDoc.save();
                zip.file(`${pedido.id}_${pedido.titulo}_${pedido.template}.pdf`, mergedPdfBytes);
            } else if (responseCardapio) {
                zip.file(`${pedido.id}_${pedido.titulo}_${pedido.template}.pdf`, responseCardapio.data);
            } else if (responseQrCode) {
                zip.file(`${pedido.id}_${pedido.titulo}_${pedido.template}.pdf`, responseQrCode.data);
            };
            const content = await zip.generateAsync({ type: "blob" });
            const url = window.URL.createObjectURL(content);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pedido_${pedido.id}.zip`);
            document.body.appendChild(link);
            link.click();
            buscarPedidos();
        } catch (e) {
            toast.error('Erro ao baixar o pedido');
            console.log(e)
        }
    };

    if(user.permissao === "mari"){
        return (
            <Button 
                onClick={async () => downloadFile()}
                disabled={pedido.status === 'CONCLUIDO'}
            >
                <ReactSVG src={DownloadSvg}/>
            </Button>
        );
    
    }else{
        return (
            <Button 
                onClick={async () => downloadFile()}
                disabled={pedido.status === 'CONCLUIDO'}
            >
                <ReactSVG src={DownloadSvg}/>Baixar pdf
            </Button>
        );
    }
};

export default DownloadPedidoButton;