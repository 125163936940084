import * as yup from 'yup';

const usuarioSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório (Nome).'),
    usuario_id: yup.string().required('Campo obrigatório (Id).'),
    codigo: yup.string().required('Campo obrigatório (Código).'),
    email: yup.string().email('Formato de e-mail inválido.').required('Campo obrigatório (E-mail).'),
    permissao_id: yup.string().required('Campo obrigatório (Permissao).'),
});

export default usuarioSchema;