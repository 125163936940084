
export const paginador = (lista, pagina = 1, limite = 10) => {
    const paginasTotal = Math.ceil(lista.length / limite);
    let paginaAtual = pagina;
    if(pagina > paginasTotal){
        paginaAtual = paginasTotal;
    };
    const startIndex = (paginaAtual - 1) * limite;
    const endIndex = paginaAtual * limite;
    const itens = lista.slice(startIndex, endIndex);

    return {
        itens,
        paginas: paginasTotal === 0 ? 1 : paginasTotal,
        paginaAtual: paginaAtual === 0 ? 1 : paginaAtual,
    };
};