export const getDataUTC = (utc) => {
    const dataAtual = new Date();
    const dataNaoFormatada = new Date(dataAtual.valueOf() - (utc * 60) * 60000);

    const {dia,mes,anoCompleto,hora,minuto} = {
        dia: (dataNaoFormatada.getUTCDate()).toString().padStart(2,'0'),
        mes: (dataNaoFormatada.getUTCMonth()+1).toString().padStart(2,'0'),
        anoCompleto: dataNaoFormatada.getFullYear(),
        hora: dataNaoFormatada.getUTCHours().toString().padStart(2,'0'),
        minuto: dataNaoFormatada.getUTCMinutes().toString().padStart(2,'0'),
    };
    
    const dataFormatada = [dia,mes,anoCompleto].join("/") + " " + [hora,minuto].join(":");
    return dataFormatada;
}