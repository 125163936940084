import styled from 'styled-components';

export const EscadaBebidaContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    min-width: 110px;
    height: 80px;
    padding: 5px;
    background: #fff;
    border: 1px solid #70707030;
    border-radius: 9px;
    text-align: center;
    color: #292828;
    font-size: 12px;
    font-weight: 500;

    position: relative;
`;

export const ExcluirBebidaVolumeButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    background-color: #fa5252;
    width: 20px;
    height: 20px;

    position: absolute;
    top: -10px;
    right: -10px;

    > div {
        width: 12px;
        height: 12px;

        > div {
            width: 100%;
            height: 100%;
            
            svg {
                width: 7px;
                height: 8px;
                margin-bottom: 4px;
            }
        } 
    }
`;

export const ExibirMaiorButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    width: 20px;
    height: 20px;
    background: #FDBC11;
    font-size: 0.9em;

    position: absolute;
    top: -10px;
    left: -10px;
`;

export const ExibirMenorButton = styled(ExibirMaiorButton)`
    top: initial;
    background: #292828;
    bottom: -10px;
`;

export const ArrowAbsoluteTop = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    
    top: -22px;
    left: -15px;
    border: 1.1rem solid transparent;
    border-top: none;

    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    transform: rotate(180deg);
    z-index: 2;
`;

export const ArrowAbsoluteBottom = styled(ArrowAbsoluteTop)`
    top: initial;
    bottom: -22px;
    transform: initial;
`;