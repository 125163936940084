import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    width: 100%;
     
    > .importar-container {
        width: 100%;

        > button {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            padding: 10px 15px;
            margin-left: auto;
            background: none;
            border: 1px solid #292828;
            border-radius: 20px;
            color: #292828;

        }
    }

    > .nome {
        width: 80.5%;
    }

    > .usuario_id,
    > .codigo {
        width: 18%;
    }

    > .email {
        width: 55%;
    }

    > .senha {
        width: 24%;
    }

    > .permissao {
        width: 100%;
    }

    > .geo {
        width: 100%;
    }

    > .buttons-container {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        > a {
            color: #292828;
        }
    }

    @media screen and (max-width: 840px) {   
        > .importar-container {
            display: none;
        }
        
        > .nome {
            width: 100%;
        }

        > .usuario_id,
        > .codigo {
            width: 49%;
        }

        > .email {
            width: 60%;
        }

        > .senha {
            width: 38%;
        }
    }

    @media screen and (max-width: 550px) {
        > .nome,
        > .usuario_id,
        > .codigo,
        > .email,
        > .senha {
            width: 100%;
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > input {
        background-color: #F0F0F0;
        border-radius: 6px;
        width: 100%;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
    }

    > span {
        top: 55px;
    }
`;

export const SelectContainer = styled.div`
   display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    > span {
        top: 55px;
    }

    > .react-select-container {
        .react-select__multi-value {
            .react-select__multi-value__label {
                min-width: 50px;
            }
            
            .react-select__multi-value__remove {
                > svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .react-select__clear-indicator {
            display: none;
        }

        .react-select__option {
            color: #292828;
        }
    }
`;

export const SalvarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;
`;