import { AdminCategoriaEditarContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroCategoriaSvg from '../../assets/cadastro-categoria.svg';
import CategoriaForm from '../Forms/CategoriaForm';

const AdminCategoriaEditar = () => {

    return (
        <AdminCategoriaEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroCategoriaSvg}/> <h1>Editar Categoria</h1> 
            </div>
            <ContentContainer>
                <CategoriaForm />
            </ContentContainer>
        </AdminCategoriaEditarContainer>
    );
};

export default AdminCategoriaEditar;