import { AdminBebidaEditarContainer, ContentContainer } from './styles';
import BebidaForm from '../Forms/BebidaForm';
import { ReactSVG } from 'react-svg';
import CadastroBebidasSvg from '../../assets/cadastro-bebidas.svg';

const AdminBebidaEditar = () => {
    return (
        <AdminBebidaEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroBebidasSvg}/> <h1>Editar Bebida</h1> 
            </div>
            <ContentContainer>
                <BebidaForm />
            </ContentContainer>
        </AdminBebidaEditarContainer>
    );
};

export default AdminBebidaEditar;