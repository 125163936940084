import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/Auth';

const OperacaoTr = ({ operacao }) => {
    const { user } = useAuth();

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{operacao.id}
            </td>
            <td>
                <label>Nome</label>
                {operacao.nome}
            </td>
            <td>
                <label>Comercial</label>
                {operacao.comercial}
            </td>
            <td>
                <label>Tipo</label>
                {operacao.tipo}
            </td>
            <td>
                <label>Geo</label>
                {operacao.geo_nome}
            </td>
            <td>
                <label>Código</label>
                {operacao.codigo}
            </td>
            <td>
                <label></label>
                {['ac', 'admin'].includes(user.permissao) && (
                    <Link to={`/administrativo/operacao/editar/${operacao.id}`}>
                        editar <ReactSVG src={EditarSvg} />
                    </Link>
                )}
            </td>
        </Tr>
    );
};

export default OperacaoTr;