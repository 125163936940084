import { useAuth } from '../../providers/Auth';
import { PedidosLevaContainer, PedidosLevasContainer } from './styles';
import { ReactSVG } from 'react-svg';
import HomeAguardandoSvg from '../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import { useEffect, useState } from 'react';

const PedidosLevas = ({ pedidos, levaAtual, levasAtivas, geoSelecionada }) => {
    const [pedidosLevaAtual, setPedidosLevaAtual] = useState([]);
    const [pedidosLevaAguardando, setPedidosLevaAguardando] = useState([]);
    const [pedidosLevaRevisar, setPedidosLevaRevisar] = useState([]);
    const [pedidosLevaAprovados, setPedidosLevaAprovados] = useState([]);

    const [pedidosLevasAtivas, setPedidosLevasAtivas] = useState([]);
    const [pedidosLevasAtivasAguardando, setPedidosLevasAtivasAguardando] = useState([]);
    const [pedidosLevasAtivasRevisar, setPedidosLevasAtivasRevisar] = useState([]);
    const [pedidosLevasAtivasAprovados, setPedidosLevasAtivasAprovados] = useState([]);
    
    const { user } = useAuth();

    useEffect(() => {
        if (pedidos.length) {
            if (['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada) {
                const pedidosLevasAtivas = levasAtivas.reduce((acc, leva) => {
                    if (!leva.data_encerramento) {
                        return acc.concat(pedidos.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                    };
                },  []);
                const pedidosLevasAtivasAguardando = pedidosLevasAtivas.filter(pedido => pedido.status === 'AGUARDANDO');
                const pedidosLevasAtivasRevisar = pedidosLevasAtivas.filter(pedido => pedido.status === 'REVISAR');
                const pedidosLevasAtivasAprovados = pedidosLevasAtivas.filter(pedido => pedido.status === 'APROVADO');
                setPedidosLevasAtivasAguardando(pedidosLevasAtivasAguardando);
                setPedidosLevasAtivasRevisar(pedidosLevasAtivasRevisar);
                setPedidosLevasAtivasAprovados(pedidosLevasAtivasAprovados);
                setPedidosLevasAtivas(pedidosLevasAtivas);
            } else if (levaAtual) {
                const pedidosLevaAtual = pedidos.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
                const pedidosLevaAguardando = pedidosLevaAtual.filter(pedido => pedido.status === 'AGUARDANDO');
                const pedidosLevaRevisar = pedidosLevaAtual.filter(pedido => pedido.status === 'REVISAR');
                const pedidosLevaAprovados = pedidosLevaAtual.filter(pedido => pedido.status === 'APROVADO');
                setPedidosLevaAguardando(pedidosLevaAguardando);
                setPedidosLevaRevisar(pedidosLevaRevisar);
                setPedidosLevaAprovados(pedidosLevaAprovados);
                setPedidosLevaAtual(pedidosLevaAtual);
            }
        }
    } ,[pedidos, levaAtual, levasAtivas]);

    return (
        <PedidosLevasContainer>
            {['mari','ac'].includes(user.permissao) && !geoSelecionada ? (
                <PedidosLevaContainer>
                    <div className='geo'>
                        {levasAtivas.length && !levaAtual ? (
                            <h2>Todas as GEOs</h2>
                        ) : (
                            <h2>Nenhuma leva ativa</h2>
                        )}
                    </div>
                    <div className='item'>
                        <h3>Total pedidos levas ativas</h3>
                        {levasAtivas.length ? (
                            <p>{pedidosLevasAtivas.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeAguardandoSvg}/> Aguardando levas ativas</h3>
                        {levasAtivas.length ? (
                            <p style={{color: '#F16623'}}>{pedidosLevasAtivasAguardando.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeRevisarSvg}/> Revisando levas ativas</h3>
                        {levasAtivas.length ? (
                            <p style={{color: '#FDBC11'}}>{pedidosLevasAtivasRevisar.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeAprovadosSvg}/> Aprovados levas ativas</h3>
                        {levasAtivas.length ? (
                            <p style={{color: '#40AD48'}}>{pedidosLevasAtivasAprovados.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                </PedidosLevaContainer>
            ) : (
                <PedidosLevaContainer>
                    <div className='geo'>
                        {levaAtual ? (
                            <h2>GEO {levaAtual.geo_nome.toUpperCase()}</h2>
                        ) : (
                            <h2>Nenhuma leva ativa</h2>
                        )}
                    </div>
                    <div className='item'>
                        <h3>Total pedidos leva atual</h3>
                        {levaAtual ? (
                            <p>{pedidosLevaAtual.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeAguardandoSvg}/> Aguardando leva atual</h3>
                        {levaAtual ? (
                            <p style={{color: '#F16623'}}>{pedidosLevaAguardando.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeRevisarSvg}/> Revisando leva atual</h3>
                        {levaAtual ? (
                            <p style={{color: '#FDBC11'}}>{pedidosLevaRevisar.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                    <div className='divisor'/>
                    <div className='item'>
                        <h3><ReactSVG src={HomeAprovadosSvg}/> Aprovados leva atual</h3>
                        {levaAtual ? (
                            <p style={{color: '#40AD48'}}>{pedidosLevaAprovados.length}</p>
                        ) : (
                            <p style={{color: '#E9E9E9'}}>-</p>
                        )}
                    </div>
                </PedidosLevaContainer>
            )}
        </PedidosLevasContainer>
    );
};

export default PedidosLevas;