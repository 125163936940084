import styled from 'styled-components';

export const AdminBebidaEditarContainer = styled.div`
    margin: 1rem 2rem;

    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        margin: 1rem 0;
        
        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #f9f9f9;
                stroke: #f9f9f9;
            }
        }

        > h1 {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 840px) {
        margin: 1rem;
    }
`;

export const ContentContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
`;