import { LoginFormContainer, Form, CodigoInputContainer, PasswordInputContainer, LoginButton } from './styles';
import { ReactComponent as LoginEmailSvg } from '../../../assets/login-email.svg';
import { ReactComponent as LoginSenhaSvg } from '../../../assets/login-senha.svg';
import { ReactComponent as LoginVerSenhaSvg } from '../../../assets/login-ver-senha.svg';
import { useState } from 'react';
import loginSchema from '../../../schemas/Login/loginSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../../services/api';
import { useAuth } from '../../../providers/Auth';
import { toast } from 'react-toastify';

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(loginSchema)
    });

    const { setToken, setLoadingToken } = useAuth();

    const onSubmitFunction = (data) => {
        setLoading(true);
        api.post('/login', data)
            .then(response => {
                const token = response.headers['x-access-token'];
                localStorage.setItem('@gcardapio:token', token);
                setToken(token);
                setLoadingToken(true);
                setLoading(false);
            }).catch(error => {
                toast.error('Código ou senha inválidos. Tente novamente.')
                setLoading(false);
            })
        
    };

    return (
        <LoginFormContainer>
            <h1>gestor de <span>cardápio</span></h1>
            <p>Preencha os campos e efetue o login</p>
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                <CodigoInputContainer>
                    <label htmlFor='codigo'>
                        <LoginEmailSvg />
                    </label>
                    <input 
                        id='codigo'
                        placeholder='Código'
                        type='text'
                        {...register('codigo')}
                    />
                    {errors.codigo && <p style={{top: '30px', left: '20px'}} className='input-error'>{errors.codigo.message}</p>}
                </CodigoInputContainer>
                <PasswordInputContainer showPassword={showPassword}>
                    <label htmlFor='password'>
                        <LoginSenhaSvg />
                    </label>
                    <input
                        id='password'
                        placeholder='Senha'
                        type={showPassword ? 'text' : 'password'}
                        {...register('senha')}
                    />
                    {errors.senha && <p style={{top: '30px', left: '20px'}} className='input-error'>{errors.senha.message}</p>}
                    <LoginVerSenhaSvg onClick={() => setShowPassword(!showPassword)}/>
                </PasswordInputContainer>
                <LoginButton>
                    {loading ? (
                        <div className='loading-container'>
                            <span className="circle"></span>
                            <span className="circle"></span>
                            <span className="circle"></span>
                            <span className="circle"></span>
                        </div>
                    ) : (
                        'Entrar'
                    )}
                </LoginButton>
            </Form>
        </LoginFormContainer>
    );
};

export default LoginForm;