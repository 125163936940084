import AdminUsuarioEditar from '../../components/AdminUsuarioEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminUsuarioEditarPageContainer } from './styles';

const AdminUsuarioEditarPage = () => {

    return (
        <AdminUsuarioEditarPageContainer>
            <Header />
            <AdminUsuarioEditar />
            <Footer />
        </AdminUsuarioEditarPageContainer>
    );
};

export default AdminUsuarioEditarPage;