import AdminPdvEditar from '../../components/AdminPdvEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminPdvEditarPageContainer } from './styles';

const AdminPdvEditarPage = () => {

    return (
        <AdminPdvEditarPageContainer>
            <Header />
            <AdminPdvEditar />
            <Footer />
        </AdminPdvEditarPageContainer>
    );
};

export default AdminPdvEditarPage;