import styled from 'styled-components';

export const AdminGeoContainer = styled.div`
    margin: 1rem 2rem;

    > .header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 15px;
        border-radius: 15px;
        color: #f9f9f9;
        background-color: #292828;
        margin: 1rem 0;
        
        > svg {
            path, polygon, rect, circle, ellipse {
                fill: #f9f9f9;
                stroke: #f9f9f9;
            }
        }

        > h1 {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 840px) {
        margin: 1rem;
    }
`;

export const ContentContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > .header {
        display: flex;
        column-gap: 10px;
        margin: 1rem 0;

        > .extrair {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            width: 150px;
            height: 40px;
            padding: 10px 20px;
            background-color: transparent;
            color: #292828;
            border: 1px solid #292828;
            border-radius: 20px;

            > svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }
        }

        > .novo {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FDBC11;
            margin-left: auto;
            width: 150px;
            height: 40px;
            border-radius: 20px;

            color: #292828;
            font-weight: 700;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 840px) {
        padding: 1rem;

        > .header {
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: space-between;
        }
    }
`;

export const PesquisarInputContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
    background: #F0F0F0;
    border-radius: 22px;
    width: 300px;
    
    > input {
        color: #292828;
        border: none;
        background: transparent;
        padding: 10px;
        padding-left: 20px;
        width: 100%;
        height: 40px;

        :focus {
            outline: none;
        }
    }

    > label {
        cursor: text;
    }

    @media screen and (max-width: 840px) {
        width: 100%;
        max-width: 400px;
    }
`;

export const TotalGeosContainer = styled.div`
    display: flex;
    color: #292828;
    column-gap: 4px;
    
    svg {
        width: 20px;
        height: 20px;
        path, polygon, rect, circle, ellipse {
            fill: #FDBC11;
        }
    }
`;

export const TableContainer = styled.div`
   @media screen and (max-width: 840px) {
        > table {
            > thead {
                display: none;
            }
        }
    }
`;