import styled from 'styled-components';

export const PdfViewerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;

    > .notfound {
        color: #292828;
        font-weight: 600;
        font-size: 1.1em;
        margin: 20px 0;
    }

    .qr-code {
        > figure {
            width: 400px;

            img {
                width: 100%;
            }
        }
    }
`;

export const PdfContainer = styled.div`
    width: 80%;
    max-width: 800px;
    min-height: 80vh;

    > .qr-code {
        display: ${({pedido, pageNumber, numPages}) => (Number(pedido.qrcode) && pageNumber === numPages) ? 'block' : 'none'};
        > figure {
            margin: 0 auto;
        }
    }

    > .pdf {
        display: ${({pedido, pageNumber, numPages}) => !(Number(pedido.qrcode) && pageNumber === numPages) ? 'block' : 'none'};
    }

    .react-pdf__Document {
        .react-pdf__Page {
            canvas {
                max-width: 2000px;
                width: 100% !important;
                height: auto !important;
            }

            .react-pdf__Page__textContent {
               display: none;
            }

            .react-pdf__Page__annotations {
               display: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const PaginatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    column-gap: 10px;

    color: #292828;
    font-weight: 600;
    text-align: center;
    font-size: 1.1em;

    > button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        width: 117px;
        height: 42px;

        border: 1px solid #292828;
        border-radius: 20px;

        background: none;
        color: #292828;
        font-size: 0.9em;
        font-weight: 500;

        svg {
            path {
                fill: #292828;
            }
        }
    }

    @media screen and (max-width: 840px) {
        font-size: 0.8em;
        
        > button {
            width: 90px;
            height: 35px;

            svg {
                width: 15px;
            }
        }
    }
`;
