import * as yup from 'yup';

const pdvSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório (nome).'),
    // eslint-disable-next-line no-useless-escape
    cnpj: yup.string().matches(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}/,'CNPJ inválido.').required('Campo obrigatório (CNPJ).'),
    codigo: yup.string().required('Campo obrigatório (código).'),
    operacao_id: yup.string().required('Campo obrigatório (operação).'),
});

export default pdvSchema;