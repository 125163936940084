import * as yup from 'yup';

const volumesValidos = [
    '100ml',
    '200ml',
    '250ml',
    '260ml',
    '269ml',
    '275ml',
    '290ml',
    '300ml',
    '330ml',
    '340ml',
    '350ml',
    '355ml',
    '375ml',
    '400ml',
    '473ml',
    '500ml',
    '600ml',
    '700ml',
    '740ml',
    '750ml',
    '1L',
    '1,5L',
    '2L',
    'BARRIL 10L',
    'BARRIL 30L',
    'BARRIL 50L',
    'CHOPP',
    'LN 269ml',
    'LT 269ml',
    '69ml',
];

yup.addMethod(yup.string, 'volumeValido', function (message) {
    return this.test('volumeValido', message, function (value) {
        if (!volumesValidos.includes(value)) {
            return false
        };
        return true
    });
});

const volumeSchema = yup.object().shape({
    bebida_id: yup.object().nullable().required('Campo obrigatório'),
    volume: yup.string().volumeValido('Volume inválido.').required('Campo obrigatório'),
});

export default volumeSchema;