import styled from 'styled-components';

export const Tr = styled.tr`
    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
        
        > label {
            display: none;
        }

        > a,
        > a:link,
        > a:visited,
        > a:active {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 4px;
            color: #292828;
            font-size: 0.9em;

            > svg {
                height: 12px;
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }
            }
        }

        :last-child {
            text-align: end;
        }
    }
  
    @media screen and (max-width: 840px) {
        display: flex;
        flex-direction: column;
        border: 1px solid #E9E9E9;
        border-radius: 15px;
        margin: 1rem 0;
        padding: 1rem;
        
        > td {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            > label {
                display: flex;
                color: #292828;
                font-weight: 600;
            }
        }

        > td:last-child {
            border-bottom: none;
        }
    }
`;