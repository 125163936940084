import styled from 'styled-components';

export const TotalPedidosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    min-width: 400px;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 20px;

    position: relative;

    > h3 {
        font-weight: 700;
        font-size: 1.1em;
        color: #292828;
        text-align: left;
        width: 100%;
    }

    > canvas {
        margin: 0 auto;
    }

    @media screen and (max-width: 768px) {
        min-width: 0px;
    }
`;

export const TotalContainer = styled.div`
    position: absolute;
    right: 20px;

    > h4 {
        color: #292828;
        font-weight: 700;
        font-size: 1.5em;
        text-align: center;
    }

    > p {
        color: #292828;
        font-size: 1.1em;
    }
`;