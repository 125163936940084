import * as yup from 'yup';
import usuarioSchema from './usuarioSchema';

const usuariosSchema = yup.object().shape({
    usuarios: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(usuarioSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default usuariosSchema;