import axios from "axios";

const api = axios.create({
	baseURL: 'https://cardapioapi.azurewebsites.net/',
	// baseURL:'http://localhost:8080'
});

api.interceptors.request.use((config) => {
	const token = localStorage.getItem('@gcardapio:token');

	if (token) {
		config.headers = {
			Authorization: `Bearer ${token}`
		};
	}

	return config;
});


export default api;