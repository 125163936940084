import AdminVolume from '../../components/AdminVolume';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminVolumePageContainer } from './styles';

const AdminVolumePage = () => {

    return (
        <AdminVolumePageContainer>
            <Header />
            <AdminVolume />
            <Footer />
        </AdminVolumePageContainer>
    );
};

export default AdminVolumePage;