import * as yup from 'yup';
import pdvSchema from './pdvSchema';

const pdvsSchema = yup.object().shape({
    pdvs: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(pdvSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default pdvsSchema;