import { ContentContainer, PedidosNovoContainer } from './styles';
import { ReactSVG } from 'react-svg';
import NovoPedidoSvg from '../../assets/novo-pedido.svg';
import PedidoForm from '../Forms/PedidoForm';
import { useEffect, useState } from 'react';
import Loading from '../Loading';
import api from '../../services/api';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import PedidoIncompleto from '../PedidoIncompleto';

const PedidosNovo = () => {
    const [loading, setLoading] = useState(true);
    const [pedidoIncompleto, setPedidoIncompleto] = useState('');
    
    const { logout } = useAuth();
    const navigate = useNavigate();

    const buscarPedidoIncompleto = () => {
        setLoading(true);
        api.get('/pedidos?status=INCOMPLETO')
            .then((response) => {
                setPedidoIncompleto(response.data.data.pedidos[0]);
                setLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                setLoading(false);
            });
    };

    useEffect(() => {
        buscarPedidoIncompleto();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PedidosNovoContainer>
            <div className='header'>
                <ReactSVG src={NovoPedidoSvg}/> <h1>Novo pedido</h1> 
            </div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {pedidoIncompleto ? (
                        <PedidoIncompleto pedido={pedidoIncompleto} buscarPedidoIncompleto={buscarPedidoIncompleto}/>
                    ) : (
                        <ContentContainer>
                            <PedidoForm buscarPedidoIncompleto={buscarPedidoIncompleto} />  
                        </ContentContainer>
                    )}
                </>
            )} 
        </PedidosNovoContainer>
    );
};

export default PedidosNovo;