import { AdminBebidaNovoContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroBebidasSvg from '../../assets/cadastro-bebidas.svg';
import BebidaForm from '../Forms/BebidaForm';

const AdminBebidaNovo = () => {

    return (
        <AdminBebidaNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroBebidasSvg}/> <h1>Cadastro de Nova Bebida</h1> 
            </div>
            <ContentContainer>
                <BebidaForm />
            </ContentContainer>
        </AdminBebidaNovoContainer>
    )
};

export default AdminBebidaNovo;