import AdminEscada from '../../components/AdminEscada';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminEscadaPageContainer } from './styles';

const AdminEscadaPage = () => {

    return (
        <AdminEscadaPageContainer>
            <Header />
            <AdminEscada />
            <Footer />
        </AdminEscadaPageContainer>
    );
};

export default AdminEscadaPage;