import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { useAuth } from '../../../providers/Auth';
import pdvSchema from '../../../schemas/Pdv/pdvSchema';
import { cnpjMask } from '../../../utils/masks';
import ImportarSvg from '../../../assets/importar.svg';
import { toast } from 'react-toastify';
import { ReactSVG } from 'react-svg';
import ModalImportarPdvs from '../../Modals/ModalImportarPdvs';
import Loading from '../../Loading';

const PdvForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(pdvSchema)
    });

    const { pdv_id } = useParams();
    const { logout } = useAuth();
    const navigate = useNavigate();

    const [operacoes, setOperacoes] = useState([]);

    const [modalImportarPdvs, setModalImportarPdvs] = useState(false);

    const [pdvLoaded, setPdvLoaded] = useState(false);
    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pdvLoaded && operacoesLoaded) {
            setLoading(false);
        };
    }, [pdvLoaded, operacoesLoaded]);
    
    const buscarOperacoes = () => {
        api.get('/operacoes')
            .then((response) => {
                setOperacoes(response.data.data.operacoes);
                setOperacoesLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        if (pdv_id) {
            api.get(`/pdvs/${pdv_id}`)
                .then(response => {
                    const pdv = response.data.data.pdv;
                    reset(pdv);
                    setValue('cnpj', cnpjMask(pdv.cnpj));
                    setPdvLoaded(true);
                })
                .catch(error => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 404) {
                        toast.error('Ponto de venda não encontrado!');
                        return;
                    };
                    toast.error('Erro ao buscar ponto de venda!');
                });
        } else {
            setPdvLoaded(true);
        };

        buscarOperacoes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitFunction = (data) => {
        if (pdv_id) {
            data.id = pdv_id;
            api.patch(`/pdvs`, {pdvs: [data]})
                .then((response) => {
                    toast.success('Ponto de venda atualizado com sucesso!');
                    navigate('/administrativo/ponto-de-venda');
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 404) {
                        toast.error('Ponto de venda não encontrado!');
                        return;
                    };
                    if (error.response?.status === 409) {
                        toast.error('Já existe um ponto de venda cadastrado com esse Código');
                        return;
                    };
                    toast.error('Erro ao atualizar ponto de venda');
                });
        } else {
            api.post('/pdvs', {pdvs: [data]})
                .then((response) => {
                    toast.success('Ponto de venda cadastrado com sucesso!');
                    reset();
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 409) {
                        toast.error('Já existe um ponto de venda cadastrado com esse Código');
                        return;
                    };
                    toast.error('Erro ao cadastrar ponto de venda');
                });
        };
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                {!pdv_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarPdvs(true)} type='button'>
                        <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div> 
                )}
                <InputContainer className='nome'>
                    <label htmlFor='nome'>Nome</label>
                    <input 
                        type='text' 
                        id='nome' 
                        placeholder='Nome do PDV'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                </InputContainer>
                <InputContainer className='cnpj'>
                    <label htmlFor='cnpj'>CNPJ</label>
                    <input
                        type='text'
                        id='cnpj'
                        placeholder='00.000.000/0000-00'
                        {...register('cnpj', {
                            onChange: (e) => {
                                setValue('cnpj', cnpjMask(e.target.value))
                            }
                        })}
                    />
                    {errors.cnpj && <span className='input-error'>{errors.cnpj.message}</span>}
                </InputContainer>
                <InputContainer className='codigo'>
                    <label htmlFor='codigo'>Código</label>
                    <input
                        type='text'
                        id='codigo'
                        placeholder='99815014'
                        {...register('codigo')}
                    />
                    {errors.codigo && <span className='input-error'>{errors.codigo.message}</span>}
                </InputContainer>
                <SelectContainer className='operacao'>
                    <label htmlFor='operacao'>Operação</label>
                    <select id='operacao' {...register('operacao_id')}>
                        <option value=''>Selecione a operação</option>
                        {operacoes.map((operacao) => (
                            <option key={operacao.id} value={operacao.id}>{operacao.nome}</option>
                        ))}
                    </select>
                    {errors.operacao_id && <span className='input-error'>{errors.operacao_id.message}</span>}
                </SelectContainer>
                <div className='buttons-container'>
                    <Link to='/administrativo/ponto-de-venda'>{'< Voltar'}</Link>
                    <SalvarButton type='submit'>
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
                {modalImportarPdvs && <ModalImportarPdvs setModalOpened={setModalImportarPdvs}/>}
            </Form>
        )}
        </>
    );
};

export default PdvForm;