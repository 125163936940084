import AdminVolumeEditar from '../../components/AdminVolumeEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminVolumeEditarPageContainer } from './styles';

const AdminVolumeEditarPage = () => {

    return (
        <AdminVolumeEditarPageContainer>
            <Header />
            <AdminVolumeEditar />
            <Footer />
        </AdminVolumeEditarPageContainer>
    );
};

export default AdminVolumeEditarPage;