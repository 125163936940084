import { AdminPdvEditarContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroPontoDeVenda from '../../assets/cadastro-ponto-venda.svg';
import PdvForm from '../Forms/PdvForm';

const AdminPdvEditar = () => {

    return (
        <AdminPdvEditarContainer>
            <div className='header'>
                <ReactSVG src={CadastroPontoDeVenda}/> <h1>Editar Ponto de Venda</h1> 
            </div> 
            <ContentContainer>
                <PdvForm />
            </ContentContainer>
        </AdminPdvEditarContainer>
    );
};

export default AdminPdvEditar;