import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalAjustarPedidoContainer } from './styles';

const ModalAjustarPedido = ({ setModalOpened, pedido, buscarPedido, levaAtual }) => {
    const [observacao, setObservacao] = useState('');
    
    const { logout } = useAuth();
    const navigate = useNavigate();
    
    const handleRevisarPedido = async () => {
        try {
            await api.post(`/pedido-observacoes/${pedido.id}`, {observacao})
            await api.patch(`/pedidos/${pedido.id}/status`, {status: 'REVISAR', leva_id: levaAtual.id})
            buscarPedido();
            toast.success('Pedido enviado para revisão com sucesso!');
            setModalOpened(false);
            navigate('/pedidos');
        } catch (error) {
            if (error.response?.status === 401) {
                logout();
                navigate('/');
                return;
            }
            setModalOpened(false);
            toast.error('Não foi possível enviar o pedido para revisão. Tente novamente mais tarde.')
        }
    };

    return (
        <ModalAjustarPedidoContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Descreva os itens a serem ajustados</p>
                    <textarea
                        placeholder='Descrição'
                        value={observacao}
                        onChange={(e) => setObservacao(e.target.value)}
                    />
                    <ButtonsContainer>
                        <button onClick={async () => await handleRevisarPedido()}>Enviar ajustes</button>
                        <button onClick={() => setModalOpened(false)}>{'< Voltar'}</button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalAjustarPedidoContainer>
    )
};

export default ModalAjustarPedido;