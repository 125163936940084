import styled from 'styled-components';

export const OperacoesAtivasContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    min-width: 500px;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 20px 30px;

    > .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        > div {
            > h3 {
                font-weight: 700;
                font-size: 1.1em;
                color: #292828;
            }

            > p {
                font-size: 0.9em;
                color: #292828;
            }
        }

        > select {
            background-color: #FDBC11;
            width: 100px;
            border-radius: 6px;
            border: none;
            margin-left: 4px;
            color: #fff;
            text-align: center;
            height: 30px;
        }
    }

    > .body {
        > .grafico-container {
            width: 100%;

            > canvas {
                width: 450px !important;
                height: 240px !important;
            }
        }

        > .info-container {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 5px;
            margin: 10px 0;

            svg {
                height: 30px;    
                width: 30px;

                path, polygon, rect, circle, ellipse {
                    fill: #FDBC11;
                }
            }

            > h3 {
                color: #292828;
                font-weight: 700;
                font-size: 2em;
            }
            
            > p {
                color: #292828;
                font-size: 0.8em;
                line-height: 10px;
                width: 130px;
                > strong {
                    font-weight: 700;
                    color: #292828;
                    font-size: 1em;
                }
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        min-width: 0;

        > .body {
            > .grafico-container {
                width: 100%;

                > canvas {
                    width: 350px !important;
                    height: 200px !important;
                }
            }

            > .info-container {
                svg {
                    width: 25px;
                    height: 25px;
                }
    
                > h3 {
                    font-size: 1.6em;
                }

                > p {
                    line-height: 8px;
                }
            }
        }
    }
`;
