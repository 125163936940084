import AdminPdv from '../../components/AdminPdv';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminPdvPageContainer } from './styles';

const AdminPdvPage = () => {

    return (
        <AdminPdvPageContainer>
            <Header />
            <AdminPdv />
            <Footer />
        </AdminPdvPageContainer>
    );
};

export default AdminPdvPage;