import styled from 'styled-components';

export const ModalAjustarPedidoContainer = styled.div`

`;

export const ContentContainer = styled.div`
    padding: 3rem;
    min-width: 350px;
    width: 40vw;
    max-width: 500px;

    > p {
        color: #292828;
        font-size: 1.2em;
        margin-bottom: 5px;
    }

    > textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        max-height: 100px;
        min-height: 100px;
        padding: 1rem;    
        border-radius: 6px;
        background: #F0F0F0;
        color: #292828;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 5px;
    margin-top: 1.5rem;

    > :first-child {
        width: 125px;
        height: 40px;
        background: #40AD48;
        border-radius: 20px;
        border: none;
        font-weight: 700;
    }

    > :last-child {
        border: none;
        background: none;
        text-decoration: underline;
        color: #292828;
    }
`;