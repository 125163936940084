import AdminCategoriaNovo from '../../components/AdminCategoriaNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminCategoriaNovoPageContainer } from './styles';

const AdminCategoriaNovoPage = () => {

    return (
        <AdminCategoriaNovoPageContainer>
            <Header />
            <AdminCategoriaNovo />
            <Footer />
        </AdminCategoriaNovoPageContainer>
    );
};

export default AdminCategoriaNovoPage;