import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    width: 100%;

    /* > .checkbox {
        display: flex;
        position: relative;
        column-gap: 5%;
        width: 100%;
    } */

    > .operacao {
        width: 32%;
    }

    > .pdv {
        width: 32%;
    }

    > .titulo {
        width: 32%;
    }

    > .tipo {
        width: 49%;
    }

    > .quantidade {
        width: 49%;
    }

    > .marca {
        width: 49%;
    }

    > .pedido {
        width: 49%;
    }

    > .buttons-container {
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        > a {
            color: #292828;
        }
    }

    @media screen and (max-width: 768px) {
        > .importar-container {
            display: none;
        }
        
        > .operacao,
        > .pdv {
            width: 49%;
        }

        > .titulo,
        > .tipo {
            width: 49%;
        }

        > .quantidade,
        > .marca {
            width: 49%;
        }
    }

    @media screen and (max-width: 550px) {
        > .operacao,
        > .pdv,
        > .titulo,
        > .tipo,
        > .quantidade,
        > .marca {
            width: 100%;
        }
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    column-gap: 5px;

    > input {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        border: 1px solid #292828;
        
        &:checked {
            accent-color: #FDBC11;

        }
    }

    > label {
        color: #292828;
    }

    
    > span {
        top: 16px;
        left: -12px;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > input {
        background-color: #F0F0F0;
        border-radius: 6px;
        width: 100%;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
    }

    > span {
        top: 55px;
    }
`;

export const SelectContainer = styled.div`
   display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    > span {
        top: 55px;
    }

    .react-select-container {
        height: 44px;
        .react-select__control {
            height: 100%;
        }

        .react-select__multi-value {
            .react-select__multi-value__label {
                min-width: 50px;
            }
            
            .react-select__multi-value__remove {
                svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .react-select__clear-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            } 
        }

        .react-select__dropdown-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #292828;
                }   
            }
        }

        .react-select__option {
            color: #292828;
        }
    }
`;

export const ContinuarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;

    svg {
        path, polygon, rect, circle, ellipse {
            fill: #292828;
        }
    }
`;