import { AdminVolumeNovoContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroVolumeSvg from '../../assets/cadastro-volume.svg';
import VolumeForm from '../Forms/VolumeForm';

const AdminVolumeNovo = () => {

    return (
        <AdminVolumeNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroVolumeSvg}/> <h1>Cadastro de Volumes</h1> 
            </div> 
            <ContentContainer>
                <VolumeForm />
            </ContentContainer>
        </AdminVolumeNovoContainer>   
    );
};

export default AdminVolumeNovo;