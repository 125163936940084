import * as yup from 'yup';

const operacaoSchema = yup.object().shape({
    nome: yup.string().required('Campo obrigatório (nome).'),
    tipo: yup.string().required('Campo obrigatório (tipo).'),
    codigo: yup.string().required('Campo obrigatório (codigo).'),
    comercial: yup.object().typeError('Campo obrigatório. (comercial).').nullable().required('Campo obrigatório. (comercial).'),
    estado: yup.object().typeError('Campo obrigatório (estado).').nullable().required('Campo obrigatório (estado).'),
});

export default operacaoSchema;