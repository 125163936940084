import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer } from '../ModalRemoverPedidoCategoria/styles';
import { ContentContainer, ModalAguardandoPedidoContainer } from './styles';

const ModalAguardandoPedido = ({ setModalOpened, pedido, buscarPedido, levaAtual }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleAguardandoPedido = () => {
        api.patch(`/pedidos/${pedido.id}/status`, {status: 'AGUARDANDO', leva_id: levaAtual.id})
            .then((response) => {
                toast.success('Status do pedido alterado com sucesso!');
                buscarPedido();
                setModalOpened(false);
                navigate('/pedidos');
            }).catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível aprovar o pedido. Tente novamente mais tarde.')
                setModalOpened(false);
            });
    };

    return (
        <ModalAguardandoPedidoContainer>
             <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja alterar esse pedido para <span>Aguardando</span>?</p>
                    <ButtonsContainer>
                        <button onClick={handleAguardandoPedido}
                        >
                            SIM
                        </button>
                        <button onClick={() => setModalOpened(false)}>
                            NÃO
                        </button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalAguardandoPedidoContainer>
    )
};

export default ModalAguardandoPedido;