import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalImportarPedidoProdutosContainer, SelectContainer } from './styles';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Loading';

const ModalImportarPedidoProdutos = ({ setModalOpened, pedido, buscarPedido }) => {
    const [pedidosOptions, setPedidosOptions] = useState([]);
    const [pedidoSelecionado, setPedidoSelecionado] = useState('');

    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        const buscarPedidos = () => {
            setLoading(true);
            api.get('/pedidos')
                .then((response) => {
                    const pedidos = response.data.data.pedidos.filter((pedido) => String(pedido.usuario_id) === String(user.id));
                    const pedidosOptions = pedidos.map((pedido) => {
                        return {
                            value: pedido.id,
                            label: pedido.id + ' - ' + pedido.titulo
                        };
                    });
                    setPedidosOptions(pedidosOptions);
                    setLoading(false);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                });
        };
        buscarPedidos();
    }, []);

    const importarProdutos = () => {
        setLoading(true);
        api.post(`/pedidos/${pedido.id}`, { importar_pedido_id: pedidoSelecionado })
            .then((response) => {
                buscarPedido();
                setModalOpened(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
            });
    };

    return (
        <ModalImportarPedidoProdutosContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar produtos do pedido</h1>
                        <SelectContainer>
                            <Select 
                                id="pedidos"
                                className='produto-select-container'
                                classNamePrefix='produto-select'
                                options={pedidosOptions}
                                onChange={(e) => setPedidoSelecionado(e.value)}
                                placeholder="Selecione o pedido"
                            />
                        </SelectContainer>
                        <ButtonsContainer>
                            <button
                                type='button'
                                onClick={importarProdutos}
                                className='importar'
                                disabled={!pedidoSelecionado}
                            >importar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarPedidoProdutosContainer>
    );
};

export default ModalImportarPedidoProdutos;