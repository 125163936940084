import { AdminOperacaoContainer, ContentContainer, PesquisarInputContainer, SelectContainer, TableContainer, TotalOperacoesContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroOperacaoSvg from '../../assets/cadastro-operacao.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import { Link, useNavigate } from 'react-router-dom';
import CustomTable from '../CustomTable';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/Auth';
import api from '../../services/api';
import OperacaoTr from '../Trs/OperacaoTr';
import { paginador } from '../../utils/pager';
import Pager from '../Pager';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';

const AdminOperacao = () => {
    const [operacoes, setOperacoes] = useState([]);
    
    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');
    const [operacoesFiltradas, setOperacoesFiltradas] = useState([]);
    const [operacoesPaginadas, setOperacoesPaginadas] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [operacoesLoaded, setOperacoesLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (operacoesLoaded && geosLoaded){
            setLoading(false);
        };
    }, [operacoesLoaded, geosLoaded]);

    const buscarOperacoes = () => {
        setOperacoesLoaded(false);
        api.get('/operacoes')
            .then((response) => {
                setOperacoes(response.data.data.operacoes);
                setOperacoesLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarOperacoes();
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const operacoesFiltradas = operacoes.filter((operacao) => {
            const filtroId = pesquisa ? String(operacao.id) === String(pesquisa) : true;
            const filtroNome = operacao.nome.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroTipo = operacao.tipo.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroGeo = geoSelecionada ? String(operacao.geo_id) === String(geoSelecionada.value) : true;

            return (filtroNome || filtroId || filtroTipo) && filtroGeo;
        });
        setOperacoesFiltradas(operacoesFiltradas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[operacoes, pesquisa, geoSelecionada]);

    useEffect(() => {
        const paginado = paginador(operacoesFiltradas, paginaAtual)
        setOperacoesPaginadas(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operacoesFiltradas, paginaAtual]);
    
    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
        let name = `operacoes_${formatedDate}.xlsx`;
        const fields = ['id','nome','estado','codigo','tipo','comercial','geo_nome','data_de_criacao','data_de_atualizacao'];
        const dadosFiltrados = operacoes.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'operacoes');
        XLSX.writeFile(wb,name);
    };

    return (
        <AdminOperacaoContainer>
            <div className='header'>
                <ReactSVG src={CadastroOperacaoSvg}/> <h1>Cadastro de Operação</h1> 
            </div>
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='header'>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-operação'
                                placeholder='Pesquisar operação'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-operação'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                        {['ac', 'admin'].includes(user?.permissao) && (
                            <Link to='/administrativo/operacao/novo' className='novo'>
                                Novo
                            </Link>
                        )}
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalOperacoesContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${operacoesFiltradas.length} ${operacoesFiltradas.length === 1 ? 'operação' : 'operacoes'}`}
                    </TotalOperacoesContainer>
                    <TableContainer>
                        <CustomTable header={['Id', 'Nome da Operação', 'Comercial', 'Tipo', 'Geo', 'Código', ' ']} message='Nenhuma operação encontrada.'>
                            {operacoesPaginadas.map((operacao) => {
                                return <OperacaoTr key={operacao.id} operacao={operacao} />
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminOperacaoContainer>
    );
};

export default AdminOperacao;