import AdminUsuarioNovo from '../../components/AdminUsuarioNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminUsuarioNovoPageContainer } from './styles';

const AdminUsuarioNovoPage = () => {

    return (
        <AdminUsuarioNovoPageContainer>
            <Header />
            <AdminUsuarioNovo />
            <Footer />
        </AdminUsuarioNovoPageContainer>
    );
};

export default AdminUsuarioNovoPage;