import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import { useAuth } from '../../../providers/Auth';

const VolumeTr = ({ volume }) => {
    const { user } = useAuth();
    return (
        <Tr>
            <td>
                <label>Id</label>
                #{volume.id}
            </td>
            <td>
                <label>Bebida</label>
                {volume.bebida}
            </td>
            <td>
                <label>Volume</label>
                {volume.volume}
            </td>
            <td>
                {user.permissao === 'ac' && (
                    <>
                    <label>Geo</label>
                    {volume.geo}
                    </>
                )}
            </td>
            <td>
                <label>Criação</label>
                {volume.data_de_criacao}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/volume/editar/${volume.id}`}>
                    editar <ReactSVG src={EditarSvg} />
                </Link>
            </td>
        </Tr>
    );
};

export default VolumeTr;