import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalRemoverPedidoCategoriaContainer } from './styles';

const ModalRemoverPedidoCategoria = ({ setModalOpened, categoria, handleRemoverCategoria }) => {

    return (
        <ModalRemoverPedidoCategoriaContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja <span>remover</span> essa categoria?</p>
                    <span>todo produtos vínculados a ela serão removidos</span>
                    <ButtonsContainer>
                        <button 
                            onClick={() => {
                                handleRemoverCategoria(categoria);
                                setModalOpened(false);
                            }}
                        >
                            SIM
                        </button>
                        <button onClick={() => setModalOpened(false)}>
                            NÃO
                        </button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalRemoverPedidoCategoriaContainer>
    )
};

export default ModalRemoverPedidoCategoria;