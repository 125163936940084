import AdminUsuario from '../../components/AdminUsuario';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminUsuarioPageContainer } from './styles';

const AdminUsuarioPage = () => {

    return (
        <AdminUsuarioPageContainer>
            <Header />
            <AdminUsuario />
            <Footer />
        </AdminUsuarioPageContainer>
    );
};

export default AdminUsuarioPage;