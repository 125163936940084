import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { useAuth } from '../../../providers/Auth';
import vinculoVolumeCategoriaSchema from '../../../schemas/VinculosVolumeCategoria/vinculoVolumeCategoriaSchema';
import { Form, SalvarButton, SelectContainer } from './styles';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';

const VinculoVolumeCategoriaForm = () => {
    const { handleSubmit, formState: { errors }, setValue,reset, control } = useForm({
        resolver: yupResolver(vinculoVolumeCategoriaSchema)
    });

    const [bebidasOptions, setBebidasOptions] = useState([]);
    const [volumesOptions, setVolumesOptions] = useState([]);
    const [categoriasOptions, setCategoriasOptions] = useState([]);

    const [bebidaSelecionada, setBebidaSelecionada] = useState(null);

    const { logout } = useAuth();
    const navigate = useNavigate();

    const [bebidasLoaded, setBebidasLoaded] = useState(false);
    const [volumesLoading, setVolumesLoading] = useState(false);

    const buscarBebidas = () => {
        api.get(`/bebidas`)
            .then(response => {
                const bebidaOptions = response.data.data.bebidas.map(bebida => {
                    return {
                        value: bebida.id,
                        label: bebida.nome
                    };
                });
                setBebidasOptions(bebidaOptions);
                setBebidasLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar bebida!');
            });
    };

    const buscarVolumes = () => {
        setVolumesLoading(true);
        api.get(`/bebida-volumes/bebida/${bebidaSelecionada.value}`)
            .then(response => {
                const volumeOptions = response.data.data.volumes.map(volume => {
                    return {
                        value: volume.id,
                        label: volume.volume
                    };
                });
                setVolumesOptions(volumeOptions);
                setVolumesLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar volume!');
                setVolumesLoading(false);
            });
    };

    const buscarCategorias = () => {
        api.get(`/categorias-bebidas`)
            .then(response => {
                const categoriaOptions = response.data.data.categorias.map(categoria => {
                    return {
                        value: categoria.id,
                        label: categoria.nome
                    };
                });
                setCategoriasOptions(categoriaOptions);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar categoria!');
            });
    };

    useEffect(() => {
        buscarBebidas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (bebidaSelecionada) {
            buscarVolumes();
            buscarCategorias();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bebidaSelecionada])

    const onSubmitFunction = (data) => {
        const dados = {
            volume_id: data.volume_id.value,
            categorias_ids: data.categorias_ids.map(categoria => categoria.value)
        };
        api.post('/bebida-volume-categorias', dados)
            .then(response => {
                toast.success('Vínculo criado com sucesso!');
                setBebidaSelecionada('');
                setValue('bebida_id', null);
                setValue('volume_id', null);
                setValue('categorias_ids', null);
                setVolumesOptions([]);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                if (error.response.status === 409) {
                    toast.error('Vínculo já existente!');
                    return;
                };
                toast.error('Erro ao criar vínculo!');
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmitFunction)}>
            <SelectContainer className='bebida'>
                <label>Bebida</label>
                <Controller
                    name='bebida_id'
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={bebidasOptions}
                            defaultValue={null}
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isLoading={!bebidasLoaded}
                            onChange={(value) => {
                                setVolumesOptions([]);
                                setValue('volume_id', null);
                                setValue('categorias_ids', null);
                                setValue('bebida_id', value)
                                setBebidaSelecionada(value)
                            }}
                        />
                    )}
                />
                {errors.bebida_id && <span className='input-error'>{errors.bebida_id.message}</span>}
            </SelectContainer>
            <SelectContainer className='volume'>
                <label>Volume</label>
                <Controller
                    name='volume_id'
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={volumesOptions}
                            isClearable
                            isLoading={volumesLoading}
                            defaultValue={null}
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isDisabled={!bebidaSelecionada}
                        />
                    )}
                />
                {errors.volume_id && <span className='input-error'>{errors.volume_id.message}</span>}
            </SelectContainer>
            <SelectContainer className='categoria'>
                <label>Categoria</label>
                <Controller
                    name='categorias_ids'
                    control={control}
                    render={({ field }) => (
                        <Select
                            {...field}
                            options={categoriasOptions}
                            isClearable
                            defaultValue={null}
                            className='react-select-container'
                            classNamePrefix='react-select'
                            isMulti
                            isDisabled={!bebidaSelecionada}
                        />
                    )}
                />
                {errors.categorias_ids && <span className='input-error'>{errors.categorias_ids.message}</span>}
            </SelectContainer>
            <div className='buttons-container'>
                <Link to='/administrativo/vinculo'>{'< Voltar'}</Link>
                <SalvarButton
                    type='submit'
                >
                    <SalvarSvg /> Salvar
                </SalvarButton>
            </div>
        </Form>
    );
};

export default VinculoVolumeCategoriaForm;