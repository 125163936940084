import { ButtonsContainer, NotFoundPageContainer } from './styles';
import { ReactSVG } from 'react-svg';
import NotFoundSvg from '../../assets/404.svg';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/Auth';

const NotFoundPage = () => {
    const { loadingUser, user } = useAuth();
    const navigate = useNavigate();

    if(loadingUser){
        if(!user){
            return <Navigate to='/'/>
        } 
    } 

    return (
        <NotFoundPageContainer>
            <h1>gestor de <span>cardápio</span></h1>
            <ReactSVG src={NotFoundSvg} />
            <h2>Página não encontrada {':('}</h2>
            <p>A página que você tentou acessar está indisponível ou não existe.</p>
            <ButtonsContainer>
                <button 
                    className='anterior'
                    onClick={() => navigate(-1)} 
                >
                    Retornar à página anterior
                </button>
                <button 
                    className='inicial'
                    onClick={() => navigate('/')}
                >
                    Ir para a página inicial
                </button>
            </ButtonsContainer>
        </NotFoundPageContainer>
    );
};

export default NotFoundPage;