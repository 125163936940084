import styled from 'styled-components';

export const PedidosPorComercialContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;

    > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > h1 {
            color: #292828;
            font-weight: 700;
            font-size: 1.2em;
        }

        > button {
            display: flex;
            border: none;
            background: none;
            color: #FDBC11;
            text-decoration: underline;
            column-gap: 5px;
            font-weight: 500;

            svg {
                path, polygon, rect, circle, ellipse {
                    fill: #FDBC11;
                }
            }
        }
    }

    @media screen and (max-width: 985px) {
        > .header > h1 {
            font-size: 1em;
        }

        > .header > button {
            font-size: 0.8em;
        }
    }
`;

export const FiltrosContainer = styled.div`
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
    margin: 1rem 0;

    > .extrair {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        width: 150px;
        height: 40px;
        
        padding: 10px 20px;
        background-color: transparent;
        color: #292828;
        border: 1px solid #292828;
        border-radius: 20px;

        svg {
            path, polygon, rect, circle, ellipse {
                fill: #292828;
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .extrair {
            font-size: 0.7em;
            padding: 10px 15px;
            height: 30px;
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
`;

export const PesquisarInputContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 10px;
    background: #F0F0F0;
    border-radius: 22px;
    width: 300px;
    
    > input {
        color: #292828;
        border: none;
        background: transparent;
        padding: 10px;
        padding-left: 20px;
        width: 100%;
        height: 40px;

        :focus {
            outline: none;
        }
    }

    > label {
        cursor: text;
    }
    
    @media screen and (max-width: 840px) {
        width: 100%;
        max-width: 400px;
    }

    @media screen and (max-width: 768px) {
        > input {
            font-size: 0.7em;
            height: 30px;
        }

        > label {
            svg {
                width: 12px;
                height: 12px;
            }    
        }
    }
`;

export const TableContainer = styled.div`
    display: ${({tableOpened}) => tableOpened ? 'block' : 'none'};
    > table {
        width: 100%;
        border-collapse: collapse;

        > thead > tr {
            > th {
                border-bottom: 1px solid rgba(112, 112, 112, 0.7);
                padding-left: 10px;
                height: 50px;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 5px;
                    color: #292828;
                    font-weight: 600;
                }

                > .aguardando {
                    svg {
                        path, polygon, rect, circle, ellipse {
                            fill: #F16623;
                        }
                    }
                }

                > .revisando {
                    svg {
                        path, polygon, rect, circle, ellipse {
                            fill: #FDBC11;
                        }
                    }
                }

                > .aprovado {
                    svg {
                        path, polygon, rect, circle, ellipse {
                            fill: #40AD48;
                        }
                    }
                }
            }

            > th:first-child > div,
            > th:nth-child(2) > div {
                justify-content: flex-start;
            }
        }
    }

    @media screen and (max-width: 985px) {
        > table > thead > tr > th {
            height: 40px;
            > div {
                font-size: 0.8em;
    
                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        } 
    }

    @media screen and (max-width: 840px) {
        > table {
            > thead {
                display: none;
            }
        }
    }
`;

export const TrNotFound = styled.tr`
    > td {
        color: #292828;
        height: 50px;
        padding-left: 10px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);
    }
`;