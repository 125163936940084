import AdminCategoriaEditar from '../../components/AdminCategoriaEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminCategoriaEditarPageContainer } from './styles';

const AdminCategoriaEditarPage = () => {

    return (
        <AdminCategoriaEditarPageContainer>
            <Header />
            <AdminCategoriaEditar />
            <Footer />
        </AdminCategoriaEditarPageContainer>
    );
};

export default AdminCategoriaEditarPage;