import { TotalPedidosAnoContainer } from './styles';
import { useState } from 'react';
import { Bar } from "react-chartjs-2";
import MenuPedidosAmareloSvg from '../../../assets/menu-pedidos-amarelo.svg';

import { 
    Chart as ChartJS, 
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend 
} from 'chart.js';
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TotalPedidosAno = ({ pedidos, anoSelecionado }) => {
    const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
    const [dadosGrafico, setDadosGrafico] = useState([]);

    useEffect(() => {
        let graficoDados = [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ];
        pedidos.forEach((elem) => {
            const [,mes] = elem.data_de_criacao.split(' ')[0].split('/');
            graficoDados[mes-1]++;
        });
        setPedidosFiltrados(pedidos);
        setDadosGrafico(graficoDados);
    },[pedidos]);

    return (
        <TotalPedidosAnoContainer>
            <div className="header">
                <div>
                    <h3>Total de pedidos</h3>
                    <p>Mês a mês</p>
                </div>
            </div>
            <div className="body">
                <div className='grafico-container'>
                    <Bar 
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    display:false,
                                },
                                title: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {grid: {display: false}},
                                y: {grid: {display: false}}
                            }
                        }}
                        data={{
                            labels: ['jan','fev','mar','abr','mai','jun','jul','ago','set','out','nov','dez'],
                            datasets: [
                                {
                                    borderRadius: {
                                        topLeft: 2,
                                        topRight: 2,
                                    },
                                    label: 'grafico',
                                    data: dadosGrafico,
                                    backgroundColor: '#FDBC11'
                                }
                            ],
                        }}
                    />
                </div>
                <div className="info-container">
                    <ReactSVG src={MenuPedidosAmareloSvg}/>
                    <h3>{pedidosFiltrados.length}</h3>
                    <p><strong>total</strong> de pedidos feitos {anoSelecionado ? `em ${anoSelecionado}` : ''}</p>
                </div>
            </div>
        </TotalPedidosAnoContainer>
    )
};

export default TotalPedidosAno;