import styled from 'styled-components';

export const PedidoLevaContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100px;

    margin: 2rem 0;
    padding: 1.5rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > h2 {
        font-weight: 600;
        color: #FDBC11;
        width: 10%;
        margin: 0 10px;
        text-align: center;

        > p {
            color: #292828;
            margin-bottom: 5px;

            > span {
                font-weight: 700;
                color: #292828;
            }
        }
    }

    > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 90px;
        padding: 0 10px;
        border-right: 1px solid #E9E9E9;

        > h1 {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            color: #292828;
            font-size: 0.9em;
            height: 50px;
            text-align: center;

            svg {
                height: 20px;
            }
        }

        > p {
            text-align: center;
            font-weight: 700;
            font-size: 1.1rem;
            color: #292828;
        }
    }

    > .item:last-child {
        border-right: none;
    }

    > .ver-pedidos {
        border: none;
        color: #292828;
        font-weight: 600;
        background: #FDBC11;
        border-radius: 30px;
        width: 120px;
        min-width: 120px;
        height: 35px;
        margin: 0 10px;
    }

    @media (max-width: 840px) {
        flex-direction: column;
        height: auto;
        padding: 1rem 1rem;
        row-gap: 1rem;

        > h2 {
            width: 100%;
            font-size: 1.2rem;
            text-align: center;

            > p {
                margin-bottom: 0;
            }
        }

        > .item {
            flex-direction: row;
            justify-content: space-between;
            column-gap: 10px;
            width: 100%;
            border-right: none;
            border-bottom: 1px solid #E9E9E9;
            height: 70px;
            
            > h1 {
                height: 30px;
            }

            > p {
                font-size: 1.1rem;
            }
        }

        > .ver-pedidos {
            width: 100%;
        }
        
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;

    > :last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color: #292828;
        text-decoration: underline;
        font-size: 0.8em;
    }
`;