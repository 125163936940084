import AdminVinculoVolumeCategoriaNovo from '../../components/AdminVinculoVolumeCategoriaNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminVinculoVolumeCategoriaNovoPageContainer } from './styles';

const AdminVinculoVolumeCategoriaNovoPage = () => {

    return (
        <AdminVinculoVolumeCategoriaNovoPageContainer>
            <Header />
            <AdminVinculoVolumeCategoriaNovo />
            <Footer />
        </AdminVinculoVolumeCategoriaNovoPageContainer>
    );
};

export default AdminVinculoVolumeCategoriaNovoPage;