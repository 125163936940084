import { AdminGeoNovoContainer, ContentContainer } from './styles';
import { ReactComponent as CadastroGeoSvg } from '../../assets/cadastro-geo.svg';
import GeoForm from '../Forms/GeoForm';

const AdminGeoNovo = () => {

    return (
        <AdminGeoNovoContainer>
             <div className='header'>
                <CadastroGeoSvg /> <h1>Cadastrar Nova Geo</h1> 
            </div>
            <ContentContainer>
                <GeoForm />    
            </ContentContainer>
        </AdminGeoNovoContainer>
    );
};

export default AdminGeoNovo;