import AdminVolumeNovo from '../../components/AdminVolumeNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminVolumeNovoPageContainer } from './styles';

const AdminVolumeNovoPage = () => {

    return (
        <AdminVolumeNovoPageContainer>
            <Header />
            <AdminVolumeNovo />
            <Footer />
        </AdminVolumeNovoPageContainer>
    );
};

export default AdminVolumeNovoPage;