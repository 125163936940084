import styled from 'styled-components';

export const NotFoundPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    width: 100%;
    min-height: 100vh;

    > h1 {
        text-align: center;
        color: #292828;
        font-size: 4em;
        width: 500px;
        line-height: 1em;
        font-weight: 300;

        > span {
            color: #FDBC11;
            font-size: 1em;
            font-weight: bold;
        }
    }

    > h2 {
        font-size: 2em;
        color: #fdbc11;
        font-weight: 700;
    }

    > p {
        color: #292828;
    }

    svg {
        height: 40vh;
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    column-gap: 15px;
    > button {
        width: 260px;
        height: 42px;
        border-radius: 20px;
        border: none;
        background: none;
        color: #292828;
        font-weight: 700;
    }

    > .anterior {
        background: #fdbc11;
    }

    > .inicial {
        border: 1px solid #292828;
    }
`;