import styled from 'styled-components';

export const DownloadPedidosButtonContainer = styled.div`

    > .loading {
        display: 'flex';
        align-items: 'center';
        justify-content: 'center';
        flex-direction: 'column';
        min-width: '120px';
        height: '100%';
        
        > div {
            color: #292828;
            text-align: center;

            span {
                color: #292828;
                font-weight: 600;
            }
        }
    }

    > button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: none;
        color: #292828;
        font-weight: 700;
        column-gap: 5px;
        background: #FDBC11;
        padding: 8px 20px;
        border-radius: 30px;
        font-size: 0.9em;

        svg {
            fill: #292828;
            path {
                fill: #292828;
            }
        }
    }
`;