import { useEffect, useState } from "react";
import { PagerContainer } from "./styles"

const Pager = ({currentPage, setCurrentPage, totalPages}) => {
    const [value, setValue] = useState(1);

    useEffect(() => {
        setValue(currentPage);
    }, [currentPage])
    
    return (
        <PagerContainer>
            <button 
                className="page-button"
                onClick={() => {
                    if(currentPage - 1 > 0){
                        setCurrentPage(currentPage - 1)
                    }
                }}
            >
                {'<Anterior'}
            </button>
            <div className="paginador-input-container">
                <input
                    value={value}
                    onChange={(e) => {
                        if(!e.target.value) {
                            setValue(currentPage);
                        } else if(Number(e.target.value) <= Number(totalPages) && Number(e.target.value) > 0){  
                            setValue(Number(e.target.value));
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setCurrentPage(value);
                        };
                    }}
                /> 
                <p>
                    de 
                </p>
                <p style={{marginLeft: '10px'}}>
                    {totalPages}
                </p>
            </div>
            <button
                className="page-button"
                onClick={() => {
                    if(currentPage < totalPages){
                        setCurrentPage(currentPage + 1)
                    }
                }}
            >
                {'Proximo>'}
            </button>
        </PagerContainer>
    )
};

export default Pager;