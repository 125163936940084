import { useState } from 'react';
import { Tr } from './styles';
import { useEffect } from 'react';
import { useAuth } from '../../../providers/Auth';
import { ReactSVG } from 'react-svg';
import HomeAguardandoSvg from '../../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../../assets/home-aprovados.svg';

const PedidosPorPdvTr = ({ pdv, pedidos, levaAtual, levasAtivas, geoSelecionada, anoSelecionado }) => {
    const [pedidosPdv, setPedidosPdv] = useState([]);
    const [pedidosPdvLevaAtual, setPedidosPdvLevaAtual] = useState([]);
    const [pedidosPdvAguardando, setPedidosPdvAguardando] = useState([]);
    const [pedidosPdvRevisar, setPedidosPdvRevisar] = useState([]);
    const [pedidosPdvAprovado, setPedidosPdvAprovado] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        const pedidosPdv = pedidos.filter(pedido => String(pedido.ponto_de_venda_id) === String(pdv.id));
        const pedidosAguardando = pedidosPdv.filter(pedido => pedido.status === 'AGUARDANDO');
        const pedidosRevisar = pedidosPdv.filter(pedido => pedido.status === 'REVISAR');
        const pedidosAprovado = pedidosPdv.filter(pedido => pedido.status === 'APROVADO');

        setPedidosPdv(pedidosPdv);
        setPedidosPdvAguardando(pedidosAguardando);
        setPedidosPdvRevisar(pedidosRevisar);
        setPedidosPdvAprovado(pedidosAprovado);

        if (['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada) {
            const pedidosLevasAtivas = levasAtivas.reduce((acc, leva) => {
                if (!leva.data_encerramento) {
                    return acc.concat(pedidosPdv.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                };
            },  []);
            setPedidosPdvLevaAtual(pedidosLevasAtivas);
        } else if (levaAtual) {
            const pedidosLevaAtual = pedidosPdv.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
            setPedidosPdvLevaAtual(pedidosLevaAtual);
        } else {
            setPedidosPdvLevaAtual([]);
        }
    },[pedidos])

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{pdv.id}
            </td>
            <td>
                <label>PDV</label>
                {pdv.nome}
            </td>
            <td>
                <label>
                    Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''}
                </label>
                {pedidosPdv.length}
            </td>
            <td>
                <label>
                    {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                </label>
                {pedidosPdvLevaAtual.length}
            </td>
            <td>
                <label className='aguardando'>
                    Aguardando <ReactSVG src={HomeAguardandoSvg}/>
                </label>
                {pedidosPdvAguardando.length}
            </td>
            <td>
                <label className='revisando'>
                    Revisando <ReactSVG src={HomeRevisarSvg}/>
                </label>
                {pedidosPdvRevisar.length}
            </td>
            <td>
                <label className='aprovado'>
                    Aprovado <ReactSVG src={HomeAprovadosSvg}/>
                </label>
                {pedidosPdvAprovado.length}
            </td>
        </Tr>
    )
};

export default PedidosPorPdvTr;