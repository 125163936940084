import { AdminBebidaContainer, ContentContainer, PesquisarInputContainer, SelectContainer, TableContainer, TotalBebidasContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroBebidasSvg from '../../assets/cadastro-bebidas.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Pager from '../Pager';
import CustomTable from '../CustomTable';
import BebidaTr from '../Trs/BebidaTr';
import { useAuth } from '../../providers/Auth';
import { paginador } from '../../utils/pager';
import api from '../../services/api';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';

const AdminBebida = () => {
    const [bebidas, setBebidas] = useState([]);

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');
    const [bebidasFiltradas, setBebidasFiltradas] = useState([]);
    const [bebidasPaginadas, setBebidasPaginadas] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [bebidasLoaded, setBebidasLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (bebidasLoaded && geosLoaded){
            setLoading(false);
        };
    }, [bebidasLoaded, geosLoaded]);

    const buscarBebidas = () => {
        setBebidasLoaded(false);
        api.get('/bebidas')
            .then((response) => {
                setBebidas(response.data.data.bebidas);
                setBebidasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                if (error.response?.status === 403) {
                    navigate('/pedidos');
                };
            });
    };
    
    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarBebidas();
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const bebidasFiltradas = bebidas.filter((bebida) => {
            const filtroId = pesquisa ? String(bebida.id) === String(pesquisa) : true;
            const filtroNome = bebida.nome.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroMarca = bebida.marca.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroGeo = geoSelecionada ? String(bebida.geo_id) === String(geoSelecionada.value) : true;

            return (filtroNome || filtroId || filtroMarca) && filtroGeo;
        });
        setBebidasFiltradas(bebidasFiltradas);
    }, [pesquisa, bebidas, geoSelecionada]);

    useEffect(() => {
        const paginado = paginador(bebidasFiltradas, paginaAtual)
        setBebidasPaginadas(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    }, [bebidasFiltradas, paginaAtual]);

    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
        let name = `bebidas_${formatedDate}.xlsx`;
        const fields = ['id','nome','segmento','pack','marca','marca_consolid','geo','data_de_criacao','data_de_atualizacao'];
        const dadosFiltrados = bebidas.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'bebidas');
        XLSX.writeFile(wb,name);
    };

    return (
        <AdminBebidaContainer>
            <div className='header'>
                <ReactSVG src={CadastroBebidasSvg}/> <h1>Cadastro de Bebidas</h1> 
            </div> 
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='header'>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-bebida'
                                placeholder='Pesquisar bebida'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-bebida'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                        <Link to='/administrativo/bebida/novo' className='novo'>
                            Novo
                        </Link>
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalBebidasContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${bebidasFiltradas.length} ${bebidasFiltradas.length === 1 ? 'bebida' : 'bebidas'}`}
                    </TotalBebidasContainer>
                    <TableContainer>
                        <CustomTable 
                            header={[
                                'Id', 
                                'Nome', 
                                'Segmento', 
                                'Marca', 
                                `${user.permissao === 'ac' ? 'Geo' : ''}`,
                                ' '
                            ]} 
                            message='Nenhuma bebida encontrada.'
                        >
                            {bebidasPaginadas.map((bebida) => {
                                return <BebidaTr key={bebida.id} bebida={bebida}/>
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminBebidaContainer>
    );
};

export default AdminBebida;