import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;

    > .buttons-container {
        display: flex;
        align-items: center;
        column-gap: 10px;
        > a {
            color: #292828;
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > input {
        background-color: #F0F0F0;
        border-radius: 6px;
        width: 100%;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
    }
`;

export const SalvarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;
`;