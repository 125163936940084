import styled from 'styled-components';

export const ModalAdicionarEscadaBebidaComparadorContainer = styled.div`

`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 2rem;
    min-width: 350px;
    width: 40vw;
    max-width: 500px;

    > h1 {
        color: #FDBC11;
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    > .buttons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: 100%;
    }
`;

export const SelectContainer = styled.div`
   display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }

    > span {
        top: 55px;
    }

    > .react-select-container {
        .react-select__multi-value {
            .react-select__multi-value__label {
                min-width: 50px;
            }
            
            .react-select__multi-value__remove {
                > svg {
                    path, polygon, rect, circle, ellipse {
                        fill: #292828;
                    }
                }
            }
        }

        .react-select__clear-indicator {
            display: none;
        }

        .react-select__option {
            color: #292828;
        }
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > div {
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        width: 100%;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
        border: 1px solid hsl(0, 0%, 80%);

        > label {
            color: #292828;
        }

        > input {
            height: 100%;
            width: 100%;
            border: none;
            padding-left: 2px;
            color: #292828;
        }

    }
`;

export const SalvarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;
    
    &:disabled {
        background-color: #a0a0a0;
        cursor: not-allowed;
    }
`;