import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalEncerrarLevaContainer } from './styles';

const ModalEncerrarLeva = ({setModalOpened, handleEncerrarLeva}) => {

    return (
        <ModalEncerrarLevaContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja <span>encerrar</span> a leva atual?</p>
                    <ButtonsContainer>
                        <button 
                            onClick={() => {
                                handleEncerrarLeva();
                                setModalOpened(false);
                            }}
                        >
                            SIM
                        </button>
                        <button onClick={() => setModalOpened(false)}>
                            NÃO
                        </button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalEncerrarLevaContainer>
    );
};

export default ModalEncerrarLeva;