import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PedidosVisualizar from '../../components/PedidosVisualizar';
import { PedidosVisualizarPageContainer } from './styles';

const PedidosVisualizarPage = () => {

    return (
        <PedidosVisualizarPageContainer>
            <Header />
            <PedidosVisualizar />
            <Footer />
        </PedidosVisualizarPageContainer>
    )
};

export default PedidosVisualizarPage;