import { ObservacoesContainer, PedidosEditarContainer } from './styles';
import { ReactSVG } from 'react-svg';
import NovoPedidoSvg from '../../assets/novo-pedido.svg';
import { useEffect, useState } from 'react';
import Loading from '../Loading';
import PedidoIncompleto from '../PedidoIncompleto';
import { useAuth } from '../../providers/Auth';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../services/api';
import { MdOutlineRateReview } from 'react-icons/md';

const PedidosEditar = () => {
    const [pedidoRevisar, setPedidoRevisar] = useState('');
    const [observacoes, setObservacoes] = useState([]);

    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const { pedido_id } = useParams();

    const [pedidoLoaded, setPedidoLoaded] = useState(false);
    const [observacoesLoaded, setObservacoesLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (pedidoLoaded && observacoesLoaded) {
            setLoading(false);
        };
    }, [pedidoLoaded, observacoesLoaded]);

    const buscarPedidoRevisar = () => {
        setPedidoLoaded(true);
        api.get(`/pedidos/${pedido_id}`)
            .then((response) => {
                const pedido = response.data.data.pedido;
                if (pedido.status !== 'REVISAR' || (pedido.usuario_id !== user.id && user.permissao !== 'gv')){
                    navigate('/pedidos');
                    return;
                };
                setPedidoRevisar(pedido);
                setPedidoLoaded(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                if (error.response?.status === 404){
                    navigate('/pedidos');
                };
            });
    };

    const buscarPedidoObservacoes = () => {
        setObservacoesLoaded(true);
        api.get(`/pedido-observacoes/${pedido_id}`)
            .then((response) => {
                const observacoes = response.data.data;
                setObservacoes(observacoes);
                setObservacoesLoaded(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
                if (error.response?.status === 404){
                    navigate('/pedidos');
                };
            });
    };

    useEffect(() => {
        buscarPedidoRevisar();
        buscarPedidoObservacoes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PedidosEditarContainer>
            <div className='header'>
                <ReactSVG src={NovoPedidoSvg}/> <h1>Editar pedido</h1>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <>
                <ObservacoesContainer id='observacoes'>
                    <h2>
                        <MdOutlineRateReview size={20} fill='#FDBC11' /> Observações
                    </h2>
                    <div className='observacoes'>
                        {observacoes.map((observacao) => (
                            <div key={observacao.id} className='item'>
                                <div className='overflow'>
                                   <p>{observacao.observacao}</p> 
                                </div>
                                <span>{observacao.data_de_criacao}</span>
                            </div>
                        ))}
                    </div>
                </ObservacoesContainer>
                <PedidoIncompleto pedido={pedidoRevisar} buscarPedidoIncompleto={buscarPedidoRevisar}/>
                </>
            )}
        </PedidosEditarContainer>
    );
};

export default PedidosEditar;