import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import api from '../../../services/api';
import CustomModal from '../CustomModal';
import { ButtonsContainer, ContentContainer, ModalCancelarPedidoContainer } from './styles';

const ModalCancelarPedido = ({ buscarPedido, setModalOpened }) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleCancelarPedido = () => {
        api.delete(`/pedidos`)
            .then((response) => {
                buscarPedido();
                setModalOpened(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível cancelar o pedido. Tente novamente mais tarde.')
                setModalOpened(false);
            });
    };

    return (
        <ModalCancelarPedidoContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <p>Certeza que deseja <span>cancelar</span> o pedido?</p>
                    <span>Todas as informações serão excluidas</span>
                    <ButtonsContainer>
                        <button onClick={handleCancelarPedido}
                        >
                            SIM
                        </button>
                        <button onClick={() => setModalOpened(false)}>
                            NÃO
                        </button>
                    </ButtonsContainer>
                </ContentContainer>
            </CustomModal>
        </ModalCancelarPedidoContainer>
    )
};

export default ModalCancelarPedido;