import AdminBebida from '../../components/AdminBebida';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminBebidaPageContainer } from './styles';

const AdminBebidaPage = () => {

    return (
        <AdminBebidaPageContainer>
            <Header />
            <AdminBebida />
            <Footer />
        </AdminBebidaPageContainer>
    );
};

export default AdminBebidaPage;