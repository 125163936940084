import { Link, useNavigate } from 'react-router-dom';
import { AdministrativoButton, AdministrativoNav, CloseMenuButton, HeaderContainer, LogoutButton, MenuAdministrativoMobile, MenuButton, MenuMobile, UserInfoContainer } from './styles';
import { ReactSVG } from 'react-svg';
import { ReactComponent as PedidosAmareloSvg } from '../../assets/menu-pedidos-amarelo.svg';
import { ReactComponent as AdminAmareloSvg } from '../../assets/menu-admin-amarelo.svg';
import { ReactComponent as ArrowDownSvg } from '../../assets/arrow-down.svg';
import MenuGeoSvg from '../../assets/menu-geo.svg';
import ComercialSvg from '../../assets/comercial.svg';
import MenuOperacaoSvg from '../../assets/menu-operacao.svg';
import MenuUsuarioSvg from '../../assets/menu-usuario.svg';
import MenuPontoDeVenda from '../../assets/menu-ponto-venda.svg';
import MenuCategorias from '../../assets/menu-categorias.svg';
import MenuBebidas from '../../assets/menu-bebidas.svg';
import MenuVolumes from '../../assets/menu-volumes.svg';
import MenuVinculo from '../../assets/menu-vinculo.svg';
import DashSvg from '../../assets/dash.svg';
import EscadaPrecoSvg from '../../assets/escada-preco.svg';
import { useAuth } from '../../providers/Auth';
import { useState } from 'react';
import LogoutSvg from '../../assets/logout.svg';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';

const Header = ({ page }) => {
    const [administrativoOpen, setAdministrativoOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [administrativoMobileOpen, setAdministrativoMobileOpen] = useState(false);
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <h1>gestor de <span>cardápio</span></h1>
            {['ac', 'admin'].includes(user?.permissao) && (
                <Link to='/dashboard' className={page === 'dashboard' ? 'hovered' : ''}>
                    <ReactSVG src={DashSvg} style={{zIndex: 2}}/> <p>Dashboard</p>
                </Link>
            )}            
            <Link to='/pedidos' className={page === 'pedidos' ? 'hovered' : ''}>
                <PedidosAmareloSvg /> <p>Pedidos</p>
            </Link>
            
            {(['ac', 'admin', 'geo'].includes(user?.permissao) || (['gv', 'gc'].includes(user?.permissao) && user?.geo_id === 3)) && (
                <AdministrativoButton onMouseEnter={() => setAdministrativoOpen(true)} onMouseLeave={() => setAdministrativoOpen(false)}>
                    <AdminAmareloSvg /> <p>Administrativo</p> <ArrowDownSvg className='arrow-down' />
                        <AdministrativoNav style={{visibility: administrativoOpen ? '' : 'hidden', opacity: administrativoOpen ? '' : '0'}}>
                            {user?.permissao === 'ac' && (
                                <Link to='/administrativo/geo'>
                                    <figure>
                                        <ReactSVG src={MenuGeoSvg} />
                                    </figure>
                                    Geo
                                </Link>
                            )}
                            {['ac', 'admin'].includes(user?.permissao) && (
                                <Link to='/administrativo/comercial'>
                                    <figure>
                                        <ReactSVG src={ComercialSvg} />
                                    </figure>
                                    Comercial
                                </Link>
                            )}
                            {['ac','admin', 'geo', 'gc'].includes(user?.permissao) && (
                                <Link to='/administrativo/operacao'>
                                    <figure>
                                        <ReactSVG src={MenuOperacaoSvg} />
                                    </figure>
                                    Operação
                                </Link>
                            )}
                            {['ac','admin'].includes(user?.permissao) && (
                                <Link to='/administrativo/usuario'>
                                    <figure>
                                        <ReactSVG src={MenuUsuarioSvg} />
                                    </figure>
                                    Usuário
                                </Link>
                            )}
                            {(['ac','admin', 'geo', 'gc'].includes(user?.permissao)  || (['gv'].includes(user?.permissao) && user?.geo_id === 3)) && (
                                <Link to='/administrativo/ponto-de-venda'>
                                    <figure>
                                        <ReactSVG src={MenuPontoDeVenda} />
                                    </figure>
                                    Ponto de Venda
                                </Link>
                            )}
                            {(['ac', 'admin'].includes(user?.permissao) || (['gv', 'gc'].includes(user?.permissao) && user?.geo_id === 3)) && (
                                <>
                                <Link to='/administrativo/bebida'>
                                    <figure>
                                        <ReactSVG src={MenuBebidas} />
                                    </figure>
                                    Bebidas
                                </Link>
                                <Link to='/administrativo/categoria'>
                                    <figure>
                                        <ReactSVG src={MenuCategorias} />
                                    </figure>
                                    Categorias
                                </Link>
                                <Link to='/administrativo/volume'>
                                    <figure>
                                        <ReactSVG src={MenuVolumes} />
                                    </figure>
                                    Volumes
                                </Link>
                                <Link to='/administrativo/vinculo'>
                                    <figure>
                                        <ReactSVG src={MenuVinculo} />
                                    </figure>
                                    Vínculos
                                </Link>
                                <Link to='/administrativo/escada'>
                                    <figure>
                                        <ReactSVG src={EscadaPrecoSvg} />
                                    </figure>
                                    Escada de preços
                                </Link>
                                </>
                            )}
                        </AdministrativoNav>
                </AdministrativoButton>
            )}
            <UserInfoContainer>
                <span>{user?.nome}</span>
                <p>
                    {user?.permissao === 'mari' && 'Produtor'}
                    {user?.permissao === 'ac' && 'Administrador Central'}
                    {user?.permissao === 'admin' && 'Administrador'}
                    {user?.permissao === 'geo' && 'Regional'}
                    {user?.permissao === 'gc' && 'Gerente Comercial'}
                    {user?.permissao === 'gv' && 'Gerente de Vendas'}
                    {user?.permissao === 'RN' && 'Representante Nacional'}
                </p>
            </UserInfoContainer>
            <LogoutButton onClick={() => {
                logout();
                navigate('/');
            }}>
                <ReactSVG src={LogoutSvg} />
            </LogoutButton>
            <MenuButton onClick={() => setMenuOpen(true)}>
                <AiOutlineMenuFold size={20} fill='#292828'/>
            </MenuButton>
            <MenuMobile 
                menuOpen={menuOpen} 
                onClick={() => {
                    setAdministrativoMobileOpen(false);
                    setMenuOpen(false);
                }}
            >
                <div className='menu' onClick={(e) => e.stopPropagation()}>
                    <CloseMenuButton 
                        onClick={() => {
                            setAdministrativoMobileOpen(false);
                            setMenuOpen(false);
                        }}
                    >
                        <AiOutlineMenuUnfold size={20} fill='#292828'/>
                    </CloseMenuButton>
                    <div className='userinfo'>
                        <span>{user?.nome}</span>
                        <p>
                            {user?.permissao === 'mari' && 'Produtor'}
                            {user?.permissao === 'ac' && 'Administrador Central'}
                            {user?.permissao === 'admin' && 'Administrador'}
                            {user?.permissao === 'geo' && 'Regional'}
                            {user?.permissao === 'gc' && 'Gerente Comercial'}
                            {user?.permissao === 'gv' && 'Gerente de Vendas'}
                            {user?.permissao === 'RN' && 'Representante Nacional'}
                        </p>
                    </div>
                    {['ac', 'admin'].includes(user?.permissao) && (
                        <Link to='/dashboard' className={page  === 'dashboard' ? 'hovered' : ''}>
                            <ReactSVG src={DashSvg} style={{zIndex: 2}}/> <p>Dashboard</p>
                        </Link>
                    )}    
                    <Link to='/pedidos' className={page  === 'pedidos' ? 'hovered' : ''}>
                        <PedidosAmareloSvg /> <p>Pedidos</p>
                    </Link>
                    <MenuAdministrativoMobile administrativoMobileOpen={administrativoMobileOpen}>
                        {['ac', 'admin', 'geo', 'gc'].includes(user?.permissao) && (
                            <button 
                                className='administrativo-button' 
                                onClick={() => setAdministrativoMobileOpen(!administrativoMobileOpen)}
                            >
                                <AdminAmareloSvg /> <p>Administrativo</p> <ArrowDownSvg className='arrow-down' />
                            </button>
                        )}
                        <nav>
                            {user?.permissao === 'ac' && (
                                <Link to='/administrativo/geo'>
                                    <figure>
                                        <ReactSVG src={MenuGeoSvg} />
                                    </figure>
                                    Geo
                                </Link>
                            )}
                            {['ac', 'admin'].includes(user?.permissao) && (
                                <Link to='/administrativo/comercial'>
                                    <figure>
                                        <ReactSVG src={ComercialSvg} />
                                    </figure>
                                    Comercial
                                </Link>
                            )}
                            {['ac','admin', 'geo', 'gc'].includes(user?.permissao) && (
                                <Link to='/administrativo/operacao'>
                                    <figure>
                                        <ReactSVG src={MenuOperacaoSvg} />
                                    </figure>
                                    Operação
                                </Link>
                            )}
                            {['ac','admin'].includes(user?.permissao) && (
                                <Link to='/administrativo/usuario'>
                                    <figure>
                                        <ReactSVG src={MenuUsuarioSvg} />
                                    </figure>
                                    Usuário
                                </Link>
                            )}
                            {['ac','admin', 'geo', 'gc'].includes(user?.permissao) && (
                                <Link to='/administrativo/ponto-de-venda'>
                                    <figure>
                                        <ReactSVG src={MenuPontoDeVenda} />
                                    </figure>
                                    Ponto de Venda
                                </Link>
                            )}
                            {(['ac', 'admin'].includes(user?.permissao) || (['gv', 'gc'].includes(user?.permissao) && user?.geo_id === 3)) && (                      <>
                                <Link to='/administrativo/bebida'>
                                    <figure>
                                        <ReactSVG src={MenuBebidas} />
                                    </figure>
                                    Bebidas
                                </Link>
                                <Link to='/administrativo/categoria'>
                                    <figure>
                                        <ReactSVG src={MenuCategorias} />
                                    </figure>
                                    Categorias
                                </Link>
                                <Link to='/administrativo/volume'>
                                    <figure>
                                        <ReactSVG src={MenuVolumes} />
                                    </figure>
                                    Volumes
                                </Link>
                                <Link to='/administrativo/vinculo'>
                                    <figure>
                                        <ReactSVG src={MenuVinculo} />
                                    </figure>
                                    Vínculos
                                </Link>
                                <Link to='/administrativo/escada'>
                                    <figure>
                                        <ReactSVG src={EscadaPrecoSvg} />
                                    </figure>
                                    Escada de preços
                                </Link>
                                </>
                            )}
                        </nav>
                    </MenuAdministrativoMobile>
                    <button 
                        className='sair'
                        onClick={() => {
                            logout();
                            navigate('/');
                        }}
                    >
                        <ReactSVG src={LogoutSvg} /> <p>Sair</p>
                    </button>
                </div>
            </MenuMobile>
        </HeaderContainer>
    );
};

export default Header;