import CustomModal from '../CustomModal';
import { ContentContainer, CriarNovaLevaForm, InputContainer, ModalCriarNovaLevaContainer, SalvarButton } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import criarNovaLevaSchema from '../../../schemas/Levas/criarNovaLevaSchema';
import { useAuth } from '../../../providers/Auth';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loading from '../../Loading';

const ModalCriarNovaLeva = ({ setModalOpened, buscarPedidos, buscarLeva }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(criarNovaLevaSchema)
    });

    const [geos, setGeos] = useState([]);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const { user, logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (geosLoaded) {
            setLoading(false);
        };
    }, [geosLoaded]);

    useEffect(() => {
        const buscarGeos = () => {
            api.get(`/geos`)
                .then(response => {
                    setGeos(response.data.data.geos);
                    setGeosLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                });
        };
        if (user?.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        }
    }, [user]);

    const handleCriarNovaLeva = (data) => {
        const dataFinal = `${data.data_final.getDate().toString().padStart(2, '0')}/${(data.data_final.getMonth()+1).toString().padStart(2, '0')}/${data.data_final.getFullYear()}`;
        const dados = {
            quantidade_min: data.quantidade_min,
            data_final: dataFinal,
        };

        api.post('/levas', dados)
            .then(()=>{
                buscarPedidos();
                buscarLeva();
                setModalOpened(false);
                toast.success('Leva criada com sucesso!');
            })
            .catch(()=>{
                toast.error('Erro ao criar leva!')
            });
    };

    return (
        <ModalCriarNovaLevaContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Criar nova leva</h1>
                        <CriarNovaLevaForm onSubmit={handleSubmit(handleCriarNovaLeva)}>
                            <div>
                                <InputContainer>
                                    <label htmlFor='mínimo'>Mínimo de pedidos</label>
                                    <input 
                                        id='mínimo'
                                        type='number'
                                        placeholder='00000'
                                        {...register('quantidade_min')}
                                    />
                                    {errors.quantidade_min && <span style={{top: '60px'}} className='input-error'>{errors.quantidade_min.message}</span>}
                                </InputContainer>
                                <InputContainer>
                                    <label htmlFor='corte'>Data de corte</label>
                                    <input 
                                        id='corte'
                                        type='date'
                                        {...register('data_final')}
                                    />
                                    {errors.data_final && <span style={{top: '60px'}} className='input-error'>{errors.data_final.message}</span>}
                                </InputContainer>
                            </div>
                            <SalvarButton>
                                <SalvarSvg /> Salvar
                            </SalvarButton>
                        </CriarNovaLevaForm>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalCriarNovaLevaContainer>
    );
};

export default ModalCriarNovaLeva;