import { FcDownload } from 'react-icons/fc';
import Loading from '../../Loading';
import { ArquivoContainer, ButtonsContainer, ContentContainer, ExemploContainer, ModalImportarBebidaVolumesContainer } from './styles';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import CustomModal from '../CustomModal';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import volumesSchema from '../../../schemas/Volumes/volumesSchema';

const ModalImportarBebidaVolumes = ({ setModalOpened }) => {
    const [arquivo, setArquivo] = useState('Selecionar arquivo...');
    const [dados, setDados] = useState('');
    const inputFileRef = useRef(null);

    const [bebidas, setBebidas] = useState([]);

    const { user, logout } = useAuth();
    const navigate = useNavigate(); 

    const [bebidasLoaded, setBebidasLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (bebidasLoaded) {
            setLoading(false);
        }
    }, [bebidasLoaded]);

    const buscarBebidas = () => {
        setBebidasLoaded(false);
        api.get(`/bebidas`)
            .then(response => {
                setBebidas(response.data.data.bebidas);
                setBebidasLoaded(true);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar bebida!');
            });
    };

    useEffect(() => {
        buscarBebidas();
        //eslint-disable-next-line
    },[]);

    const arquivoExemplo = () => {
        const dadosExemplo = [
            {
                bebida_id: '1',
                volume: '200ml'
            }
        ];
        if (user.permissao === 'ac') dadosExemplo[0].geo_id = 1;

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(dadosExemplo);
        XLSX.utils.book_append_sheet(wb, ws, 'criar_bebida_volumes');
        XLSX.writeFile(wb, 'criar_bebida_volumes.xlsx');
    };

    const criaRelatorioBebida = () => {
        const fields = ['id','nome'];
        if (user.permissao === 'ac') {
            fields.push('geo_id');
        };
        const dadosFiltrados = bebidas.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        const ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'bebidas');
        XLSX.writeFile(wb, 'bebidas.xlsx');
    };

    const readUploadFile = (e) => {
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                const dadosFormatados = json.map((item) => {
                    return {
                        ...item,
                        bebida_id: {value: item.bebida_id}
                    }
                });
                setDados(dadosFormatados);
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        };
    };

    const atualizar = async () => {
        try {
            await volumesSchema.validate({volumes: dados});
        } catch (error) {
            if (error.message && error.path) {
                const errorPath = error.path;
                const linha = errorPath.slice(
                    errorPath.indexOf("[") + 1, 
                    errorPath.lastIndexOf("]")
                );
                toast.error(<div>{error.message}<br /> {!Number.isNaN(Number(linha)) && `Linha: ${Number(linha)+2}`}</div>)
            };
            setArquivo('Selecionar arquivo...');
            setDados('');
            inputFileRef.current.value = null;
            return;
        };
        const dadosFormatados = dados.map((item) => {
            return {
                bebida_id: item.bebida_id.value,
                volume: item.volume,
            };
        });
        api.post('/bebida-volumes/multiplos', {vinculos: dadosFormatados})
            .then(response => {
                toast.success('Volumes cadastradas com sucesso!');
                navigate('/administrativo/volume');
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                }
                if (error.response.status === 404) {
                    toast.error(error.response.data.message)
                    return;
                };
                setArquivo('Selecionar arquivo...');
                setDados('');
                inputFileRef.current.value = null;
                toast.error('Erro ao cadastrar volumes!');
            });
    };

    return (
        <ModalImportarBebidaVolumesContainer>
            <CustomModal setModalOpened={setModalOpened}>
                {loading ? (
                    <Loading />
                ) : (
                    <ContentContainer>
                        <h1>Importar Bebida Volumes</h1>
                        <p>
                            <button type='button' onClick={() => arquivoExemplo()}>
                                Baixe aqui
                            </button> o modelo de planilha
                        </p>
                        <ExemploContainer>
                                <div className='item'>
                                    Coluna <strong>"bebida_id":</strong> 
                                    <p>
                                        preencher com <strong>id</strong> da <strong>bebida</strong>.
                                    </p> 
                                    <FcDownload onClick={criaRelatorioBebida} size={18}/>
                                </div>
                        </ExemploContainer>
                        <ArquivoContainer>
                            <label>{'Arquivo (.xlsx)'}</label>
                            <label htmlFor='arquivo-xlsx' className='arquivo-input'>
                                <p>{arquivo}</p>
                                <button type='button'>
                                    selecionar
                                </button>
                            </label>
                            <input
                                id="arquivo-xlsx"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={inputFileRef}
                                onChange={(e) => {
                                    const event = e;
                                    readUploadFile(event);
                                    setArquivo(event.target.files[0].name);
                                    e.target.value = null;
                                }}
                            />
                        </ArquivoContainer>
                        <ButtonsContainer>
                            <button type='button' onClick={async () => await atualizar()} className='importar'>criar</button>
                            <button type='button' onClick={() => setModalOpened(false)} className='voltar'>voltar</button>
                        </ButtonsContainer>
                    </ContentContainer>
                )}
            </CustomModal>
        </ModalImportarBebidaVolumesContainer>
    );
};

export default ModalImportarBebidaVolumes;