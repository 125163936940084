import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Pedidos from '../../components/Pedidos';
import { PedidosPageContainer } from './styles';

const PedidosPage = () => {

    return (
        <PedidosPageContainer>
            <Header page='pedidos'/>
            <Pedidos />
            <Footer />
        </PedidosPageContainer>
    );
};

export default PedidosPage;