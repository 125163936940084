import { Link } from 'react-router-dom';
import { Tr } from './styles';
import { ReactComponent as EditarSvg } from '../../../assets/editar.svg';

const GeoTr = ({ geo }) => {

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{geo.id}
            </td>
            <td>
                <label>Nome</label>
                {geo.nome}
            </td>
            <td>
                <label>Criação</label>
                {geo.data_de_criacao}
            </td>
            <td>
                <label></label>
                <Link to={`/administrativo/geo/editar/${geo.id}`} className='editar'>
                    editar <EditarSvg />
                </Link>
            </td>
        </Tr>
    );
};

export default GeoTr;