import { AdminGeoEditarContainer, ContentContainer } from './styles';
import { ReactComponent as CadastroGeoSvg } from '../../assets/cadastro-geo.svg';
import GeoForm from '../Forms/GeoForm';

const AdminGeoEditar = () => {

    return (
        <AdminGeoEditarContainer>
            <div className='header'>
                <CadastroGeoSvg /> <h1>Editar Geo</h1> 
            </div>
            <ContentContainer>
                <GeoForm />    
            </ContentContainer>
        </AdminGeoEditarContainer>
    );
};

export default AdminGeoEditar;