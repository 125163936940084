import { Tr } from './styles';
import { ReactSVG } from 'react-svg';
import EditarSvg from '../../../assets/editar.svg';
import ExcluirSvg from '../../../assets/excluir.svg';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { GrFormCheckmark , GrFormClose } from 'react-icons/gr';
import { moneyMask } from '../../../utils/masks';
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';

const PedidoProdutoTr = ({ index, produto, pedido, buscarPedidoProdutos, pedidoProdutos, setPedidoProdutosLoading }) => {
    const [editar, setEditar] = useState(false);
    const [preco, setPreco] = useState(produto.preco);

    const [width, setWidth] = useState(0);
    const span = useRef();

    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (span.current) setWidth(span.current.offsetWidth + 8);
    }, [preco]);

    const handleEditar = () => {
        api.patch(`pedido-produtos/${pedido.id}`, {id: produto.id, preco})
            .then((response) => {
                setEditar(false);
                buscarPedidoProdutos();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };

    const handleExcluir = () => {
        api.delete(`/pedido-produtos/${pedido.id}`, {data: {pedido_produto_id: produto.id}})
            .then((response) => {
                buscarPedidoProdutos();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Não foi possível excluir o produto do pedido. Tente novamente mais tarde.')
            });
    };
    const handlePosicaoCima = () => {
        let produtosUpdate = [];
        pedidoProdutos.forEach((pedidoProduto) => {
            if (Number(pedidoProduto.ordem) === Number(produto.ordem)) {
                produtosUpdate.push({
                    id: pedidoProduto.id,
                    ordem: Number(pedidoProduto.ordem) - 1
                });
            };
            if (Number(pedidoProduto.ordem) === Number(produto.ordem) - 1) {
                produtosUpdate.push({
                    id: pedidoProduto.id,
                    ordem: Number(pedidoProduto.ordem) + 1
                });
            };
        });
        api.patch(`pedido-produtos/${pedido.id}/ordem`, {produtos: produtosUpdate})
            .then((response) => {
                setPedidoProdutosLoading(true);
                setEditar(false);
                buscarPedidoProdutos();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };

    const handlePosicaoBaixo = () => {
        let produtosUpdate = [];
        pedidoProdutos.forEach((pedidoProduto) => {
            if (Number(pedidoProduto.ordem) === Number(produto.ordem)) {
                produtosUpdate.push({
                    id: pedidoProduto.id,
                    ordem: Number(pedidoProduto.ordem) + 1
                });
            };
            if (Number(pedidoProduto.ordem) === Number(produto.ordem) + 1) {
                produtosUpdate.push({
                    id: pedidoProduto.id,
                    ordem: Number(pedidoProduto.ordem) - 1
                });
            };
        });
        api.patch(`pedido-produtos/${pedido.id}/ordem`, {produtos: produtosUpdate})
            .then((response) => {
                setEditar(false);
                setPedidoProdutosLoading(true);
                buscarPedidoProdutos();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error(error.response.data.message)
                setEditar(false);
            });
    };
    
    return (
        <Tr>
            <td>
                <div className='ordenar'>
                    <button
                        disabled={Number(index) === 0}
                        onClick={handlePosicaoCima}
                    >
                        <MdOutlineArrowDropUp title='Mover produto pra cima' size={25} fill='#292828'/>
                    </button>
                    <button
                        disabled={Number(index+1) === pedidoProdutos.length}
                        onClick={handlePosicaoBaixo}
                    >
                        <MdOutlineArrowDropDown title='Mover produto pra baixo' size={25} fill='#292828'/>
                    </button>
                </div>
            </td>
            <td>
                <label>Produto</label>
                {produto.nome}
            </td>
            <td>
                <label>Volume</label>
                {produto.volume}
            </td>
            <td>
                <label>Preço</label>
                <div className='preco'>
                    R$
                    {editar ? (
                        <div>
                            <span ref={span} style={{position: 'absolute', visibility: 'hidden'}}>{preco}</span>
                            <input
                                type='text'
                                value={preco}
                                style={{width}}
                                maxLength={6}
                                onChange={(e) => setPreco(moneyMask(e.target.value))}
                            />
                        </div>
                    ) : (
                        produto.preco
                    )}
                </div>
            </td>
            <td>
                <label>Editar/Excluir</label>
                {editar ? (
                    <div>
                        <button className='salvar' onClick={handleEditar}>
                            <GrFormCheckmark size={20}/>
                        </button>
                        <button className='cancelar' onClick={() => setEditar(false)}>
                            <GrFormClose size={20}/>
                        </button>
                    </div>
                ) : (
                    <div>
                        <button className='editar' onClick={() => setEditar(true)}>
                            <ReactSVG src={EditarSvg} />
                        </button>
                        <button className='excluir' onClick={handleExcluir}>
                            <ReactSVG src={ExcluirSvg} />
                        </button>
                    </div>
                )}
            </td>
        </Tr>
    )
};

export default PedidoProdutoTr;