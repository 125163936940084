import AdminComercial from '../../components/AdminComercial';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminComercialPageContainer } from './styles';

const AdminComercialPage = () => {

    return (
        <AdminComercialPageContainer>
            <Header />
            <AdminComercial />
            <Footer />
        </AdminComercialPageContainer>
    );
};

export default AdminComercialPage;