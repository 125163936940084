import * as yup from 'yup';
import bebidaSchema from './bebidaSchema';

const bebidasSchema = yup.object().shape({
    bebidas: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(bebidaSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default bebidasSchema;