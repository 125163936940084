import AdminBebidaNovo from '../../components/AdminBebidaNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminBebidaNovoPageContainer } from './styles';

const AdminBebidaNovoPage = () => {

    return (
        <AdminBebidaNovoPageContainer>
            <Header />
            <AdminBebidaNovo />
            <Footer />
        </AdminBebidaNovoPageContainer>
    );
};

export default AdminBebidaNovoPage;