import styled from 'styled-components';

export const EscadaBebidaComparadorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    min-width: 150px;
    height: 80px;
    background: #f0f0f0;
    border: 1px solid #70707030;
    border-radius: 9px;

    position: relative;

    > .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        > p {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #292828;
            font-size: 0.8em;
            font-weight: 500;
            padding: 5px;
            height: 100%;
        }

        > span {
            padding: 2px 5px;
            background: #40AD48;
            font-size: 0.8em;
            width: 100%;
            border-radius: 0 0 9px 9px;
            text-align: center;

            > b {
                font-size: 1em;
                font-weight: 600;
            }
        }
    }
`;

export const ExcluirButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    background-color: #fa5252;
    width: 20px;
    height: 20px;

    position: absolute;
    top: -5px;
    right: -8px;

    > div {
        width: 12px;
        height: 12px;

        > div {
            width: 100%;
            height: 100%;
            
            svg {
                width: 7px;
                height: 8px;
                margin-bottom: 4px;
            }
        } 
    }
`;