import { Link, useNavigate, useParams } from 'react-router-dom';
import { Form, InputContainer, SalvarButton, SelectContainer } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import categoriaSchema from '../../../schemas/Categorias/categoriaSchema';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useEffect, useState } from 'react';
import Loading from '../../Loading';
import ImportarSvg from '../../../assets/importar.svg';
import { ReactSVG } from 'react-svg';
import ModalImportarCategorias from '../../Modals/ModalImportarCategorias';

const CategoriaForm = () => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
        resolver: yupResolver(categoriaSchema)
    });

    const [geos, setGeos] = useState([]);
    
    const [geoSelecionada, setGeoSelecionada] = useState('');
    const [geoError, setGeoError] = useState(false);

    const { categoria_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [modalImportarCategorias, setModalImportarCategorias] = useState(false);

    const [categoriaLoaded, setCategoriaLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (categoriaLoaded && geosLoaded) {
            setLoading(false);
        };
    }, [categoriaLoaded, geosLoaded]);

    useEffect(() => {
        if (categoria_id) {
            api.get(`/categorias-bebidas/${categoria_id}`)
                .then(response => {
                    const categoria = response.data.data.categoria;
                    reset(categoria);
                    setGeoSelecionada(categoria.geo_id);
                    setCategoriaLoaded(true);
                })
                .catch(error => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 404) {
                        toast.error('Categoria não encontrada!');
                        return;
                    };
                    toast.error('Erro ao buscar categoria!');
                });
        } else {
            setCategoriaLoaded(true);
        };
    }, []);

    useEffect(() => {
        const buscarGeos = () => {
            api.get(`/geos`)
                .then(response => {
                    setGeos(response.data.data.geos);
                    setGeosLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                });
        };
        if (user?.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        }
    }, [user]);

    const onSubmitFunction = (data) => {
        if (user?.permissao === 'ac') {
            if (!geoSelecionada) {
                setGeoError(true);
                return;
            };
        };
        const dados = {
            ...data,
            geo_id: geoSelecionada
        };
        if (categoria_id) {
            dados.id = categoria_id;
            api.patch(`/categorias-bebidas`, {categorias: [dados]})
                .then((response) => {
                    toast.success('Categoria atualizada com sucesso!');
                    navigate('/administrativo/categoria');
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response?.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response?.status === 409) {
                        toast.error('Categoria já cadastrada!');
                        return;
                    };
                    toast.error('Erro ao atualizar categoria!');
                });
        } else {
            api.post('/categorias-bebidas', {categorias : [dados]})
            .then((response) => {
                toast.success('Categoria cadastrada com sucesso!');
                reset();
                setGeoSelecionada('');
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response?.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                if (error.response?.status === 409) {
                    toast.error('Categoria já cadastrada!');
                    return;
                };
                toast.error('Erro ao cadastrar categoria!');
            });
        };
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                 {!categoria_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarCategorias(true)} type='button'>
                            <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div>
                )}
                <InputContainer style={{width: '100%'}}>
                    <label htmlFor='nome'>Nome</label>
                    <input 
                        type='text' 
                        id='nome' 
                        placeholder='Nome da categoria'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error'>{errors.nome.message}</span>}
                </InputContainer>
                {user.permissao === 'ac' && (
                    <SelectContainer style={{width: '100%'}}>
                         <label htmlFor='geo_id'>Geo</label>
                        <select
                            id='geo_id'
                            value={geoSelecionada}
                            onChange={e => setGeoSelecionada(e.target.value)}
                        >
                            <option value=''>Selecione uma geo</option>
                            {geos.map(item => {
                                return (
                                    <option key={item.id} value={item.id}>{item.id} - {item.nome}</option>
                                );
                            })}
                        </select>
                        {geoError && <span className='input-error' style={{top: '60px'}}>Campo obrigatório</span>}
                    </SelectContainer>
                )}
                <div className='buttons-container'>
                    <Link to='/administrativo/categoria'>{'< Voltar'}</Link>
                    <SalvarButton 
                        onClick={() => {
                            if (user.permissao === 'ac' && !geoSelecionada) {
                                setGeoError(true);
                                return;
                            };
                            setGeoError(false);
                        }}
                        type='submit'
                    >
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
            </Form>
        )}
        {modalImportarCategorias && <ModalImportarCategorias setModalOpened={setModalImportarCategorias} />}
        </>
    )
};

export default CategoriaForm;