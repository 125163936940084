import styled from 'styled-components';

export const PedidosLevasContainer = styled.div`

`;

export const PedidosLevaContainer = styled.div`
    display: flex;
    justify-content: space-around;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 15px 20px;

    > .geo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        > h2 {
            color: #292828;
            font-size: 1.4rem;
            font-weight: 700;
        } 
    }

    > .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 15px;
        > h3 {
            display: flex;
            align-items: center;
            column-gap: 10px;
            color: #292828;
            font-size: 1.1rem;
        }

        > p {
            color: #292828;
            font-weight: 600;
            font-size: 1.6rem;
        }
    }

    > .item:last-child {
        border-right: none;
    }

    > .divisor {
        border-left: 1px solid #E9E9E9;
    }

    @media screen and (max-width: 1190px) {
        > .geo {
            margin-right: 30px;
        }
        
        > .geo > h2 {
            font-size: 1.2em; 
        }

        > .item > h3 {
            font-size: 0.9em;
        }

        > .item > p {
            font-size: 1.4em;
        }

        > .item svg {
            height: 20px;
            width: 20px;
        }
    }

    @media screen and (max-width: 985px) {
        > .geo {
            margin-right: 20px;
        }

        > .geo > h2 {
            font-size: 1em;
        }

        >  .item > h3 {
            font-size: 0.7em;
        }

        >  .item > p {
            font-size: 1.2em;
        }

        > .item svg {
            height: 15px;
            width: 15px;
        }
    }

    @media screen and (max-width: 840px) {
        flex-direction: column;

        > .item {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 30px;

            > h3 {
                column-gap: 0px;
                flex-direction: row-reverse; 

                svg {
                    width: 25px;
                }
            }
        }

        > .divisor {
            border-bottom: 1px solid #E9E9E9;
        }
    }
`;
