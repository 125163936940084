import { Navigate } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';
import { useAuth } from '../providers/Auth';

const CustomRoute = ({element: Element,permissions = []}) => {
    const { loadingUser, user } = useAuth();

    if(loadingUser){
        if(user){
            const userPermission = user.permissao;

            if(permissions.includes(userPermission)){
                return <Element />
            } else {
                return <NotFoundPage />
            }
        } else {
            return <Navigate to='/'/>
        }
    } 
};

export default CustomRoute;