import AdminComercialEditar from '../../components/AdminComercialEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminComercialEditarPageContainer } from './styles';

const AdminComercialEditarPage = () => {

    return (
        <AdminComercialEditarPageContainer>
            <Header />
            <AdminComercialEditar />
            <Footer />
        </AdminComercialEditarPageContainer>  
    );
};

export default AdminComercialEditarPage;