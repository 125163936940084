import { AdminOperacaoNovoContainer, ContentContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroOperacaoSvg from '../../assets/cadastro-operacao.svg';
import OperacaoForm from '../Forms/OperacaoForm';

const AdminOperacaoNovo = () => {

    return (
        <AdminOperacaoNovoContainer>
            <div className='header'>
                <ReactSVG src={CadastroOperacaoSvg}/> <h1>Cadastro de Operação</h1> 
            </div>
            <ContentContainer>
                <OperacaoForm />
            </ContentContainer>
        </AdminOperacaoNovoContainer>
    );
};

export default AdminOperacaoNovo;