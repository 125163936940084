import LoginForm from '../../components/Forms/LoginForm';
import { FormContainer, ImageContainer, LoginPageContainer } from './styles';
import { ReactComponent as LogoAmbevSvg } from '../../assets/logo-ambev.svg';

const LoginPage = () => {
    return (
        <LoginPageContainer>
            <FormContainer>
                <LoginForm />
                <LogoAmbevSvg />
            </FormContainer>
            <ImageContainer>
            </ImageContainer>
        </LoginPageContainer>
    );
};

export default LoginPage;