import * as yup from 'yup';

yup.addMethod(yup.string, 'tituloValido', function (message) {
    return this.test('tituloValido', message, function (value) {
        const { template } = this.parent;

        if (template === 'brahma' || template === 'brahma duplo malte') {
            let tituloTamanho = 55;
            for (let i = 0; i < value.length; i++) {
                if (['i', 'f', 'l', 'z', '(', ')', '/'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 1;
                };
                if (['a', 'á', 'ã', 'â', 'b', 'c', 'ç', 'd', 'e', 'é', 'g', 'h', 'j', 'k', 'm', 'n', 'o', 'ó', 'ô', 'õ', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 2;
                };
            };
            if (tituloTamanho < 0) {
                return false;
            } else {
                return true;
            };
        } else if (template === 'spaten') {
            let tituloTamanho = 71;
            for (let i = 0; i < value.length; i++) {
                if (['i', 'í', '1', ' ', '/', '(', ')', '.'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 1;
                };
                if (['a', 'á', 'ã', 'â', 'b', 'c', 'ç', ].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 2;
                };
                if (['g', 'd', 'e', 'é', 'f', 'h', 'j', 'k', 'l', 'n', 'p', 'r', 's', 't', 'v', 'x', 'y', 'z', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 3;
                };
                if (['o', 'ó', 'ô', 'õ', 'q', 'u', 'm', 'w'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 4;
                };
            };
            if (tituloTamanho < 0) {
                return false;
            } else {
                return true;
            };
        } else if (template === 'budweiser') {
            let tituloTamanho = 140;
            for (let i = 0; i < value.length; i++) {
                if (['i', 'í', '1', ' ', '/', '(', ')', '.'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 1;
                };
                if (['a', 'á', 'ã', 'â', 'b', 'c', 'ç', ].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 2;
                };
                if (['g', 'd', 'e', 'é', 'f', 'h', 'j', 'k', 'l', 'n', 'p', 'r', 's', 't', 'v', 'x', 'y', 'z', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 3;
                };
                if (['o', 'ó', 'ô', 'õ', 'q', 'u', 'm', 'w'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 4;
                };
            };
            if (tituloTamanho < 0) {
                return false;
            } else {
                return true;
            };
        } else if (template === 'original') {
            let tituloTamanho = 87;
            for (let i = 0; i < value.length; i++) {
                if (['i', 'í','1', ' ', '/', '(', ')', '.', '-'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 1;
                };
                if (['a', 'á', 'ã', 'â','b', 'c', 'ç', ].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 2;
                };
                if (['g','d', 'e', 'é', 'f', 'h', 'j', 'k', 'l', 'n', 'p', 'r', 's', 't', 'v', 'x', 'y', 'z', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 3;
                };
                if ([ 'o', 'ó', 'ô', 'õ', 'q', 'u','m', 'w'].includes(value[i].toLowerCase())) {
                    tituloTamanho = tituloTamanho - 4;
                };
            };
            if (tituloTamanho < 0) {
                return false;
            } else {
                return true;
            };
        } else {
            return true;
        };
    });
});

yup.addMethod(yup.string, 'validarQuantidade', function (message) {
    return this.test('validarQuantidade', message, function (value) {
        const { pedido, quantidade } = this.parent;

        if (pedido === 'impresso' && Number(quantidade) === 0 ) {
            return false;
        } else if ( pedido === 'impresso e qrcode'  && Number(quantidade) === 0 ) {
            return false;
        }else {
            return true;
        };
    });
});

yup.addMethod(yup.string, 'validarQuantidadeQrCode', function (message) {
    return this.test('validarQuantidadeQrCode', message, function (value) {
        const { pedido, quantidade_qrcode } = this.parent;

        if (pedido === 'qrcode' && Number(quantidade_qrcode) === 0) {
            return false;
        } else if ( pedido === 'impresso e qrcode' && Number(quantidade_qrcode) === 0) {
            return false;
        }else {
            return true;
        };
    });
});

const pedidoSchema = yup.object().shape({
    titulo: yup.string().required('Campo obrigatório').tituloValido('Título muito grande, por favor reduza o tamanho do título'),
    tipo: yup.string().required('Campo obrigatório'),
    template: yup.string().required('Campo obrigatório'),
    quantidade: yup.string().nullable().validarQuantidade('Quantidade obrigatória'),
    quantidade_qrcode: yup.string().nullable().validarQuantidadeQrCode('Quantidade obrigatória'),
    ponto_de_venda_id: yup.object().nullable().required('Campo obrigatório'),
    pedido: yup.string().required('Campo obrigatório'),
});

export default pedidoSchema;
