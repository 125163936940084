import { ReactSVG } from 'react-svg';
import { AdminComercialNovoContainer, ContentContainer } from './styles';
import ComercialSvg from '../../assets/comercial.svg';
import ComercialForm from '../Forms/ComercialForm';

const AdminComercialNovo = () => {

    return (
        <AdminComercialNovoContainer>
            <div className="header">
                <ReactSVG src={ComercialSvg} /> <h1>Cadastro de Comercial</h1>
            </div>
            <ContentContainer>
                <ComercialForm />
            </ContentContainer>
        </AdminComercialNovoContainer>
    );
};

export default AdminComercialNovo;