import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import criarGeoSchema from '../../../schemas/Geos/criarGeoSchema';
import { Form, InputContainer, SalvarButton } from './styles';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useEffect, useState } from 'react';
import Loading from '../../Loading';

const GeoForm = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(criarGeoSchema)
    });

    const { logout } = useAuth();

    const { geo_id } = useParams();
    const navigate = useNavigate();

    const [geoLoaded, setGeoLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (geoLoaded) {
            setLoading(false);
        };
    }, [geoLoaded]);
    
    useEffect(() => {
        if (geo_id) {
            api.get(`/geos/${geo_id}`)
                .then(response => {
                    reset(response.data.data.geo);
                    setGeoLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                    }
                    if (error.response.status === 404) {
                        toast.error('Geo não encontrada!');
                        return;
                    };
                    toast.error('Erro ao buscar Geo!');
                });
        } else {
            setGeoLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const onSubmitFunction = (data) => {
        if (geo_id) {
            api.patch(`/geos/${geo_id}`, data)
                .then(response => {
                    toast.success('Geo editada com sucesso!');
                    navigate('/administrativo/geo');
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/')
                        return;
                    };
                    if (error.response.status === 409) {
                        toast.error('Geo já cadastrada!');
                        return;
                    };
                    toast.error('Erro ao editar Geo!');
                });
            return;
        };
        api.post('/geos', data)
            .then(response => {
                reset();
                toast.success('Geo cadastrada com sucesso!');
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    return;
                };
                if (error.response.status === 409) {
                    toast.error('Geo já cadastrada!');
                    return;
                };
                toast.error('Erro ao cadastrar Geo!');
            });
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                <InputContainer>
                    <label htmlFor='nome'>Nome</label>
                    <input
                        type='text'
                        id='nome'
                        placeholder='Nome da região'
                        {...register('nome')}
                    />
                    {errors.nome && <span className='input-error' style={{top: '60px'}}>{errors.nome.message}</span>}
                </InputContainer>
                <div className='buttons-container'>
                    <Link to='/administrativo/geo'>{'< Voltar'}</Link>
                    <SalvarButton type='submit'>
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
            </Form>
        )}
        </>
    );
};

export default GeoForm;