import { EscadaBebidaComparadorContainer, ExcluirButton } from './styles';
import { useEffect, useState } from 'react';
import FecharSvg from '../../assets/fechar.svg';
import { ReactSVG } from 'react-svg';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';

const EscadaBebidaComparador = ({bebidaComparador, bebidaComparadorAtiva, tipoComparador, buscarEscadaBebidas, visualizar}) => {
    const [bebidaComparadorInfo, setBebidaComparadorInfo] = useState('');

    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (tipoComparador === 'maior') {
            if (bebidaComparador.escada_bebida_volume_filho_id === bebidaComparadorAtiva.id && bebidaComparador.maior) {
                setBebidaComparadorInfo({
                    nome: bebidaComparador.bebida_pai,
                    volume: bebidaComparador.volume_pai,
                    diferenca_minima: String(bebidaComparador.diferenca_minima.toFixed(2)).replace('.', ','),
                });
            } else {
                setBebidaComparadorInfo({
                    nome: bebidaComparador.bebida_filho,
                    volume: bebidaComparador.volume_filho,
                    diferenca_minima: String(bebidaComparador.diferenca_minima.toFixed(2)).replace('.', ','),
                });
            }
        }
        if (tipoComparador === 'menor') {
            if (bebidaComparador.escada_bebida_volume_pai_id === bebidaComparadorAtiva.id && bebidaComparador.maior) {
                setBebidaComparadorInfo({
                    nome: bebidaComparador.bebida_filho,
                    volume: bebidaComparador.volume_filho,
                    diferenca_minima: String(bebidaComparador.diferenca_minima.toFixed(2)).replace('.', ','),
                });
            } else {
                setBebidaComparadorInfo({
                    nome: bebidaComparador.bebida_pai,
                    volume: bebidaComparador.volume_pai,
                    diferenca_minima: String(bebidaComparador.diferenca_minima.toFixed(2)).replace('.', ','),
                });
            }
        }
    }, []);
   
    const handleExcluirBebidaComparador = () => {
        api.delete(`/escada-bebida-volumes-comparador/${bebidaComparador.id}`)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao remover comparador.');
            });
    };

    return (
        <EscadaBebidaComparadorContainer>
            {!visualizar && (
                <ExcluirButton onClick={handleExcluirBebidaComparador}>
                    <ReactSVG src={FecharSvg} />
                </ExcluirButton>
            )}
            {bebidaComparadorInfo && (
                <div className='content'>
                    <p>{bebidaComparadorInfo.nome} {bebidaComparadorInfo.volume}</p>
                    <span><b>diferença mín: </b>R${bebidaComparadorInfo.diferenca_minima}</span>
                </div>
            )}
        </EscadaBebidaComparadorContainer>
    )
};

export default EscadaBebidaComparador;