import * as yup from 'yup';
import operacaoSchema from './operacaoSchema';

const operacoesSchema = yup.object().shape({
    operacoes: yup.array()
        .typeError('Verifique se os dados estão sendo passados corretamente.')
        .of(operacaoSchema)
        .required('Verifique se os dados estão sendo passados corretamente.'),
});

export default operacoesSchema;