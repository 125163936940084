import AdminPdvNovo from '../../components/AdminPdvNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminPdvNovoPageContainer } from './styles';

const AdminPdvNovoPage = () => {

    return (
        <AdminPdvNovoPageContainer>
            <Header />
            <AdminPdvNovo />
            <Footer />
        </AdminPdvNovoPageContainer>
    );
};

export default AdminPdvNovoPage;