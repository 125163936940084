import HomeAguardandoSvg from '../../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../../assets/home-aprovados.svg';
import { ReactSVG } from 'react-svg';
import { Tr } from './styles';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../providers/Auth';

const PedidosPorGeoTr = ({ geo, pedidos, levaAtual, levasAtivas, geoSelecionada, anoSelecionado }) => {
    const [pedidosGeo, setPedidosGeo] = useState([]);
    const [pedidosGeoLevaAtual, setPedidosGeoLevaAtual] = useState([]);
    const [pedidosGeoAguardando, setPedidosGeoAguardando] = useState([]);
    const [pedidosGeoRevisar, setPedidosGeoRevisar] = useState([]);
    const [pedidosGeoAprovado, setPedidosGeoAprovado] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        const pedidosGeo = pedidos.filter(pedido => String(pedido.geo_id) === String(geo.id));
        const pedidosAguardando = pedidosGeo.filter(pedido => pedido.status === 'AGUARDANDO');
        const pedidosRevisar = pedidosGeo.filter(pedido => pedido.status === 'REVISAR');
        const pedidosAprovado = pedidosGeo.filter(pedido => pedido.status === 'APROVADO');

        setPedidosGeo(pedidosGeo);
        setPedidosGeoAguardando(pedidosAguardando);
        setPedidosGeoRevisar(pedidosRevisar);
        setPedidosGeoAprovado(pedidosAprovado);

        if (['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada) {
            const pedidosLevasAtivas = levasAtivas.reduce((acc, leva) => {
                if (!leva.data_encerramento) {
                    return acc.concat(pedidosGeo.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                };
            }, []);
            setPedidosGeoLevaAtual(pedidosLevasAtivas);
        } else if (levaAtual) {
            const pedidosLevaAtual = pedidosGeo.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
            setPedidosGeoLevaAtual(pedidosLevaAtual);
        } else {
            setPedidosGeoLevaAtual([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pedidos]);

    return (
        <Tr>
           <td>
                <label>Id</label>
                #{geo.id}
            </td>
            <td>
                <label>GEO</label>
                <div>
                    <p>{geo.nome}</p>
                </div>
            </td>
            <td>
                <label>
                    Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''}
                </label>
                {pedidosGeo.length}
            </td>
            <td>
                <label>
                    {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                </label>
                {pedidosGeoLevaAtual.length}
            </td>
            <td>
                <label className='aguardando'>
                    Aguardando <ReactSVG src={HomeAguardandoSvg}/>
                </label>
                {pedidosGeoAguardando.length}
            </td>
            <td>
                <label className='revisando'>
                    Revisando <ReactSVG src={HomeRevisarSvg}/>
                </label>
                {pedidosGeoRevisar.length}
            </td>
            <td>
                <label className='aprovado'>
                    Aprovado <ReactSVG src={HomeAprovadosSvg}/>
                </label>
                {pedidosGeoAprovado.length}
            </td>
        </Tr>
    )
};

export default PedidosPorGeoTr;