import { AdminCategoriaContainer, ContentContainer, PesquisarInputContainer, SelectContainer, TableContainer, TotalCategoriasContainer } from './styles';
import { ReactSVG } from 'react-svg';
import CadastroCategoriaSvg from '../../assets/cadastro-categoria.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/Auth';
import { Link, useNavigate } from 'react-router-dom';
import { paginador } from '../../utils/pager';
import api from '../../services/api';
import CustomTable from '../CustomTable';
import Pager from '../Pager';
import CategoriaTr from '../Trs/CategoriaTr';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';

const AdminCategoria = () => {
    const [categorias, setCategorias] = useState([]);

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');
    const [categoriasFiltradas, setCategoriasFiltradas] = useState([]);
    const [categoriasPaginadas, setCategoriasPaginadas] = useState([]);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [categoriasLoaded, setCategoriasLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (categoriasLoaded && geosLoaded){
            setLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriasLoaded, geosLoaded]);

    const buscarCategorias = () => {
        setCategoriasLoaded(false);
        api.get('/categorias-bebidas')
            .then((response) => {
                setCategorias(response.data.data.categorias);
                setCategoriasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarCategorias();
        if (user.permissao === 'ac') {
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const categoriasFiltradas = categorias.filter((categoria) => {
            const filtroId = pesquisa ? String(categoria.id) === String(pesquisa) : true;
            const filtroNome = categoria.nome.toLowerCase().includes(pesquisa.toLowerCase());
            const filtroGeo = geoSelecionada ? String(categoria.geo_id) === String(geoSelecionada.value) : true;

            return (filtroNome || filtroId) && filtroGeo;
        });
       setCategoriasFiltradas(categoriasFiltradas);
    },[categorias, pesquisa, geoSelecionada]);

    useEffect(() => {
        const paginado = paginador(categoriasFiltradas, paginaAtual)
        setCategoriasPaginadas(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    },[categoriasFiltradas, paginaAtual]);

    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
        let name = `categorias_${formatedDate}.xlsx`;
        const fields = ['id','nome','data_de_criacao','data_de_atualizacao'];
        const dadosFiltrados = categorias.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
        var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'categorias');
        XLSX.writeFile(wb,name);
    };

    return (
        <AdminCategoriaContainer>
            <div className='header'>
                <ReactSVG src={CadastroCategoriaSvg}/> <h1>Cadastro de Categoria</h1> 
            </div>  
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='header'>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-operação'
                                placeholder='Pesquisar categoria'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-operação'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                        <Link to='/administrativo/categoria/novo' className='novo'>
                            Novo
                        </Link>
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalCategoriasContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${categoriasFiltradas.length} ${categoriasFiltradas.length === 1 ? 'categoria' : 'categorias'}`}
                    </TotalCategoriasContainer>
                    <TableContainer>
                        <CustomTable 
                            header={['Id', 'Nome', `${user.permissao === 'ac' ? 'Geo' : ''}`,'Criação', 'Atualização', ' ']}
                            message='Nenhuma categoria encontrada.'
                        >
                            {categoriasPaginadas.map((categoria) => {
                                return <CategoriaTr key={categoria.id} categoria={categoria} />
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminCategoriaContainer>
    )
};

export default AdminCategoria;