import { useEffect } from 'react';
import { Tr } from './styles';
import { useState } from 'react';
import { useAuth } from '../../../providers/Auth';
import { ReactSVG } from 'react-svg';
import HomeAguardandoSvg from '../../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../../assets/home-aprovados.svg';

const PedidosPorComercialTr = ({ comercial, pedidos, levaAtual, levasAtivas, geoSelecionada, anoSelecionado }) => {
    const [pedidosComercial, setPedidosComercial] = useState([]);
    const [pedidosComercialLevaAtual, setPedidosComercialLevaAtual] = useState([]);
    const [pedidosComercialAguardando, setPedidosComercialAguardando] = useState([]);
    const [pedidosComercialRevisar, setPedidosComercialRevisar] = useState([]);
    const [pedidosComercialAprovado, setPedidosComercialAprovado] = useState([]);

    const { user } = useAuth();

    useEffect(() => {
        const pedidosComercial = pedidos.filter((pedido) => String(pedido.comercial_id) === String(comercial.id));
        const pedidosAguardando = pedidosComercial.filter((pedido) => pedido.status === 'AGUARDANDO');
        const pedidosRevisar = pedidosComercial.filter((pedido) => pedido.status === 'REVISAR');
        const pedidosAprovado = pedidosComercial.filter((pedido) => pedido.status === 'APROVADO');
        
        setPedidosComercial(pedidosComercial);
        setPedidosComercialAguardando(pedidosAguardando);
        setPedidosComercialRevisar(pedidosRevisar);
        setPedidosComercialAprovado(pedidosAprovado);

        if (['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada) {
            const pedidosLevasAtivas = levasAtivas.reduce((acc, leva) => {
                if (!leva.data_encerramento) {
                    return acc.concat(pedidosComercial.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                };
            },  []);
            setPedidosComercialLevaAtual(pedidosLevasAtivas);
        } else if (levaAtual) {
            const pedidosLevaAtual = pedidosComercial.filter((pedido) => String(pedido.leva_id) === String(levaAtual.id));
            setPedidosComercialLevaAtual(pedidosLevaAtual);
        } else {
            setPedidosComercialLevaAtual([]);
        }
    }, [pedidos]);

    return (
        <Tr>
            <td>
                <label>Id</label>
                #{comercial.id}
            </td>
            <td>
                <label>Comercial</label>
                {comercial.nome}
            </td>
            <td>
                <label>
                    Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''}
                </label>
                {pedidosComercial.length}
            </td>
            <td>
                <label>
                    {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                </label>
                {pedidosComercialLevaAtual.length}
            </td>
            <td>
                <label className='aguardando'>
                    Aguardando <ReactSVG src={HomeAguardandoSvg}/>
                </label>
                {pedidosComercialAguardando.length}
            </td>
            <td>
                <label className='revisando'>
                    Revisando <ReactSVG src={HomeRevisarSvg}/>
                </label>
                {pedidosComercialRevisar.length}	
            </td>
            <td>
                <label className='aprovado'>
                    Aprovado <ReactSVG src={HomeAprovadosSvg}/>
                </label>
                {pedidosComercialAprovado.length}
            </td>
        </Tr>
    );
};

export default PedidosPorComercialTr;