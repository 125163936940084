import { AdminPdvContainer, ContentContainer, ExtrairButton, PesquisarInputContainer, SelectContainer, TableContainer, TotalPdvsContainer } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import CadastroPontoDeVenda from '../../assets/cadastro-ponto-venda.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import CustomTable from '../CustomTable';
import VisualizarSvg from '../../assets/visualizar.svg';
import { useAuth } from '../../providers/Auth';
import Pager from '../Pager';
import PdvTr from '../Trs/PdvTr';
import api from '../../services/api';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loading from '../Loading';
import Select from 'react-select';
import HomeFiltroSvg from '../../assets/home-filtro.svg';
import { useRef } from 'react';
import { toast } from 'react-toastify';

const AdminPdv = () => {
    const [pdvs, setPdvs] = useState([]);

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [pesquisa, setPesquisa] = useState('');

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);
    const [totalPdvs, setTotalPdvs] = useState(0);

    const pesquisarButtonRef= useRef(null);

    const [pdvsLoaded, setPdvsLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);

    const [relatorioLoading, setRelatorioLoading] = useState(false);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (pdvsLoaded && geosLoaded){
            setLoading(false);
        } else {
            setLoading(true);
        };
    }, [pdvsLoaded, geosLoaded]);

    const buscarPdvs = () => {
        setPdvsLoaded(false);
        api.get(`/pdvs?pagina=${paginaAtual}&limite=10&geo_id=${geoSelecionada ? geoSelecionada.value : ''}&pesquisa=${pesquisa}`)
            .then((response) => {
                if(response.data.data.paginas) {
                    setTotalPaginas(response.data.data.paginas);
                };
                setTotalPdvs(response.data.data.quantidade);
                setPdvs(response.data.data.pdvs);
                setPdvsLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarPdvs();
    }, [geoSelecionada, paginaAtual]);

    const buscarGeos = () => {
        setGeosLoaded(false);
        api.get('/geos')
            .then((response) => {
                const geosOptions = response.data.data.geos.map((geo) => {
                    return {
                        value: geo.id,
                        label: geo.nome
                    };
                });
                setGeosOptions(geosOptions);
                setGeosLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        if (user.permissao === 'ac'){
            buscarGeos();
        } else {
            setGeosLoaded(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    const criaRelatorio = async () => {
        try {
            setRelatorioLoading(true);
            const date = new Date();
            const map = {
                mm: date.getMonth() + 1,
                dd: date.getDate(),
                aaaa: date.getFullYear()
            }
            const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched])
            let name = `pdvs_${formatedDate}.xlsx`;
            const fields = ['id','nome','codigo','cnpj','operacao', 'codigo_operacao','data_de_criacao','data_de_atualizacao'];
            const responsePdvs = await api.get(`/pdvs?geo_id=${geoSelecionada ? geoSelecionada.value : ''}&pesquisa=${pesquisa}`);
            const dadosFiltrados = responsePdvs.data.data.pdvs.map(item => Object.fromEntries(fields.map(k => [k, item[k]])));
            var ws = XLSX.utils.json_to_sheet(dadosFiltrados)
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb,ws,'pdvs');
            XLSX.writeFile(wb,name);
            setRelatorioLoading(false);
        } catch (error) {
            setRelatorioLoading(false);
            toast.error('Erro ao extrair relátorio.')
        };
    };

    return (
        <AdminPdvContainer>
            <div className='header'>
                <ReactSVG src={CadastroPontoDeVenda}/> <h1>Cadastro de Ponto de Venda</h1> 
            </div>
            {loading ? (
                <Loading />
            ) : (
                <ContentContainer>
                    <div className='header'>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-pdv'
                                placeholder='Pesquisar pdv'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        pesquisarButtonRef.current.click();
                                    }
                                }}
                            />
                            <button 
                                ref={pesquisarButtonRef}
                                onClick={buscarPdvs}
                            >
                                <ReactSVG src={PesquisarSvg} />
                                pesquisar
                            </button>
                        </PesquisarInputContainer>
                        <ExtrairButton onClick={criaRelatorio}>
                            {relatorioLoading ? (
                                <div className='loading'>
                                    <div className="lds-dual-ring"></div>
                                </div>
                            ): (
                                <>
                                <ReactSVG src={DownloadSvg} /> extrair lista
                                </>
                            )}
                        </ExtrairButton>
                        {['ac', 'admin'].includes(user?.permissao) && (
                            <Link to='/administrativo/ponto-de-venda/novo' className='novo'>
                                Novo
                            </Link>
                        )}
                    </div>
                    {user.permissao === 'ac' && (
                        <SelectContainer>
                            <label>
                                <ReactSVG src={HomeFiltroSvg} /> Filtrar por Geo
                            </label>
                            <Select 
                                options={geosOptions}
                                value={geoSelecionada}
                                onChange={(e) => setGeoSelecionada(e)}
                                placeholder='Ver todos'
                                className='filtro-select-container'
                                classNamePrefix='filtro-select'
                                isClearable
                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                            />
                        </SelectContainer>
                    )}
                    <TotalPdvsContainer>
                        <ReactSVG src={VisualizarSvg} /> {`Mostrando ${totalPdvs} ${totalPdvs === 1 ? 'pdv' : 'pdvs'}`}
                    </TotalPdvsContainer>
                    <TableContainer>
                        <CustomTable 
                            header={[
                                'Id', 
                                'Nome', 
                                'Operação',
                                `${user.permissao === 'ac' ? 'Geo' : ''}`, 
                                'CNPJ', 
                                'Código', 
                                ' '
                            ]} 
                            message='Nenhum ponto de venda encontrado.'
                        >
                            {pdvs.map((pdv) => {
                                return <PdvTr key={pdv.id} pdv={pdv} />
                            })}
                        </CustomTable>
                        <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                    </TableContainer>
                </ContentContainer>
            )}
        </AdminPdvContainer>
    )
};

export default AdminPdv;