import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import DownloadSvg from '../../assets/download.svg';
import { DownloadPedidosButtonContainer } from './styles';
import { ReactSVG } from 'react-svg';
import axios from 'axios';
import api from '../../services/api';
import { PDFDocument } from 'pdf-lib';

const zipFiles = async (arquivos, setProgress) => {
    const zip = new JSZip();
    for (let i = 0; i < arquivos.length; i++) {
        let responseCardapio = '';
        let responseQrCode = '';
        if (Number(arquivos[i].pedido.impresso)) {
            responseCardapio = await axios.get(`https://gestorcadapios.blob.core.windows.net${arquivos[i].pedido.link_pdv}.pdf`, {
                responseType: "arraybuffer", 
            });
        };
        if (Number(arquivos[i].pedido.qrcode)) {
            const fileName = arquivos[i].pedido.link_pdv.split('/').pop();
            responseQrCode = await axios.get(`https://gestorcadapios.blob.core.windows.net/qrcode/${fileName}.pdf`, {
                responseType: "arraybuffer",
            });
        };
        if (arquivos[i].pedido.status === 'APROVADO'){
            await api.patch(`/pedidos/${arquivos[i].pedido.id}/status`, { status: 'BAIXADO', leva_id: arquivos[i].leva_id})
        }
        setProgress(i + 1);

        if (responseCardapio && responseQrCode) {
            const pdfDoc1 = await PDFDocument.load(responseCardapio.data);
            const pdfDoc2 = await PDFDocument.load(responseQrCode.data);

            const mergedDoc = await PDFDocument.create();
            for (let i = 0; i < pdfDoc1.getPageCount(); i++) {
                const [page] = await mergedDoc.copyPages(pdfDoc1, [i]);
                mergedDoc.addPage(page);
            };
            for (let i = 0; i < pdfDoc2.getPageCount(); i++) {
                const [page] = await mergedDoc.copyPages(pdfDoc2, [i]);
                mergedDoc.addPage(page);
            };

            const mergedPdfBytes = await mergedDoc.save();
            zip.file(arquivos[i].fileName, mergedPdfBytes);
        } else if (responseCardapio) {
            zip.file(arquivos[i].fileName, responseCardapio.data);
        } else if (responseQrCode) {
            zip.file(`${arquivos[i].fileName}`, responseQrCode.data);
        };
    };
    const content = await zip.generateAsync({ type: "blob" });
    return content;
};

const DownloadPedidosButton = ({ arquivos, buscaPedidos }) => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (!loading) return;
        zipFiles(arquivos, setProgress)
            .then((content) => {
                if (mounted) {
                    setLoading(false);
                    setProgress(0);
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(content);
                    link.download = "pedidos.zip";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    buscaPedidos();
                }
            })
            .catch((error) => {
                setLoading(false);
                setProgress(0);
            });
        return () => {
            mounted = false;
        };
    }, [loading, arquivos]);

    return (
        <DownloadPedidosButtonContainer>
            {loading ? (
                <div 
                    className='loading'
                >
                    <div>
                        <span>{progress}</span> de <span>{arquivos.length}</span>
                    </div>
                    <progress value={progress} max={arquivos.length} style={{width: '110px'}} />
                </div>
            ) : (
                <button className='btn-download' onClick={() => setLoading(true)}>
                    <ReactSVG src={DownloadSvg}/> Baixar pdfs        
                </button>
            )}
        </DownloadPedidosButtonContainer>
    );
}

export default DownloadPedidosButton;