import { AlertaContainer, FiltrosContainer, LevasContainer, ListaLevasContainer, ListaPedidosContainer, NenhumaLevaCriadaContainer, PedidosContainer, PedidosTableContainer, PesquisarInputContainer, SelectContainer, TotalPedidosContainer } from './styles';
import { ReactComponent as PedidosAmareloSvg } from '../../assets/menu-pedidos-amarelo.svg';
import { ReactSVG } from 'react-svg';
import HomeMinimoPedidosSvg from '../../assets/home-minimo-pedidos.svg'; 
import ConcluidoSvg from '../../assets/concluido.svg';
import BaixadoSvg from '../../assets/baixado.svg';
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import HomeAguardandoSvg from '../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../assets/home-revisar.svg';
import HomeDataCorteSvg from '../../assets/home-data-corte.svg';
import HomeDiasRestantesSvg from '../../assets/home-dias-restantes.svg';
import NotificacaoSvg from '../../assets/notificação.svg';
import LevaCriarSvg from '../../assets/leva-criar.svg';
import VisualizarSvg from '../../assets/visualizar.svg';
import EditarSvg from '../../assets/editar.svg';
import { ReactComponent as PesquisarSvg } from '../../assets/pesquisar.svg';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import CustomTable from '../CustomTable';
import { useAuth } from '../../providers/Auth';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PedidoTr from '../Trs/PedidoTr';
import { ReactComponent as HomeFiltroSvg } from '../../assets/home-filtro.svg';
import { ReactComponent as DownloadSvg } from '../../assets/download.svg';
import { getDataUTC } from '../../utils/date/dataUTC';
import ModalCriarNovaLeva from '../Modals/ModalCriarNovaLeva';
import ModalEncerrarLeva from '../Modals/ModalEncerrarLeva';
import { paginador } from '../../utils/pager';
import Pager from '../Pager';
import * as XLSX from "xlsx/xlsx.mjs";
import Loading from '../Loading';
import Select from 'react-select';
import PedidoLeva from '../PedidoLeva';
import DownloadPedidosButton from '../DownloadPedidosButton';
import ModalConcluirPedidos from '../Modals/ModalConcluirPedidos';
import { FaRegCalendarTimes } from 'react-icons/fa';
import ModalGerarQrcode from '../Modals/ModalGerarQrcode';
import { IoCreateOutline } from 'react-icons/io5';
import ModalEditarDataLeva from '../Modals/ModalEditarDataLeva';
import { AvaliacaoChamadoModal } from '../Modals/ModalAvaliacaoChamado';

const Pedidos = () => {
    const [levas, setLevas] = useState([]);
    const [leva, setLeva] = useState('');
    const [levasFiltradas, setLevasFiltradas] = useState([]);
    const [diasRestantes, setDiasRestantes] = useState(0);

    const [clienteOptions, setClienteOptions] = useState([]);
    const [clienteSelecionado, setClienteSelecionado] = useState('');

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [levaStatusSelecionado, setLevaStatusSelecionado] = useState('');

    const [pedidos, setPedidos] = useState([]);
    const [concluidos, setConcluidos] = useState(0);
    const [baixados, setBaixados] = useState(0);
    const [aprovados, setAprovados] = useState(0);
    const [aguardando, setAguardando] = useState(0);
    const [revisar, setRevisar] = useState(0);
    
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [pedidosFiltrados, setPedidosFiltrados] = useState([]);
    const [pesquisa, setPesquisa] = useState('');
    const [operacoesOptions, setOperacoesOptions] = useState([]);
    const [operacaoSelecionada, setOperacaoSelecionada] = useState('');
    const [pontosDeVendaOptions, setPontosDeVendaOptions] = useState([]);
    const [pontoDeVendaSelecionado, setPontoDeVendaSelecionado] = useState('');
    const [solicitantesOptions, setSolicitantesOptions] = useState([]);
    const [solicitanteSelecionado, setSolicitanteSelecionado] = useState('');
    const [templatesOptions, setTemplatesOptions] = useState([]);
    const [templateSelecionado, setTemplateSelecionado] = useState('');
    const [statusOptions, setStatusOptions] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState('');
    const [pedidosPaginados, setPedidosPaginados] = useState([]);

    const [encerrarLevaModal, setEncerrarLevaModal] = useState(false);
    const [criarNovaLevaModal, setCriarNovaLevaModal] = useState(false);
    const [concluirPedidosModal, setConcluirPedidosModal] = useState(false);
    const [gerarQrCodeModal, setGerarQrCodeModal] = useState(false);
    const [editarDataLevaModal, setEditarDataLevaModal] = useState(false);

    const[avaliacao, setAvaliacao] = useState(false);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const [levasLoaded, setLevasLoaded] = useState(false);
    const [clientesLoaded, setClientesLoaded] = useState(false);
    const [geosLoaded, setGeosLoaded] = useState(false);
    const [pedidosLoaded, setPedidosLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [params] = useSearchParams();

    function verificarAvaliacao() {
        if(!user.data_oferecimento_avaliacao) {
            return setAvaliacao(true)
        } 

        const [dia, mes, ano] = user.data_oferecimento_avaliacao.split("/");
        const dataOferecimentoAvalaiacao = new Date(ano, mes - 1, dia);
        const dataAtual = new Date();
    
        if (dataAtual > dataOferecimentoAvalaiacao) {
            setAvaliacao(true);
        }
    }

    useEffect(() => {
        if(user.id){
            verificarAvaliacao();
        }
      }, [user])

    useEffect(() => {
        if (levasLoaded && clientesLoaded && geosLoaded && pedidosLoaded) {
            setLoading(false);
            params.get('leva') && setLeva(levas.find((leva) => String(leva.id) === params.get('leva')));
        } else {
            setLoading(true);
        }
    }, [levasLoaded, clientesLoaded, geosLoaded, pedidosLoaded])

    const buscarLevas = () => {
        setLevasLoaded(false);
        setLeva('');
        api.get('/levas')
            .then((response) => {
                setLevas(response.data.data.levas);
                setLevasLoaded(true);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };
    const buscarPedidos = () => {
        setPedidosLoaded(false);
        api.get(`/pedidos`).then((resp)=>{
            const pedidosResp = resp.data.data.pedidos;
            setPedidos(pedidosResp);
            setPedidosLoaded(true);
        }).catch((error)=>{
            if (error.response?.status === 401){
                logout();
            };
        })
    }
    useEffect(() => {
        buscarLevas();
        buscarPedidos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user]);

    useEffect(() => {
        const buscarClientes = () => {
            api.get('/clientes')
                .then((response) => {
                    const clientes = response.data.data.clientes;
                    const clientesOptions = clientes.map((cliente) => {
                        return {
                            value: cliente.id,
                            label: cliente.nome
                        };
                    });
                    setClienteOptions(clientesOptions);
                    setClientesLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        const buscarGeos = () => {
            api.get('/geos')
                .then((response) => {
                    const geos = response.data.data.geos;
                    const geosOptions = geos.map((geo) => {
                        return {
                            value: geo.id,
                            label: geo.nome
                        };
                    });
                    setGeosOptions(geosOptions);
                    setGeosLoaded(true);
                }).catch((error) => {
                    if (error.response?.status === 401){
                        logout();
                        navigate('/');
                    };
                });
        };
        if (user.permissao === 'mari') {
            buscarClientes();
            buscarGeos();
        } else if (user.permissao === 'ac') {
            setClientesLoaded(true);
            buscarGeos();
        } else {
            setClientesLoaded(true);
            setGeosLoaded(true);
        };
    },[user]);

    useEffect(() => {
        if (!['mari', 'ac', 'admin'].includes(user.permissao) && levas.length) {
            const [ultimaLeva] = levas;
            if (!ultimaLeva.data_encerramento) {
                setLeva(ultimaLeva);
            } else {
                setLeva('');
            };
        }
    },[levas, user]);

    useEffect(()=>{
        if(leva){
            const [day, month, year] = leva.data_final.split('/')
            const data = new Date();
            const dia = String(data.getDate());
            const mes = String(data.getMonth() + 1);
            const ano = data.getFullYear();

            const date1 = new Date(year, month - 1, day);
            const date2 = new Date(ano, mes - 1, dia);
            const diffTime = date1 - date2;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            setDiasRestantes(diffDays);
        }
    },[leva])

    useEffect(() => {
        const levasFiltradas = levas.filter((leva)=>{
            const filtroCliente = clienteSelecionado?.value ? String(leva.cliente_id) === String(clienteSelecionado.value) : true;
            const filtroGeo = geoSelecionada?.value? String(leva.geo_id) === String(geoSelecionada.value) : true;

            const levaStatus = leva.data_encerramento ? 'encerrada' : 'aberta'; 
            const filtroStatus = levaStatusSelecionado?.value ? levaStatus === levaStatusSelecionado.value : true;

            return filtroCliente && filtroGeo && filtroStatus;
        });

        setLevasFiltradas(levasFiltradas);
    }, [levas, geoSelecionada, clienteSelecionado, levaStatusSelecionado]);

    useEffect(()=>{
        if(leva) {
            const pedidosFiltrados = pedidos.filter((pedido)=>{
                const filtroId = pesquisa ? String(pedido.id) === String(pesquisa) : true;
                const filtroOperacaoSelecionada = operacaoSelecionada ? pedido.nome_operacao.includes(operacaoSelecionada.value) : true;
                const filtroPontoDeVendaSelecionado = pontoDeVendaSelecionado ? pedido.ponto_de_venda.includes(pontoDeVendaSelecionado.value) : true;
                const filtroSolicitanteSelecionado = solicitanteSelecionado ? pedido.criado_por.includes(solicitanteSelecionado.value) : true;
                const filtroTemplateSelecionado = templateSelecionado ? pedido.template.includes(templateSelecionado.value) : true;
                const filtroStatusSelecionado = statusSelecionado ? pedido.status.includes(statusSelecionado.value) : true;
                const filtroLeva = leva ? (
                    (String(pedido.leva_id) === String(leva.id)) 
                        || 
                    (!leva.data_encerramento && ['AGUARDANDO', 'REVISAR'].includes(pedido.status) && pedido.geo_id === leva.geo_id)
                        ||
                    (['geo','gc','gv','rn'].includes(user.permissao))
                 ) : true;

                return (
                    (filtroId)
                    &&
                    (filtroOperacaoSelecionada && filtroPontoDeVendaSelecionado && filtroSolicitanteSelecionado && filtroTemplateSelecionado && filtroStatusSelecionado && filtroLeva)
                );
            }).sort((a, b)=>{
                const [diaA, mesA, anoA, horaA, minutoA] = a.data_de_atualizacao.split(/[\/\s:]/);
                const [diaB, mesB, anoB, horaB, minutoB] = b.data_de_atualizacao.split(/[\/\s:]/);
                const dataA = new Date(anoA, mesA - 1, diaA, horaA, minutoA);
                const dataB = new Date(anoB, mesB - 1, diaB, horaB, minutoB);

                return dataB - dataA;
            });
            const pedidosConcluidos = pedidosFiltrados.filter((pedido)=>pedido.status === 'CONCLUIDO');
            const pedidosBaixados = pedidosFiltrados.filter((pedido)=>pedido.status === 'BAIXADO');
            const pedidosAprovados = pedidosFiltrados.filter((pedido)=>pedido.status === 'APROVADO');
            const pedidosAguardando = pedidosFiltrados.filter((pedido)=>pedido.status === 'AGUARDANDO');
            const pedidosRevisar = pedidosFiltrados.filter((pedido)=>pedido.status === 'REVISAR');

            const operacoes = pedidosFiltrados.map((pedido)=>pedido.nome_operacao);
            const operacoesFiltradas = operacoes.filter((operacao, index)=> {
                return operacoes.indexOf(operacao) === index
            }).map((operacao)=>({value: operacao, label: operacao}));
            setOperacoesOptions(operacoesFiltradas);

            const pontosDeVenda = pedidosFiltrados.map((pedido)=>pedido.ponto_de_venda);
            const pontosDeVendaFiltrados = pontosDeVenda.filter((pontoDeVenda, index)=>{
                return pontosDeVenda.indexOf(pontoDeVenda) === index
            }).map((pontoDeVenda)=>({value: pontoDeVenda, label: pontoDeVenda}));
            setPontosDeVendaOptions(pontosDeVendaFiltrados);

            const solicitantes = pedidosFiltrados.map((pedido)=>pedido.criado_por);
            const solicitantesFiltrados = solicitantes.filter((solicitante, index)=>{
                return solicitantes.indexOf(solicitante) === index
            }).map((solicitante)=>({value: solicitante, label: solicitante}));
            setSolicitantesOptions(solicitantesFiltrados);

            const templates = pedidosFiltrados.map((pedido)=>pedido.template);
            const templatesFiltrados = templates.filter((template, index)=>{
                return templates.indexOf(template) === index
            }).map((template)=>({value: template, label: template}));
            setTemplatesOptions(templatesFiltrados);

            const status = pedidosFiltrados.map((pedido)=>pedido.status);
            const statusFiltrados = status.filter((item, index)=>{
                return status.indexOf(item) === index
            }).map((item)=>({value: item, label: item}));
            setStatusOptions(statusFiltrados);

            setConcluidos(pedidosConcluidos.length);
            setBaixados(pedidosBaixados.length);
            setAprovados(pedidosAprovados.length);
            setAguardando(pedidosAguardando.length);
            setRevisar(pedidosRevisar.length);

            setPedidosFiltrados(pedidosFiltrados);
    }
    },[pedidos, pesquisa, leva, operacaoSelecionada, pontoDeVendaSelecionado, solicitanteSelecionado, templateSelecionado, statusSelecionado, paginaAtual])

    useEffect(() => {
        const paginado = paginador(pedidosFiltrados, paginaAtual);
        setPedidosPaginados(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    }, [pedidosFiltrados])

    const handleEncerrarLeva = () => {
        const dados = {
            data_encerramento: getDataUTC(3)
        };
        api.patch(`/levas/${leva.id}`, dados)
            .then(()=>{
                setLeva('');
                buscarLevas();
                buscarPedidos();
                toast.success('Leva encerrada com sucesso!')
            })
            .catch(()=>{
                toast.error('Erro ao encerrar leva!')
            })
    };

    const criaRelatorio = () => {
        const date = new Date();
        const map = {
            mm: date.getMonth() + 1,
            dd: date.getDate(),
            aaaa: date.getFullYear()
        }
        const formatedDate = "dd/mm/aaaa".replace(/mm|dd|aaaa/gi, matched => map[matched]);
        let name = `pedidos_${formatedDate}.xlsx`;
        const dadosFormatados = pedidosFiltrados.map(pedido => {
            const output = {
                id: pedido.id,
                nome_operacao: pedido.nome_operacao,
                criado_por: pedido.criado_por,
                ponto_de_venda: pedido.ponto_de_venda,
                codigo_pdv: pedido.codigo_pdv,
                titulo: pedido.titulo,
                tipo: ['verso', 'frenteVerso'].includes(pedido.tipo) ? 'frenteVerso' : '',
                cardapio: `${pedido.impresso ? 'Impresso' : ''}${pedido.impresso && pedido.qrcode ? ' e ' : ''}${pedido.qrcode ? 'QR Code' : ''}`,
                template: pedido.template,
                quantidade: pedido.quantidade,
                quantidade_qrcode: pedido.quantidade_qrcode,
                leva_id: pedido.leva_id,
                status: pedido.status,
            };
            if (!pedido.impresso && !pedido.qrcode){
                output.cardapio = 'Digital';
            }
            if (user?.permissao === 'mari' && leva) {
                output['geo_nome'] = pedido.geo_nome;
                if (output.cardapio === 'QR Code'){
                    output.tipo = '';
                };
            };
            return output;
        });
        var ws = XLSX.utils.json_to_sheet(dadosFormatados)
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'pedidos');
        XLSX.writeFile(wb,name);
    };

    return (
        <PedidosContainer>
            <div className='header'>
            {(['mari','ac', 'admin'].includes(user?.permissao) && leva) && (
                <button 
                    className='voltar'
                    onClick={() => {
                        setPedidosFiltrados([]);
                        setLeva('')
                    }}
                >
                    {'<'}
                </button>  
            )} 
            <PedidosAmareloSvg /> 
            <h1>Pedidos</h1>
            {(['mari','ac', 'admin'].includes(user?.permissao) && leva) && (
                <p style={{fontWeight: 600}}>
                   - Leva {leva.id} / Geo {leva.geo_nome}
                </p>
            )}
            </div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {(['mari','ac','admin'].includes(user.permissao) && !leva) ? (
                        <ListaLevasContainer>
                            {levas.length && (
                                <div className='filtros-container'>
                                    {user.permissao === 'mari' && (
                                        <SelectContainer>
                                            <label>
                                                <HomeFiltroSvg /> Filtrar por Cliente
                                            </label>
                                            <Select 
                                                options={clienteOptions}
                                                value={clienteSelecionado}
                                                onChange={(e) => setClienteSelecionado(e)}
                                                placeholder='Ver todos'
                                                className='filtro-select-container'
                                                classNamePrefix='filtro-select'
                                                isClearable
                                                noOptionsMessage={() => 'Nenhuma operação encontrada'}
                                            />
                                        </SelectContainer>
                                    )}
                                    {['mari', 'ac'].includes(user.permissao) && (
                                        <SelectContainer>
                                            <label>
                                                <HomeFiltroSvg /> Filtrar por Geo
                                            </label>
                                            <Select 
                                                options={geosOptions}
                                                value={geoSelecionada}
                                                onChange={(e) => setGeoSelecionada(e)}
                                                placeholder='Ver todos'
                                                className='filtro-select-container'
                                                classNamePrefix='filtro-select'
                                                isClearable
                                                noOptionsMessage={() => 'Nenhuma geo encontrada'}
                                            />
                                        </SelectContainer>
                                    )}
                                    <SelectContainer>
                                        <label>
                                            <HomeFiltroSvg /> Filtrar por Status
                                        </label>
                                        <Select 
                                            options={[{value: 'aberta', label: 'Aberta'}, {value: 'encerrada', label: 'Encerrada'}]}
                                            value={levaStatusSelecionado}
                                            onChange={(e) => setLevaStatusSelecionado(e)}
                                            placeholder='Ver todos'
                                            className='filtro-select-container'
                                            classNamePrefix='filtro-select'
                                            isClearable
                                        />
                                    </SelectContainer>
                                    {(user.permissao === 'admin' && levas[0]?.data_encerramento) && (
                                        <button onClick={() => setCriarNovaLevaModal(true)}>
                                            Criar nova leva
                                        </button>
                                    )}
                                </div>
                            )}
                            {!levas.length && (
                                <NenhumaLevaCriadaContainer>
                                    <h2>Nenhuma leva criada</h2>
                                    {user.permissao === 'admin' ? (
                                        <p>Para iniciar os pedidos inicie uma nova leva</p>
                                    ) : (
                                        <p>Para iniciar os pedidos é necessário ter uma leva iniciada</p>
                                    )}
                                    {user.permissao === 'admin' && (
                                        <button onClick={() => setCriarNovaLevaModal(true)}>
                                            Criar nova leva
                                        </button>
                                    )}
                                    <ReactSVG src={LevaCriarSvg} />
                                </NenhumaLevaCriadaContainer>   
                            )}
                            {levasFiltradas.map((leva) => (
                                <PedidoLeva 
                                    key={leva.id} 
                                    leva={leva} 
                                    setLeva={setLeva}
                                    aprovados={pedidos.filter((pedido)=> pedido.status === 'APROVADO' && String(pedido.leva_id) === String(leva.id))}
                                    buscarPedidos={buscarPedidos}
                                />  
                            ))}
                        </ListaLevasContainer>
                    ) : (
                        <>
                        <LevasContainer>
                            {['admin'].includes(user.permissao) && (
                                <>
                                    {(leva && !leva.data_encerramento) && (
                                        <button onClick={() => setEncerrarLevaModal(true)}>Encerrar leva atual</button>
                                    )}
                                </>
                            )}
                            <div className='item'>
                                <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                    {leva && <ReactSVG src={HomeMinimoPedidosSvg} />} Mínimo de pedidos
                                </h1>
                                <p style={{color: leva ? '#292828' : '#E9E9E9'}}>{leva?.quantidade_min ? leva.quantidade_min : '-'}</p>
                            </div>
                            {user.permissao === 'mari' && (
                                <>
                                <div className='item concluidos'>
                                    <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                        <ReactSVG src={ConcluidoSvg}/> Concluídos
                                    </h1>
                                    <p style={{color: leva ? '#292828' : '#E9E9E9'}}>{leva ? concluidos : '-'}</p>
                                </div>
                                <div className='item baixados'>
                                    <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                       <ReactSVG src={BaixadoSvg}/> Baixados
                                    </h1>
                                    <p style={{color: leva ? '#F16521' : '#E9E9E9'}}>{leva ? baixados : '-'}</p>
                                </div>
                                </>
                            )}
                            <div className='item'>
                                <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                    {leva && <ReactSVG src={HomeAprovadosSvg} />} Aprovados
                                </h1>
                                <p style={{color: leva ? '#40AD48' : '#E9E9E9'}}>{leva ? aprovados : '-'}</p>
                            </div>
                            {user.permissao !== 'mari' && (
                                <>
                                <div className='item'>
                                    <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                        {leva && <ReactSVG src={HomeAguardandoSvg} />} Aguardando
                                    </h1>
                                    <p style={{color: leva ? '#F16521' : '#E9E9E9'}}>{leva ? aguardando : '-'}</p>
                                </div>
                                <div className='item'>
                                    <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                        {leva && <ReactSVG src={HomeRevisarSvg} />} Revisar
                                    </h1>
                                    <p style={{color: leva ? '#FDBC11' : '#E9E9E9'}}>{leva ? revisar : '-'}</p>
                                </div>
                                </>
                            )}
                            <div className='item corte'>
                                <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                    {leva && <ReactSVG src={HomeDataCorteSvg} />} Data de corte {['admin'].includes(user.permissao) && (
                                        <ReactSVG src={EditarSvg} className='editar-svg' onClick={() => setEditarDataLevaModal(true)}/>
                                    )}
                                </h1>
                                <p style={{color: leva ? '#292828' : '#E9E9E9'}}>{leva ? leva.data_final : '-'}</p>
                            </div>
                            {leva.data_encerramento ? (
                                <div className='item'>
                                    <h1>
                                        <FaRegCalendarTimes size={20} fill='#292828'/> Data encerramento
                                    </h1>
                                    <p style={{color: '#292828'}}>
                                        {leva.data_encerramento.split(' ')[0]}
                                    </p>
                                </div>
                            ) : (
                                <div className='item'>
                                    <h1 style={{color: leva ? '#292828' : '#e9e9e9'}}>
                                        {leva && <ReactSVG src={HomeDiasRestantesSvg} />} Dias restantes
                                    </h1>
                                    <p style={{color: leva ? '#292828' : '#E9E9E9'}}>{leva ? diasRestantes : '-'}</p>
                                </div>
                            )}
                        </LevasContainer>
                        {(user.permissao === 'rn' && revisar > 0) && (
                            <AlertaContainer>
                                <ReactSVG src={NotificacaoSvg} /> Você tem {revisar} {revisar === 1 ? 'pedido' : 'pedidos'} que {revisar === 1 ? 'precisa' : 'precisam'} de revisão
                            </AlertaContainer>
                        )}
                        {(user.permissao === 'gv' && aguardando > 0) && (
                            <AlertaContainer>
                                <ReactSVG src={NotificacaoSvg} /> Você tem {aguardando} {aguardando === 1 ? 'pedido' : 'pedidos'} aguardando aprovação
                            </AlertaContainer>
                        )}
                        <ListaPedidosContainer>
                            <div className='header'>
                                <PesquisarInputContainer>
                                    <input 
                                        type='text'
                                        id='pesquisar-pedido'
                                        placeholder='Pesquisar pedido'
                                        value={pesquisa}
                                        onChange={(e)=>setPesquisa(e.target.value)}
                                    />
                                    <label htmlFor='pesquisar-pedido'>
                                        <PesquisarSvg />
                                    </label>
                                </PesquisarInputContainer>
                                {(!['mari', 'ac'].includes(user.permissao)) && (
                                    <button className='gerar-qrcode' onClick={() => setGerarQrCodeModal(true)}>
                                        <IoCreateOutline fill='#292828' stroke='#292828' size='20' /> gerar qrcode
                                    </button>
                                )}
                                {(!['mari', 'ac'].includes(user.permissao)  && !leva.data_encerramento) && (
                                    <Link to='/pedidos/novo' className='novo-pedido'>
                                        + novo pedido
                                    </Link>
                                )}
                                {user.permissao === 'mari' && (
                                    <DownloadPedidosButton
                                        buscaPedidos={buscarPedidos}
                                        arquivos={pedidosFiltrados.map((pedido) => {
                                            return {
                                                fileName: `${pedido.id}_${pedido.titulo}_${pedido.template}.pdf`,
                                                url: pedido.link,
                                                pedido: pedido,
                                                leva_id: leva.id
                                            }
                                        })} 
                                    />
                                )}
                                {(user.permissao === 'mari' && baixados > 0) && (
                                    <button onClick={() => setConcluirPedidosModal(true)} className='concluir-pedidos'>
                                        <ReactSVG src={ConcluidoSvg}/> concluír pedidos baixados
                                    </button>
                                )}
                            </div>
                            <FiltrosContainer permissao={user.permissao}>
                                {user.permissao !== 'rn' && (
                                    <SelectContainer>
                                        <label>
                                            <HomeFiltroSvg /> Filtrar por Operação
                                        </label>
                                        <Select 
                                            options={operacoesOptions}
                                            value={operacaoSelecionada}
                                            onChange={(e) => setOperacaoSelecionada(e)}
                                            placeholder='Ver todos'
                                            className='filtro-select-container'
                                            classNamePrefix='filtro-select'
                                            isClearable
                                            noOptionsMessage={() => 'Nenhuma operação encontrada'}
                                        />
                                    </SelectContainer>
                                )}
                                <SelectContainer>
                                    <label>
                                        <HomeFiltroSvg /> Filtrar por PDV
                                    </label>
                                    <Select 
                                        options={pontosDeVendaOptions}
                                        value={pontoDeVendaSelecionado}
                                        onChange={(e) => setPontoDeVendaSelecionado(e)}
                                        placeholder='Ver todos'
                                        className='filtro-select-container'
                                        classNamePrefix='filtro-select'
                                        isClearable
                                        noOptionsMessage={() => 'Nenhum PDV encontrado'}
                                    />
                                </SelectContainer>
                                {user.permissao !== 'rn' && (
                                    <SelectContainer>
                                        <label>
                                            <HomeFiltroSvg /> Filtrar por Solicitante
                                        </label>
                                        <Select
                                            options={solicitantesOptions}
                                            value={solicitanteSelecionado}
                                            onChange={(e) => setSolicitanteSelecionado(e)}
                                            placeholder='Ver todos'
                                            className='filtro-select-container'
                                            classNamePrefix='filtro-select'
                                            isClearable
                                            noOptionsMessage={() => 'Nenhum solicitante encontrado'}
                                        />
                                    </SelectContainer>
                                )}
                                <SelectContainer>
                                    <label>
                                        <HomeFiltroSvg /> Filtrar por Template
                                    </label>
                                    <Select
                                        options={templatesOptions}
                                        value={templateSelecionado}
                                        onChange={(e) => setTemplateSelecionado(e)}
                                        placeholder='Ver todos'
                                        className='filtro-select-container'
                                        classNamePrefix='filtro-select'
                                        isClearable
                                        noOptionsMessage={() => 'Nenhum template encontrado'}
                                    />
                                </SelectContainer>
                                <SelectContainer>
                                    <label>
                                        <HomeFiltroSvg /> Filtrar por Status
                                    </label>
                                    <Select
                                        options={statusOptions}
                                        value={statusSelecionado}
                                        onChange={(e) => setStatusSelecionado(e)}
                                        placeholder='Ver todos'
                                        className='filtro-select-container'
                                        classNamePrefix='filtro-select'
                                        isClearable
                                        noOptionsMessage={() => 'Nenhum status encontrado'}
                                    />
                                </SelectContainer>
                                {['mari','ac','admin'].includes(user.permissao) && (
                                    <button className='download' onClick={criaRelatorio}>
                                        <DownloadSvg /> extrair lista
                                    </button>
                                )}
                            </FiltrosContainer>
                            <TotalPedidosContainer>
                                    <ReactSVG src={VisualizarSvg}/> {`Mostrando ${pedidosFiltrados.length} ${pedidosFiltrados.length === 1 ? 'pedido' : 'pedidos'}`}
                            </TotalPedidosContainer>
                            <PedidosTableContainer>
                                <CustomTable 
                                    header={[
                                        'Id', 
                                        `${user.permissao === 'rn' ? '' : 'Operação'}`, 
                                        `${user.permissao === 'rn' ? '' : 'Solicitante'}`, 
                                        'PDV', 
                                        'Template', 
                                        'Quant.', 
                                        'Qnt Qr code',
                                        'Cardápio',
                                        'Qr code',
                                        'Status', 
                                        'Atualização', 
                                        `Visualizar/${user.permissao === 'mari' ? 'baixar' : 'editar'}`
                                    ]}
                                    message={leva ? 'Nenhum pedido encontrado' : 'Nenhuma leva em endamento'}
                                >
                                    {pedidosPaginados.map((pedido)=>{
                                        return <PedidoTr key={pedido.id} pedido={pedido} buscarPedidos={buscarPedidos}/>
                                    })}

                               
                                </CustomTable>
                                <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                            </PedidosTableContainer>
                        </ListaPedidosContainer>
                        </>
                    )}
                </>
            )}
            {encerrarLevaModal && <ModalEncerrarLeva setModalOpened={setEncerrarLevaModal} handleEncerrarLeva={handleEncerrarLeva} />}
            {criarNovaLevaModal && <ModalCriarNovaLeva setModalOpened={setCriarNovaLevaModal} buscarPedidos={buscarPedidos} buscarLeva={buscarLevas} />}
            {concluirPedidosModal && (
                <ModalConcluirPedidos 
                    setModalOpened={setConcluirPedidosModal} 
                    pedidosBaixados={pedidosFiltrados.filter((pedido) => pedido.status === 'BAIXADO')} 
                    buscarPedidos={buscarPedidos} 
                />
            )}
            {gerarQrCodeModal && (
                <ModalGerarQrcode 
                    setModalOpened={setGerarQrCodeModal}
                />
            )}
            {editarDataLevaModal && <ModalEditarDataLeva setModalOpened={setEditarDataLevaModal} buscarLevas={buscarLevas} leva={leva}/>}
            {avaliacao && <AvaliacaoChamadoModal setStateModal={setAvaliacao}/>}
        </PedidosContainer>
    );
};

export default Pedidos;