import styled from 'styled-components';

export const ModalEscadaPrecoContainer = styled.div`

`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 2rem;
    min-width: 350px;
    width: 90vw;
    max-width: 1200px;
    max-height: 90vh;
    > h1 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #FDBC11;
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 5px;

        svg {
            height: 20px;
        }
    }
    
    > .content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        overflow-x: auto;
    
        > h2 {
            display: inline-block;
            color: #292828;
    
            > .maior {
                display: inline-block;
                background: #FDBC11;
                width: 15px;
                height: 15px;
                border-radius: 100%;
                margin: 0 2px;
            }
    
            > .menor {
                display: inline-block;
                background: #292828;
                width: 15px;
                height: 15px;
                border-radius: 100%;
                margin: 0 2px;
            }
        }
    
        > .caption {
            display: flex;
            align-items: center;
            column-gap: 6px;
            border: 1px dashed #FDBC11;
            width: 278px;
            height: 48px;
            min-height: 48px;
            padding: 0 18px;
            margin-left: auto;
            
            > p {
                color: #000;
    
                > b {
                    color: #000;
                    font-weight: 600;
                }
            }
        }
    }
`;

export const DegrausContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 100%;
    > .captions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;