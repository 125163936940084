import styled from 'styled-components';

export const ModalCriarNovaLevaContainer = styled.div`

`;

export const ContentContainer = styled.div`
    padding: 2rem;

    > h1 {
        font-weight: 600;
        color: #292828;
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }
`;

export const CriarNovaLevaForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    column-gap: 20px;

    > div {
        display: flex;
        column-gap: 10px;
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > input {
        background-color: #F0F0F0;
        border-radius: 6px;
        width: 160px;
        padding-left: 10px;
        height: 44px;
        border: none;
        color: #292828;
    }
`;

export const SelectContainer = styled.div`
   display: flex;
    flex-direction: column;
    row-gap: 5px;
    position: relative;

    > label {
        color: #292828;
    }

    > select {
        background-color: #F0F0F0;
        width: 100%;
        height: 44px;
        padding-left: 10px;
        color: #292828;
        border: none;
        border-radius: 6px;

        > option {
            color: #292828;
        }
    }
`;

export const SalvarButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width: 159px;
    height: 42px;
    border-radius: 20px;
    background: #FDBC11;
    border: none;
    color: #292828;
    font-weight: 600;
`;