import { FiltrosContainer, PedidosPorComercialContainer, PesquisarInputContainer, TableContainer, TrNotFound } from './styles';
import { ReactSVG } from 'react-svg';
import ArrowDownSvg from '../../assets/arrow-down.svg';
import HomeAguardandoSvg from '../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import { useState } from 'react';
import api from '../../services/api';
import { useEffect } from 'react';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import PedidosPorComercialTr from '../Trs/PedidosPorComercialTr';
import Pager from '../Pager';
import { paginador } from '../../utils/pager';
import Loading from '../Loading';
import * as XLSX from 'xlsx/xlsx.mjs';

const PedidosPorComercial = ({ pedidos, levaAtual, levasAtivas, anoSelecionado, geoSelecionada, operacaoSelecionada }) => {
    const [comerciais, setComerciais] = useState([]);
    const [comerciaisFiltrados, setComerciaisFiltrados] = useState([]);
    const [comerciaisPaginados, setComerciaisPaginados] = useState([]);

    const [pesquisa, setPesquisa] = useState('');

    const [tableOpened, setTableOpened] = useState(true);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [loading, setLoading] = useState(true);
    
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const buscarComerciais = () => {
        setLoading(true);
        api.get('/comerciais')
            .then((response) => {
                setComerciais(response.data.data.comerciais);
                setLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarComerciais();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const comerciaisFiltrados = comerciais.filter((comercial) => {
            const filtroGeo =  geoSelecionada ? String(comercial.geo_id) === String(geoSelecionada.value) : true;
            const filtroOperacao = operacaoSelecionada ? String(comercial.id) === String(operacaoSelecionada.comercial_id) : true;
            const filtroId = pesquisa ? String(comercial.id) === String(pesquisa) : true;
            const filtroNome = pesquisa ? comercial.nome.toLowerCase().includes(pesquisa.toLowerCase()) : true;

            return (
                filtroGeo
                &&
                filtroOperacao
                &&
                (filtroId || filtroNome)
            );
        }).sort((comercialA, comercialB) => {
          //rankear comerciais pelo número de pedidos aprovados, caso seja 0, rankear pelo número de pedidos total
            const pedidosComercialA = pedidos.filter((pedido) => String(pedido.comercial_id) === String(comercialA.id));
            const pedidosComercialB = pedidos.filter((pedido) => String(pedido.comercial_id) === String(comercialB.id));
            const pedidosAprovadosComercialA = pedidosComercialA.filter((pedido) => pedido.status === 'APROVADO');
            const pedidosAprovadosComercialB = pedidosComercialB.filter((pedido) => pedido.status === 'APROVADO');
            const pedidosAprovadosComercialASize = pedidosAprovadosComercialA.length;
            const pedidosAprovadosComercialBSize = pedidosAprovadosComercialB.length;
            const pedidosComercialASize = pedidosComercialA.length;
            const pedidosComercialBSize = pedidosComercialB.length;

            if (pedidosAprovadosComercialASize > pedidosAprovadosComercialBSize) {
                return -1;
            } else if (pedidosAprovadosComercialASize < pedidosAprovadosComercialBSize) {
                return 1;
            } else if (pedidosComercialASize > pedidosComercialBSize) {
                return -1;
            } else if (pedidosComercialASize < pedidosComercialBSize) {
                return 1;
            } else {
                return 0;
            };
        });
        setComerciaisFiltrados(comerciaisFiltrados);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comerciais, geoSelecionada, operacaoSelecionada, pedidos,pesquisa]);
    
    useEffect(() => {
        const paginado = paginador(comerciaisFiltrados, paginaAtual)
        setComerciaisPaginados(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comerciaisFiltrados, paginaAtual]);

    const criaRelatorio = () => {
        let name = `pedido_por_comercial${anoSelecionado ? `_${anoSelecionado}` : ''}.xlsx`;
        const dados = comerciaisFiltrados.map((comercial) => {
            const levasAtivasCond = ['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada;
            const pedidosComercial = pedidos.filter((pedido) => String(pedido.comercial_id) === String(comercial.id));
            const pedidosAguardando = pedidosComercial.filter((pedido) => pedido.status === 'AGUARDANDO');
            const pedidosRevisar = pedidosComercial.filter((pedido) => pedido.status === 'REVISAR');
            const pedidosAprovados = pedidosComercial.filter((pedido) => pedido.status === 'APROVADO');
            let totalLeva = [];

            if (levasAtivasCond) {
                totalLeva = levasAtivas.reduce((acc, leva) => {
                    if (!leva.data_encerramento) {
                        return acc.concat(pedidosComercial.filter((pedido) => String(pedido.leva_id) === String(leva.id)));
                    };
                }, []);
            } else if (levaAtual) {
                totalLeva = pedidosComercial.filter((pedido) => String(pedido.leva_id) === String(levaAtual.id));
            };
            return {
                id: comercial.id,
                comercial: comercial.nome,
                [`total pedidos ${anoSelecionado ? `(${anoSelecionado})` : ''}`]: pedidosComercial.length,
                [`total ${levasAtivasCond ? 'levas ativas' : 'leva atual'}`]: totalLeva.length,
                aguardando: pedidosAguardando.length,
                revisar: pedidosRevisar.length,
                aprovados: pedidosAprovados.length,
            };
        });
        const ws = XLSX.utils.json_to_sheet(dados);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'volumes');
        XLSX.writeFile(wb,name);
    };

    return (
        <PedidosPorComercialContainer>
            <div className='header'>
                <h1>Pedidos por comercial</h1>
                <button onClick={() => setTableOpened(!tableOpened)}>
                    {tableOpened ? (
                        <>
                        ver menos <ReactSVG src={ArrowDownSvg} />
                        </>
                    ) : (
                        <>
                        ver mais <ReactSVG style={{transform: 'rotate(180deg)', marginTop: '3px'}} src={ArrowDownSvg} />
                        </>
                    )}
                </button>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <TableContainer tableOpened={tableOpened}>
                    <FiltrosContainer>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-comercial'
                                placeholder='Pesquisar comercial'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-comercial'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                    </FiltrosContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        Id
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Comercial
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''} 
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                                    </div>
                                </th>
                                <th>
                                    <div className='aguardando'>
                                        <ReactSVG src={HomeAguardandoSvg}/> Aguardando
                                    </div>
                                </th>
                                <th>
                                    <div className='revisando'>
                                        <ReactSVG src={HomeRevisarSvg}/> Revisando
                                    </div>
                                </th>
                                <th>
                                    <div className='aprovado'>
                                        <ReactSVG src={HomeAprovadosSvg}/> Aprovado
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {comerciaisPaginados.length ? (
                                comerciaisPaginados.map((comercial) => (
                                    <PedidosPorComercialTr key={comercial.id} comercial={comercial} pedidos={pedidos} levaAtual={levaAtual} levasAtivas={levasAtivas} geoSelecionada={geoSelecionada} anoSelecionado={anoSelecionado}/>
                                ))
                            ) : (
                                <TrNotFound>
                                    <td>Nenhum comercial encontrado.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </TrNotFound>
                            )}
                        </tbody>
                    </table>
                    <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                </TableContainer>
            )}
        </PedidosPorComercialContainer>
    );
};

export default PedidosPorComercial;