import { ReactSVG } from 'react-svg';
import { FiltrosContainer, PedidosPorGeoContainer, PesquisarInputContainer, TableContainer, TrNotFound } from './styles';
import ArrowDownSvg from '../../assets/arrow-down.svg';
import HomeAguardandoSvg from '../../assets/home-aguardando.svg';
import HomeRevisarSvg from '../../assets/home-revisar.svg';
import HomeAprovadosSvg from '../../assets/home-aprovados.svg';
import PesquisarSvg from '../../assets/pesquisar.svg';
import DownloadSvg from '../../assets/download.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../providers/Auth';
import api from '../../services/api';
import { paginador } from '../../utils/pager';
import Loading from '../Loading';
import Pager from '../Pager';
import PedidosPorGeoTr from '../Trs/PedidosPorGeoTr';
import * as XLSX from 'xlsx/xlsx.mjs';

const PedidosPorGeo = ({ pedidos, levaAtual, levasAtivas, anoSelecionado, geoSelecionada }) => {
    const [geos, setGeos] = useState([]);
    const [geosFiltradas, setGeosFiltradas] = useState([]);
    const [geosPaginadas, setGeosPaginadas] = useState([]);
    
    const [pesquisa, setPesquisa] = useState('');

    const [tableOpened, setTableOpened] = useState(true);

    const [paginaAtual, setPaginaAtual] = useState(1);
    const [totalPaginas, setTotalPaginas] = useState(1);

    const [loading, setLoading] = useState(true);

    const { logout, user } = useAuth();
    const navigate = useNavigate();

    const buscarGeos = () => {
        setLoading(true);
        api.get('/geos')
            .then((response) => {
                setGeos(response.data.data.geos);
                setLoading(false);
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                };
            });
    };

    useEffect(() => {
        buscarGeos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const geosFiltradas = geos.filter(geo => {
            const filtroGeo = geoSelecionada ? String(geo.id) === String(geoSelecionada.value) : true;
            const filtroId = pesquisa ? String(geo.id) === String(pesquisa) : true;
            const filtroNome = pesquisa ? geo.nome.toLowerCase().includes(pesquisa.toLowerCase()) : true;

            return (
                filtroGeo 
                && 
                (filtroId || filtroNome)
            ); 
        });
        const geosOrdenadas = geosFiltradas.sort((geoA, geoB) => {
            //rankear geos pelo número de pedidos aprovados, caso seja 0, rankear pelo número de pedidos total
            const pedidosGeoA = pedidos.filter(pedido => String(pedido.geo_id) === String(geoA.id));
            const pedidosGeoB = pedidos.filter(pedido => String(pedido.geo_id) === String(geoB.id));
            const pedidosAprovadosGeoA = pedidosGeoA.filter(pedido => pedido.status === 'APROVADO');
            const pedidosAprovadosGeoB = pedidosGeoB.filter(pedido => pedido.status === 'APROVADO');
            const pedidosTotalGeoA = pedidosGeoA.length;
            const pedidosTotalGeoB = pedidosGeoB.length;
            const pedidosAprovadosGeoATotal = pedidosAprovadosGeoA.length;
            const pedidosAprovadosGeoBTotal = pedidosAprovadosGeoB.length;
            if (pedidosAprovadosGeoATotal > pedidosAprovadosGeoBTotal) {
                return -1;
            } else if (pedidosAprovadosGeoATotal < pedidosAprovadosGeoBTotal) {
                return 1;
            } else if (pedidosTotalGeoA > pedidosTotalGeoB) {
                return -1;
            } else if (pedidosTotalGeoA < pedidosTotalGeoB) {
                return 1;
            } else {
                return 0;
            };
        })
        setGeosFiltradas(geosOrdenadas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geos, geoSelecionada, pedidos, pesquisa]);

    useEffect(() => {
        const paginado = paginador(geosFiltradas, paginaAtual);
        setGeosPaginadas(paginado.itens);
        setPaginaAtual(paginado.paginaAtual);
        setTotalPaginas(paginado.paginas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geosFiltradas, paginaAtual]);

    const criaRelatorio = () => {
        const name = `pedido_por_geo${anoSelecionado ? `_${anoSelecionado}` : ''}.xlsx`;
        const dados = geosFiltradas.map((geo) => {
            const levasAtivasCond = ['ac'].includes(user.permissao) && levasAtivas.length && !geoSelecionada;
            const pedidosGeo = pedidos.filter(pedido => String(pedido.geo_id) === String(geo.id));
            const pedidosAguardando = pedidosGeo.filter(pedido => pedido.status === 'AGUARDANDO');
            const pedidosRevisar = pedidosGeo.filter(pedido => pedido.status === 'REVISAR');
            const pedidosAprovados = pedidosGeo.filter(pedido => pedido.status === 'APROVADO');
            let totalLeva = [];

            if (levasAtivasCond) {
                totalLeva = levasAtivas.reduce((acc, leva) => {
                    if (!leva.data_encerramento) {
                        return acc.concat(pedidosGeo.filter(pedido => String(pedido.leva_id) === String(leva.id)));
                    };
                }, []);
            } else if (levaAtual) {
                totalLeva = pedidosGeo.filter(pedido => String(pedido.leva_id) === String(levaAtual.id));
            };
            return {
                id: geo.id,
                geo: geo.nome,
                [`total pedidos ${anoSelecionado ? anoSelecionado : ''}`]: pedidosGeo.length,
                [`total ${levasAtivasCond ? 'levas ativas' : 'leva atual'}`]: totalLeva.length,
                aguardando: pedidosAguardando.length,
                revisar: pedidosRevisar.length,
                aprovados: pedidosAprovados.length,
            };
        });
        const ws = XLSX.utils.json_to_sheet(dados);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'pedidos_por_geo');
        XLSX.writeFile(wb, name);
    };

    return (
        <PedidosPorGeoContainer>
            <div className="header">
                <h1>Pedidos por GEO</h1>
                <button onClick={() => setTableOpened(!tableOpened)}>
                    {tableOpened ? (
                        <>
                        ver menos <ReactSVG src={ArrowDownSvg} />
                        </>
                    ) : (
                        <>
                        ver mais <ReactSVG style={{transform: 'rotate(180deg)', marginTop: '3px'}} src={ArrowDownSvg} />
                        </>
                    )}
                </button>
            </div>
            {loading ? (
                <Loading />
            ) : (
                <TableContainer tableOpened={tableOpened}>
                    <FiltrosContainer>
                        <PesquisarInputContainer>
                            <input 
                                type='text'
                                id='pesquisar-geo'
                                placeholder='Pesquisar geo'
                                value={pesquisa}
                                onChange={(e) => setPesquisa(e.target.value)}
                            />
                            <label htmlFor='pesquisar-geo'>
                                <ReactSVG src={PesquisarSvg} />
                            </label>
                        </PesquisarInputContainer>
                        <button className='extrair' onClick={criaRelatorio}>
                            <ReactSVG src={DownloadSvg} /> extrair lista
                        </button>
                    </FiltrosContainer>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <div>
                                        Id
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        GEO
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        Total pedidos {anoSelecionado ? `${anoSelecionado}` : ''} 
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        {(user.permissao === 'ac' && !geoSelecionada) ? 'Total levas ativas' : 'Total leva atual'}
                                    </div>
                                </th>
                                <th>
                                    <div className='aguardando'>
                                        <ReactSVG src={HomeAguardandoSvg}/> Aguardando
                                    </div>
                                </th>
                                <th>
                                    <div className='revisando'>
                                        <ReactSVG src={HomeRevisarSvg}/> Revisando
                                    </div>
                                </th>
                                <th>
                                    <div className='aprovado'>
                                        <ReactSVG src={HomeAprovadosSvg}/> Aprovado
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {geosPaginadas.length ? (
                                geosPaginadas.map((geo) => (
                                    <PedidosPorGeoTr key={geo.id} geo={geo} pedidos={pedidos} levaAtual={levaAtual} levasAtivas={levasAtivas} anoSelecionado={anoSelecionado}/>
                                ))
                            ) : (
                                <TrNotFound>
                                    <td>Nenhuma operação encontrado.</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </TrNotFound>
                            )}
                        </tbody>
                    </table>
                    <Pager currentPage={paginaAtual} setCurrentPage={setPaginaAtual} totalPages={totalPaginas} />
                </TableContainer>
            )}
        </PedidosPorGeoContainer>
    )
};

export default PedidosPorGeo;