import { createContext, useContext, useState, useEffect } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [loadingToken, setLoadingToken] = useState(false);
    const [user, setUser] = useState('');
    const [loadingUser, setLoadingUser] = useState(false);

    const logout = () => {
        localStorage.removeItem('@gcardapio:token');
        setToken('');
        setUser('');
    };

    useEffect(() => {
        const token = localStorage.getItem('@gcardapio:token');
        if (token) {
            setToken(token);
        }
        setLoadingToken(true);
    }, []);

    useEffect(() => {
        if (loadingToken) {
            if (token) {
                api.get('/perfil')
                    .then(response => {
                        setUser(response.data);
                        setLoadingUser(true);
                    }).catch(error => {
                        if(error.response?.status === 401){
                            logout();
                            window.location.href = '/';
                        } else {
                            toast.error('Ocorreu um erro ao carregar o usuário. Tente novamente mais tarde.')
                        };
                        setLoadingUser(true);
                    });
            } else {
                setLoadingUser(true);
            };
        };
    }, [token, loadingToken]);

    return (
        <AuthContext.Provider 
            value={{
                logout,
                setToken,
                setLoadingToken,
                user,
                loadingUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);