import { useParams } from 'react-router-dom';
import { PdfContainer, PdfPageContainer } from './styles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import axios from 'axios';
import Loading from '../../components/Loading';

const PdfPage = () => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const [file, setFile] = useState('');
    const [errorPdf, setErrorPdf] = useState(false);
    const [numPages, setNumPages] = useState(null);
    
    const { link } = useParams();

    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        axios.get(`https://gestorcadapios.blob.core.windows.net/pdf/${link}.pdf`, {responseType: 'blob'})
            .then((response) => {
                const file = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                setFile(file);
                setLoading(false);
            }).catch((error) => {
                setErrorPdf(true);
                setLoading(false);
            });
    }, [link]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }
    
    return (
        <PdfPageContainer>
            {loading ? (
                <Loading />
            ) : (
                <>
                {file && (
                    <PdfContainer>
                        <Document
                            file={file}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )}
                        </Document>
                    </PdfContainer>
                )}
                {errorPdf && (
                    <span className='notfound'>Pdf não encontrado.</span>
                )}
                </>
            )}
        </PdfPageContainer>
    )
};

export default PdfPage;