import styled from 'styled-components';

export const CustomModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100vw;
    height: 100vh;
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`;

export const ContentContainer = styled.div`
    min-width: 300px;
    
    background: #F6F6FC;
    box-shadow: 0px 3px 6px #000000C2;
    border: 1px solid #707070;
    border-radius: 18px;

    position: relative;
    
    > .fechar {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        border: none;
        background-color: #fa5252;
        width: 61px;
        height: 61px;
        position: absolute;
        top: -25px;
        right: -25px;
    }
`;