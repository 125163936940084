import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { ReactComponent as SalvarSvg } from '../../../assets/salvar.svg';
import { useForm, Controller } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import volumeSchema from '../../../schemas/Volumes/volumeSchema';
import api from '../../../services/api';
import { Form, SalvarButton, SelectContainer } from './styles';
import Select from 'react-select';
import Loading from '../../Loading';
import { ReactSVG } from 'react-svg';
import ImportarSvg from '../../../assets/importar.svg';
import ModalImportarBebidaVolumes from '../../Modals/ModalImportarBebidaVolumes';

const VolumeForm = () => {
    const { register, handleSubmit, formState: { errors },setValue, reset, control } = useForm({
        resolver: yupResolver(volumeSchema)
    });

    const [geosOptions, setGeosOptions] = useState([]);
    const [geoSelecionada, setGeoSelecionada] = useState('');

    const [bebidas, setBebidas] = useState([]);

    const { volume_id } = useParams();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const [modalImportarVolumes, setModalImportarVolumes] = useState(false);

    const [geosLoaded, setGeosLoaded] = useState(false);
    const [bebidasLoaded, setBebidasLoaded] = useState(false);
    const [volumeLoaded, setVolumeLoaded] = useState(false);
    const [bebidasLoading, setBebidasLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (geosLoaded && bebidasLoaded && volumeLoaded) {
            setLoading(false);
        };
    }, [geosLoaded, bebidasLoaded, volumeLoaded]);

    const buscarBebidas = (geo_id) => {
        setBebidasLoaded(false);
        setBebidasLoading(true);
        api.get(`/bebidas?geo_id=${geo_id}`)
            .then(response => {
                const bebidaOptions = response.data.data.bebidas.map(bebida => {
                    return {
                        value: bebida.id,
                        label: bebida.nome
                    };
                });
                setBebidas(bebidaOptions);
                setBebidasLoaded(true);
                setBebidasLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response.status === 403) {
                    navigate('/pedidos');
                    return;
                };
                toast.error('Erro ao buscar bebida!');
            });
    };

    useEffect(() => {
        const buscarGeos = () => {
            api.get(`/geos`)
                .then(response => {
                    const geosOptions = response.data.data.geos.map((geo) => {
                        return {
                            value: geo.id,
                            label: geo.nome
                        };
                    });
                    setGeosOptions(geosOptions);
                    // setGeos(response.data.data.geos);
                    setGeosLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    }
                });
        };
        if (user?.permissao === 'ac') {
            buscarGeos();
            setBebidasLoaded(true);
        } else {
            buscarBebidas();
            setGeosLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (geoSelecionada) {
            setBebidas([]);
            buscarBebidas(geoSelecionada.value);
        };
    }, [geoSelecionada]);

    useEffect(() => {
        if (volume_id) {
            api.get(`/bebida-volumes/volume/${volume_id}`)
                .then(response => {
                    const volume = response.data.data.volume;
                    const dadosVolume = {
                        bebida_id: {value:volume.bebida_id, label:volume.bebida},
                        volume: volume.volume,
                    };
                    reset(dadosVolume);
                    setVolumeLoaded(true);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    if (error.response.status === 404) {
                        toast.error('Volume não encontrado!');
                        return;
                    };
                    toast.error('Erro ao buscar volume!');
                });
        } else {
            setVolumeLoaded(true);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitFunction = (data) => {
        if (volume_id) {
            data.id = volume_id;
            data.bebida_id = data.bebida_id.value;
            api.patch(`/bebida-volumes`, { volumes: [data] })
                .then((response) => {
                    toast.success('Volume atualizado com sucesso!');
                    navigate('/administrativo/volume');
                }).catch((error) => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    toast.error('Erro ao atualizar volume!');
                });
        } else {
            const dados = {
                bebida_id: data.bebida_id.value,
                volumes: [{volume: data.volume}]
            };
            api.post(`/bebida-volumes`, dados)
                .then((response) => {
                    toast.success('Volume criado com sucesso!');
                    setValue('volume', null);
                }).catch((error) => {
                    if (error.response.status === 401) {
                        logout();
                        navigate('/');
                        return;
                    };
                    if (error.response.status === 403) {
                        navigate('/pedidos');
                        return;
                    };
                    toast.error(error.response.data.message);
                });
        }
    };

    return (
        <>
        {loading ? (
            <Loading />
        ) : (
            <Form onSubmit={handleSubmit(onSubmitFunction)}>
                {!volume_id && (
                    <div className='importar-container'>
                        <button onClick={() => setModalImportarVolumes(true)} type='button'>
                            <ReactSVG src={ImportarSvg}/> importar arquivo
                        </button>
                    </div>
                )}
                {user.permissao === 'ac' && (
                    <SelectContainer style={{width: '100%'}}>
                        <label htmlFor='geo_id'>Geo</label>
                        <Select
                            options={geosOptions}
                            onChange={e => setGeoSelecionada(e)}
                            isClearable
                            className='react-select-container'
                            classNamePrefix='react-select'
                        />
                    </SelectContainer>
                )}
                <SelectContainer className='bebida'>
                    <label>Bebida</label>
                    <Controller
                        name='bebida_id'
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                options={bebidas}
                                isClearable
                                defaultValue={null}
                                className='react-select-container'
                                classNamePrefix='react-select'
                                isDisabled={user.permissao === 'ac' && !geoSelecionada}
                                isLoading={bebidasLoading}
                            />
                        )}
                    />
                    {errors.bebida_id && <span className='input-error'>{errors.bebida_id.message}</span>}
                </SelectContainer>
                <SelectContainer className='volume'>
                    <label htmlFor="volume">Volume</label>
                    <select 
                        id="volume"
                        {...register('volume')}
                    >
                        <option value=''>Selecione um volume</option>
                        <option value="100ml">100ml</option>
                        <option value="200ml">200ml</option>
                        <option value="250ml">250ml</option>
                        <option value="260ml">260ml</option>
                        <option value="269ml">269ml</option>
                        <option value="275ml">275ml</option>
                        <option value="290ml">290ml</option>
                        <option value="300ml">300ml</option>
                        <option value="330ml">330ml</option>
                        <option value="340ml">340ml</option>
                        <option value="350ml">350ml</option>
                        <option value="355ml">355ml</option>
                        <option value="375ml">375ml</option>
                        <option value="400ml">400ml</option>
                        <option value="473ml">473ml</option>
                        <option value="500ml">500ml</option>
                        <option value="600ml">600ml</option>
                        <option value="700ml">700ml</option>
                        <option value="740ml">740ml</option>
                        <option value="750ml">750ml</option>
                        <option value="1L">1L</option>
                        <option value="1,5L">1,5L</option>
                        <option value="2L">2L</option>
                        <option value="BARRIL 10L">BARRIL 10L</option>
                        <option value="BARRIL 30L">BARRIL 30L</option>
                        <option value="BARRIL 50L">BARRIL 50L</option>
                        <option value="CHOPP">CHOPP</option>
                        <option value="LN 269ml">LN 269ml</option>
                        <option value="LT 269ml">LT 269ml</option>
                    </select>
                    {errors.volume && <span className='input-error'>{errors.volume.message}</span>}
                </SelectContainer>
                <div className='buttons-container'>
                    <Link to='/administrativo/volume'>{'< Voltar'}</Link>
                    <SalvarButton
                        type='submit'
                    >
                        <SalvarSvg /> Salvar
                    </SalvarButton>
                </div>
            </Form>
        )}
        {modalImportarVolumes && <ModalImportarBebidaVolumes setModalOpened={setModalImportarVolumes} />}
        </>
    );
};

export default VolumeForm;