import AdminBebidaEditar from '../../components/AdminBebidaEditar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminBebidaEditarPageContainer } from './styles';

const AdminBebidaEditarPage = () => {

    return (
        <AdminBebidaEditarPageContainer>
            <Header />
            <AdminBebidaEditar />
            <Footer />
        </AdminBebidaEditarPageContainer>
    );
};

export default AdminBebidaEditarPage;