import AdminComercialNovo from '../../components/AdminComercialNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminComercialNovoPageContainer } from './styles';

const AdminComercialNovoPage = () => {

    return (
        <AdminComercialNovoPageContainer>
            <Header />
            <AdminComercialNovo />
            <Footer />
        </AdminComercialNovoPageContainer>
    );
};

export default AdminComercialNovoPage;