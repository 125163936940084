import styled from 'styled-components';

export const PedidoIncompletoContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export const InformacoesContainer = styled.div`
    padding: 2rem;
    border: 1px solid #E9E9E9;
    border-radius: 15px;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 4px;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }
    }

    > .informacoes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        column-gap: 10px;
        > .item {
            width: 10%;
            > h3 {
                color: #292828;
                font-weight: 600;
            }

            > p {
                color: #292828;
                margin: 5px 0;
            }
        }

        > button.editar {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            height: 38px;
            width: 170px;

            background: none;
            border: 1px solid #292828;
            border-radius: 20px;
            color: #292828;
            font-size: 0.9em;
            font-weight: 500;

            svg {
                height: 12px;
                path {
                    fill: #292828;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        > .informacoes {
            flex-direction: column;
            
            > .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #E9E9E9;
                padding: 5px 0;
            }

            > .editar {
                margin-top: 10px;
                margin-left: auto;
            }
        }
    }
`;

export const EscadaDePrecosContainer = styled.div`
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;
    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }

        > button {
            background: none;
            border: none;
            color: #292828;
            font-size: 0.9em;
            text-decoration: underline;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 2rem 1rem;
    }
`;

export const AddProdutosContainer = styled.div`
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;
    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }

        > .importar {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            margin-left: 10px;
            padding: 0 10px;
            height: 38px;

            background: none;
            color: #292828;
            border: 1px solid #292828;
            border-radius: 20px;

            svg {
                margin-top: 5px;
                height: 15px;
            }
        }
    }

    > .salvar {
        display: flex;
        justify-content: center;
        > button {
            background: #FDBC11;
            border-radius: 20px;
            border: none;
            
            color: #292828;
            font-weight: 600;
            padding: 10px 20px;
        }
    }
    
    @media screen and (max-width: 768px) {
        padding: 2rem 1rem;

        > h2 {
            font-size: 0.9em;

            svg {
                height: 18px;
                width: 18px;
            }

            > .importar {
                margin-left: auto;
                font-size: 1em;

                svg {
                    height: 15px;
                    width: 15px;
                }
            }
        }
    }
`;

export const CategoriaSelectContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 1rem 0;

    position: relative;

    > label {
        display: flex;
        align-items: center;
        column-gap: 5px;
        color: #292828;
        white-space: nowrap;
        font-weight: 600;
        font-size: 0.9em;

        svg {
            width: 15px;
        }
    }

    > span {
        top: 30px;
        left: 150px;
    }

    > .categorias-select,
    > .outras-categorias-select {
        width: 100%;

        .react-select__multi-value__label {
            min-width: 50px;
        }

        .react-select__multi-value__remove {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: rgba(41, 40, 40, 0.7);
                }
            }
        }

        .react-select__clear-indicator {
            display: none;
        }

        .react-select__loading-indicator {
            > span {
                color: #292828;
            }
        }
       
        .react-select__dropdown-indicator {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: rgba(41, 40, 40, 0.7);
                }   
            }
        }

        .react-select__option {
            color: #292828;
        }
    }

    > .outras-categorias-select {
        .react-select__menu {
            display: none;
        }

        .react-select__multi-value__remove {
            svg {
                path, polygon, rect, circle, ellipse {
                    fill: rgba(41, 40, 40, 0.7);
                }
            }
        }

        .react-select__dropdown-indicator {
            display: none;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 10px;
        align-items: flex-start;
    }
`;

export const LimiteDeItensContainer = styled.div`
    font-weight: 700;
    font-size: 1.2em;
    color: #292828;
    text-align: center;

    margin: 40px 0;
`;

export const CategoriasBebidasContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const OutrasCategoriasContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const PdfContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #E9E9E9;
    border-radius: 15px;
    padding: 2rem;

    > h2 {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 10px;
        width: 100%;

        color: #FDBC11;
        font-weight: 700;

        svg {
            height: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        padding: 2rem 1rem;
    }
`;

export const EncerrarPedidoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;

    > button {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        height: 38px;
        width: 200px;
        background: none;
        border-radius: 20px;
        font-weight: 600;
        font-size: 0.9em;
        border: none;
        background-color: #f8971d;
        color: #fff;

        svg {
            width: 20px;
            path, polygon, rect, circle, ellipse {
                fill: #fff;
            }
        }
    }

    > .cancelar {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        height: 38px;
        width: 200px;
        background: none;
        border-radius: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 0.9em;
        border: none;
        background: #ED1818;
        svg {
            margin-top: 5px;
        }
    }

    .aguardando{
         
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        height: 38px;
        width: 200px;
        background: none;
        border-radius: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 0.9em;
        border: none;
        background:#F16521;
        svg {
            margin-top: 5px;
        }
    }
    

    > .finalizar {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        height: 38px;
        width: 200px;
        background: none;
        border-radius: 20px;
        color: #fff;
        font-weight: 600;
        font-size: 0.9em;
        border: none;
        background: #40AD48;

        :disabled {
            background: #909090;
            cursor: not-allowed;

            svg {
                path, polygon, rect, circle, ellipse {
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        column-gap: 5px;
        
        > button {
            font-size: 0.8em;
            width: 170px;
            height: 34px;
            svg {
                width: 15px;
            }    
        }
    }

    @media screen and (max-width: 400px) {
        > button {
            font-size: 0.7em;
            width: 160px;
            height: 30px;
            svg {
                width: 13px;
            }    
        }
    }
`;