import AdminOperacaoNovo from '../../components/AdminOperacaoNovo';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { AdminOperacaoNovoPageContainer } from './styles';

const AdminOperacaoNovoPage = () => {

    return (
        <AdminOperacaoNovoPageContainer>
            <Header />
            <AdminOperacaoNovo />
            <Footer />
        </AdminOperacaoNovoPageContainer>
    )
};

export default AdminOperacaoNovoPage;