import { ReactSVG } from 'react-svg';
import { AdminComercialEditarContainer, ContentContainer } from './styles';
import ComercialSvg from '../../assets/comercial.svg';
import ComercialForm from '../Forms/ComercialForm';

const AdminComercialEditar = () => {

    return (
        <AdminComercialEditarContainer>
            <div className="header">
                <ReactSVG src={ComercialSvg} /> <h1>Cadastro de Comercial</h1>
            </div>
            <ContentContainer>
                <ComercialForm />
            </ContentContainer>
        </AdminComercialEditarContainer>
    );
};

export default AdminComercialEditar;