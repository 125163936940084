import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { ComparadoresMaiorContainer, ComparadoresMenorContainer, EscadaDegrauContainer, NenhumaBebidaContainer } from './styles';
import { useState } from 'react';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import ModalAdicionarEscadaBebidas from '../Modals/ModalAdicionarEscadaBebidas';
import EscadaBebida from '../EscadaBebida';
import { useEffect } from 'react';
import EscadaBebidaComparador from '../EscadaBebidaComparador';
import ModalAdicionarEscadaBebidaComparador from '../Modals/ModalAdicionarEscadaBebidaComparador';

const EscadaDegrau = ({degrau, escadaPreco, escadaBebidas, buscarEscadaBebidas, visualizar}) => {
    const [bebidasDegrau] = useState(() => {
        return escadaBebidas.filter((elem) => elem.degrau === degrau);
    });
    const [bebidaArrastada, setBebidaArrastada] = useState('');
    const [bebidaSelecionada, setBebidaSelecionada] = useState('');

    const [comparadoresMaior, setComparadoresMaior] = useState([]);
    const [comparadoresMenor, setComparadoresMenor] = useState([]);
    const [exibirComparadoresMaior, setExibirComparadoresMaior] = useState(false);
    const [exibirComparadoresMenor, setExibirComparadoresMenor] = useState(false);
    const [bebidaComparadorAtiva, setBebidaComparadorAtiva] = useState('');

    const { logout } = useAuth();
    const navigate = useNavigate();

    const [modalAdicionarEscadaBebidas, setModalAdicionarEscadaBebidas] = useState(false);
    const [modalAdicionarEscadaBebidaComparadorMaior, setModalAdicionarEscadaBebidaComparadorMaior] = useState(false);
    const [modalAdicionarEscadaBebidaComparadorMenor, setModalAdicionarEscadaBebidaComparadorMenor] = useState(false);
    
    const handleMoverDegrauCima = () => {
        const bebidasCima = escadaBebidas.filter((elem) => elem.degrau === degrau - 1);
        const bebidasAtual = escadaBebidas.filter((elem) => elem.degrau === degrau);
        const bebidasCimaFormatadas = bebidasCima.map((elem) => {
            return {
                id: elem.id,
                degrau: String(degrau),
            };
        });
        const bebidasAtualFormatadas = bebidasAtual.map((elem) => {
            return {
                id: elem.id,
                degrau: String(degrau - 1),
            };
        });
        const dados = {
            escadaVolumes: [
                ...bebidasCimaFormatadas,
                ...bebidasAtualFormatadas,
            ],
        };

        api.patch(`/escada-bebida-volumes/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao alterar andar da escada.');
            });
    };

    const handleMoverDegrauBaixo = () => {
        const bebidasBaixo = escadaBebidas.filter((elem) => elem.degrau === degrau + 1);
        const bebidasAtual = escadaBebidas.filter((elem) => elem.degrau === degrau);
        const bebidasBaixoFormatadas = bebidasBaixo.map((elem) => {
            return {
                id: elem.id,
                degrau: String(degrau),
            };
        });
        const bebidasAtualFormatadas = bebidasAtual.map((elem) => {
            return {
                id: elem.id,
                degrau: String(degrau + 1),
            };
        });
        const dados = {
            escadaVolumes: [
                ...bebidasBaixoFormatadas,
                ...bebidasAtualFormatadas,
            ],
        };
        
        api.patch(`/escada-bebida-volumes/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao alterar andar da escada.');
            });
    };

    const handleMovePosicaoBebidas = () => {
        const dadosBebidaArrastada = {
            id: bebidaArrastada.id,
            posicao: bebidaSelecionada.posicao,
        };
        const dadosBebidaSelecionada = {
            id: bebidaSelecionada.id,
            posicao: bebidaArrastada.posicao,
        };
        const dados = {
            escadaVolumes: [
                dadosBebidaArrastada,
                dadosBebidaSelecionada      
            ]
        };
        api.patch(`/escada-bebida-volumes/${escadaPreco.id}`, dados)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                toast.error('Erro ao alterar posição da bebida.');
            });

    };

    useEffect(() => {
        if (bebidaArrastada && bebidaSelecionada) {
            handleMovePosicaoBebidas();
        }
    }, [bebidaArrastada, bebidaSelecionada])

    return (
        <EscadaDegrauContainer>
            {!visualizar && (
                <div className='setas-container'>
                    <button 
                        onClick={handleMoverDegrauCima}
                        disabled={degrau === escadaPreco.degraus_cima}
                    >
                        <FiArrowUp title='Mover degrau pra cima' stroke='#292828' size={18}/>
                    </button>
                    <button 
                        onClick={handleMoverDegrauBaixo}
                        disabled={degrau === escadaPreco.degraus_baixo}
                    >
                        <FiArrowDown title='Mover degrau pra baixo' stroke='#292828' size={18}/>
                    </button>
                </div>
            )}
            <div className='bebidas-container'>
                {!visualizar && (
                    <button className='adicionar-bebida' onClick={() => setModalAdicionarEscadaBebidas(true)}>
                        <div>+</div>
                        <div>Adicionar bebida</div>
                    </button>
                )}
                {bebidasDegrau.length ? (
                    bebidasDegrau.map((bebida) => (
                        <EscadaBebida 
                            key={bebida.id}
                            bebida={bebida}
                            setBebidaArrastada={setBebidaArrastada}
                            setBebidaSelecionada={setBebidaSelecionada}
                            buscarEscadaBebidas={buscarEscadaBebidas}
                            exibirComparadoresMaior={exibirComparadoresMaior}
                            exibirComparadoresMenor={exibirComparadoresMenor}
                            setExibirComparadoresMaior={setExibirComparadoresMaior}
                            setExibirComparadoresMenor={setExibirComparadoresMenor}
                            setComparadoresMaior={setComparadoresMaior}
                            setComparadoresMenor={setComparadoresMenor}
                            bebidaComparadorAtiva={bebidaComparadorAtiva}
                            setBebidaComparadorAtiva={setBebidaComparadorAtiva}
                            visualizar={visualizar}
                        />
                    ))
                ) : (
                    <NenhumaBebidaContainer>
                        Nenhuma bebida adicionada
                    </NenhumaBebidaContainer>
                )}
            </div>
            {!visualizar && (
                <div className='setas-container'>
                    <button 
                        onClick={handleMoverDegrauCima}
                        disabled={degrau === escadaPreco.degraus_cima}
                    >
                        <FiArrowUp title='Mover degrau pra cima' stroke='#292828' size={18}/>
                    </button>
                    <button 
                        onClick={handleMoverDegrauBaixo}
                        disabled={degrau === escadaPreco.degraus_baixo}
                    >
                        <FiArrowDown title='Mover degrau pra baixo' stroke='#292828' size={18}/>
                    </button>
                </div>
            )}
            {modalAdicionarEscadaBebidas && (
                <ModalAdicionarEscadaBebidas 
                    setModalOpened={setModalAdicionarEscadaBebidas}
                    escadaPreco={escadaPreco}
                    degrau={degrau}
                    escadaBebidas={escadaBebidas}
                    buscarEscadaBebidas={buscarEscadaBebidas}
                />
            )}
            {exibirComparadoresMaior && (
                <ComparadoresMaiorContainer>
                    <div className='comparador' title='Maior igual'>
                        {'>='}
                    </div>
                    {!visualizar && (
                        <button 
                            className='escolher-bebida' 
                            onClick={() => {
                                setModalAdicionarEscadaBebidaComparadorMaior(true);
                            }}
                        >
                            <div>+</div>
                            <div>escolher bebida</div>
                        </button>
                    )}
                    <div className='itens'>
                        {comparadoresMaior.map((elem) => (
                            <EscadaBebidaComparador 
                                key={elem.id} 
                                bebidaComparador={elem}
                                bebidaComparadorAtiva={bebidaComparadorAtiva}
                                tipoComparador={'maior'}
                                buscarEscadaBebidas={buscarEscadaBebidas}
                                visualizar={visualizar}
                            />
                        ))}
                    </div>
                </ComparadoresMaiorContainer>
            )}
            {exibirComparadoresMenor && (
                <ComparadoresMenorContainer>
                    <div className='comparador' title='Menor igual'>
                        {'<='}
                    </div>
                    {!visualizar && (
                        <button 
                            className='escolher-bebida'
                            onClick={() => {
                                setModalAdicionarEscadaBebidaComparadorMenor(true)
                            }}
                        >
                            <div>+</div>
                            <div>escolher bebida</div>
                        </button>
                    )}
                    <div className='itens'>
                        {comparadoresMenor.map((elem) => (
                            <EscadaBebidaComparador 
                                key={elem.id} 
                                bebidaComparador={elem}
                                bebidaComparadorAtiva={bebidaComparadorAtiva}
                                tipoComparador={'menor'}
                                buscarEscadaBebidas={buscarEscadaBebidas}
                                visualizar={visualizar}
                            />
                        ))}
                    </div>
                </ComparadoresMenorContainer>
            )}
            {modalAdicionarEscadaBebidaComparadorMaior && (
                <ModalAdicionarEscadaBebidaComparador 
                    setModalOpened={setModalAdicionarEscadaBebidaComparadorMaior} 
                    tipoComparador={'maior'} 
                    bebidas={bebidasDegrau}
                    bebidaComparadorAtiva={bebidaComparadorAtiva}
                    buscarEscadaBebidas={buscarEscadaBebidas}
                />
            )}
            {modalAdicionarEscadaBebidaComparadorMenor && (
                <ModalAdicionarEscadaBebidaComparador 
                    setModalOpened={setModalAdicionarEscadaBebidaComparadorMenor} 
                    tipoComparador={'menor'} 
                    bebidas={bebidasDegrau}
                    bebidaComparadorAtiva={bebidaComparadorAtiva}
                    buscarEscadaBebidas={buscarEscadaBebidas}
                />
            )}
        </EscadaDegrauContainer>
    )
};

export default EscadaDegrau;