import CustomModal from '../CustomModal';
import { ContentContainer, InputContainer, ModalAdicionarEscadaBebidaComparadorContainer, SalvarButton, SelectContainer } from './styles';
import Select from 'react-select';
import SalvarSvg from '../../../assets/salvar.svg';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useEffect } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import { useAuth } from '../../../providers/Auth';
import { useNavigate } from 'react-router-dom';
import { moneyMask } from '../../../utils/masks';

const ModalAdicionarEscadaBebidaComparador = ({ setModalOpened, bebidas, tipoComparador, bebidaComparadorAtiva, buscarEscadaBebidas }) => {
    const [bebidasOptions, setBebidasOptions] = useState([]);
    const [bebidaSelecionada, setBebidaSelecionada] = useState('');
    const [diferencaMinima, setDiferencaMinima] = useState('');

    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (bebidas.length) {
            const bebidasFiltradas = bebidas.filter((elem) => elem.id !== bebidaComparadorAtiva.id)
            const options = bebidasFiltradas.map((elem) => {
                return {
                    value: elem.id,
                    label: `${elem.bebida} ${elem.volume}` 
                };
            });
            setBebidasOptions(options);
        };
    }, [bebidas]);

    const handleAdicionarBebidaComparador = () => {
        const dados = {
            bebida_volume_pai_id: bebidaComparadorAtiva.id,
            bebida_volume_filho_id: bebidaSelecionada.value,
            maior: tipoComparador === 'maior' ? '0' : '1',
            menor: tipoComparador === 'menor' ? '0' : '1',
        };
        if (diferencaMinima) {
            dados.diferenca_minima = diferencaMinima.replace(',', '.');
        }
        api.post(`/escada-bebida-volumes-comparador`, dados)
            .then((response) => {
                buscarEscadaBebidas();
            }).catch((error) => {
                if (error.response?.status === 401){
                    logout();
                    navigate('/');
                    return;
                };
                if (error.response?.status === 409) {
                    toast.error('Item já adicionado.');
                    return;
                }
                toast.error('Erro ao adicionar comparador.');
            });
    };

    return (
        <ModalAdicionarEscadaBebidaComparadorContainer>
            <CustomModal setModalOpened={setModalOpened}>
                <ContentContainer>
                    <h1>Adicionar bebida</h1>
                    <SelectContainer style={{width: '100%'}}>
                        <label htmlFor='bebidas'>Bebidas</label>
                        <Select
                            id='bebidas'
                            className='react-select-container'
                            classNamePrefix='react-select'
                            placeholder='Selecione as bebidas'
                            options={bebidasOptions}
                            onChange={setBebidaSelecionada}
                        />
                    </SelectContainer>
                    <InputContainer>
                        <label htmlFor='diferenca-minima'>Diferença mínima</label>
                        <div>
                            <label>R$</label>
                            <input
                                type='text'
                                id='diferenca-minima'
                                placeholder='0,00'
                                value={diferencaMinima}
                                onChange={(e) => {
                                    setDiferencaMinima(moneyMask(e.target.value))
                                }}
                            />
                        </div>
                    </InputContainer>
                    <div className='buttons-container'>
                        <SalvarButton 
                            onClick={handleAdicionarBebidaComparador}
                            disabled={!bebidaSelecionada}
                        >
                            <ReactSVG src={SalvarSvg} /> Salvar
                        </SalvarButton>
                    </div>
                </ContentContainer>
            </CustomModal>
        </ModalAdicionarEscadaBebidaComparadorContainer>
    )
};

export default ModalAdicionarEscadaBebidaComparador;