import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid rgba(112, 112, 112, 0.7);
    margin: 1rem;
    margin-top: auto;
    padding: 10px 20px;
    padding-bottom: 0;

    > div > div > svg {
        width: 80px;
    }
    > svg {
        width: 100px;
    }
`;