import styled from 'styled-components';

export const PdfPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > .notfound {
        padding: 4rem 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: #292828;
        text-align: center;
    }
    
`;

export const PdfContainer = styled.div`
    width: 100%;
    max-width: 800px;
    .react-pdf__Document {
        .react-pdf__Page {
            canvas {
                max-width: 2000px;
                width: 100% !important;
                height: auto !important;
            }

            .react-pdf__Page__textContent {
               display: none;
            }

            .react-pdf__Page__annotations {
               display: none;
            }
        }
    }
`;