import axios from "axios";

export const apiPesquisaSatisfacao = axios.create({
    // baseURL: 'http://localhost:8081',
    baseURL: "https://servicosapi.ifollow.com.br/"
  });

// apiPesquisaSatisfacao.interceptors.request.use((config) => {
//     const token = localStorage.getItem('@gcardapio:token');
  
//     if (token) {
//       config.headers = {
//         Authorization: `${token}`
//       };
//     }
  
//     return config;
//   });
  
// apiPesquisaSatisfacao.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const token = localStorage.getItem("@gcardapio:token");

//     if (error.response) {
//       if (error.response.status === 401 && token) {
//         localStorage.removeItem("@gcardapio:token");
//         window.location.reload();
//       }
//     }
//     return Promise.reject(error);
//   }
// );
  
export default apiPesquisaSatisfacao;